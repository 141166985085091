import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { DEFAULT_FONT, Grey_10, buildMediaQuery, tablet768 } from '@czechtv/styles';
import { IconProfilePicture, IconLogin } from '@czechtv/icons';
import { Link } from '../../../Link';
import analytics from '../../../../../common/analytics';
import {
  GlobalHeaderGlobalMenuAccountConfig,
  useGlobalHeaderContext,
} from '../../../../providers/GlobalHeaderProvider';

const useStyles = createUseStyles({
  accountLoginLink: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '24px',
    color: 'inherit',
    fontFamily: DEFAULT_FONT,
    fontSize: 16,
    fontWeight: 600,
    padding: [10, 20],
    maxWidth: 'fit-content',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    '& svg': {
      display: 'none',
      height: 24,
      width: 24,
      fill: 'currentcolor',
    },
    '& span': {
      display: 'flex',
      minWidth: 'fit-content',
      alignItems: 'center',
    },
    '&:hover': {
      backgroundColor: Grey_10,
    },
    [buildMediaQuery({ minWidth: tablet768 })]: {
      minWidth: 133,
      justifyContent: 'center',
    },
  },
  accountUserIcon: {
    fontWeight: 400,
  },
  insetFocusVisible: {},
  userProfilePhoto: {
    height: 40,
    width: 40,
    borderRadius: '50%',
    marginRight: 8,
  },
});

const onClickLoggin = () => {
  const menuAnalyticsData = {
    menu: {
      type: 'global',
      level1: 'Přihlásit',
    },
    interaction: true,
    _clear: true,
  };

  analytics.trigger({ type: 'MenuClick', data: menuAnalyticsData });
};

export interface GlobalMenuAccountLoginLinkProps {
  accountConfig?: GlobalHeaderGlobalMenuAccountConfig;
  active?: boolean;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const GlobalMenuAccountLoginLink = memo(
  ({ accountConfig, buttonRef, onClick }: GlobalMenuAccountLoginLinkProps) => {
    const classes = useStyles();

    const { states } = useGlobalHeaderContext();
    return (
      <>
        {states.userData?.isLogged ? (
          <button
            aria-controls="account-menu"
            aria-haspopup="menu"
            className={classNames(
              classes.accountLoginLink,
              classes.accountUserIcon,
              classes.insetFocusVisible
            )}
            ref={buttonRef}
            type="button"
            onClick={onClick}
          >
            {states.userData.userImage ? (
              <img
                alt=""
                className={classNames(classes.userProfilePhoto)}
                src={states.userData.userImage}
              />
            ) : (
              <IconProfilePicture />
            )}
            <span>Moje ČT</span>
          </button>
        ) : (
          <Link
            aria-label="Přihlásit"
            className={classNames(classes.accountLoginLink, classes.insetFocusVisible)}
            href={accountConfig?.loginLink || ''}
            onClick={onClickLoggin}
          >
            <span aria-hidden>Přihlásit</span>
            <IconLogin />
          </Link>
        )}
      </>
    );
  }
);
