// @deprecated Přepsat na třídu
export const CSSEllipsis = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

// @deprecated Přepsat na třídu
export const SrOnly = {
  height: 1,
  left: '-10000px',
  overflow: 'hidden',
  position: 'absolute',
  top: 'auto',
  width: 1,
};
