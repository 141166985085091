import React, { createRef, memo, RefObject, useEffect } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useGlobalHeaderContext } from '../../../../providers/GlobalHeaderProvider';
import { useKeyFocusCycle } from '../../../../uses/useKeyFocusCycle';
import { ContextMenuLink } from '../../../ContextMenu/ContextMenuItem/ContextMenuItem';
import { ContextMenu } from '../../../ContextMenu/ContextMenu';
import { MenuNavWrapper } from '../MenuNavWrapper/MenuNavWrapper';
import { AccountMenuLink } from '../../../../constants/accountMenuNavItems';
import { globalMenuAccountNavStyles } from './GlobalMenuAccountNav.style';

export interface GlobalMenuAccountNavProps {
  accountMenuItems: AccountMenuLink[];
  ariaLabel?: string;
  buttonRef?: RefObject<HTMLButtonElement>;
  onClickOutside?: () => void;
  onLinkClick?: (item: ContextMenuLink) => void;
  visible?: boolean;
}
const useGlobalMenuAccountNavStyles = createUseStyles(globalMenuAccountNavStyles);

export const GlobalMenuAccountNav = memo(
  ({
    visible = false,
    onClickOutside = () => {},
    buttonRef,
    ariaLabel = 'Menu uživatele',
    onLinkClick,
    accountMenuItems,
  }: GlobalMenuAccountNavProps) => {
    const ref: RefObject<HTMLDivElement> = createRef();
    const classes = useGlobalMenuAccountNavStyles();

    const {
      config: { account },
    } = useGlobalHeaderContext();

    const accountMenuItemsWithHref: AccountMenuLink[] = accountMenuItems.map((item) => {
      return {
        ...item,
        href: account?.accountMenuItems[item.id]?.href || item.href,
        customLinkProps: account?.accountMenuItems[item.id]?.customLinkProps,
      };
    });

    useKeyFocusCycle({ containerRef: ref, buttonRef, active: visible });

    useEffect(() => {
      const handleOnClick = (e: Event) => {
        if (ref.current && !ref.current.contains(e.target as Node) && visible) {
          // Pri kliknuti mimo rozbalene menu skryjeme menu
          if (buttonRef?.current?.contains(e.target as Node)) {
            return;
          }
          onClickOutside();
        }
      };

      document.addEventListener('click', handleOnClick);

      return () => {
        document.removeEventListener('click', handleOnClick);
      };
    }, [onClickOutside, ref, visible, buttonRef]);

    const onItemSelected = (link: ContextMenuLink) => {
      if (onLinkClick) {
        onLinkClick(link);
      }
    };

    const { states } = useGlobalHeaderContext();

    // nahradit datama z props

    return (
      <MenuNavWrapper
        ariaLabel={ariaLabel}
        data-testid="ctg-profile-menu"
        id="account-menu"
        ref={ref}
        visible={visible}
      >
        <div className={classNames(classes.profileName)}>
          <p>{states.userData?.fullName}</p>
        </div>

        {accountMenuItems && (
          <ContextMenu
            className={classNames(classes.accountContextMenu)}
            items={accountMenuItemsWithHref}
            onLinkClick={onItemSelected}
          />
        )}
      </MenuNavWrapper>
    );
  }
);
