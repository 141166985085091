import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { NativeButton } from '@czechtv/components';
import analytics from '../../../../common/analytics';
import { PositionedMenu } from '../../PositionedMenu/PositionedMenu';
import { useGlobalHeaderContext } from '../../../providers/GlobalHeaderProvider';
import { localMenuNavStyles } from './LocalMenuNav.style';
import { LocalMenuNavItem, LocalMenuNavItemLink } from './LocalMenuNavItem/LocalMenuNavItem';

const NEXT_BUTTON_WIDTH = 72;

export interface LocalMenuNavProps {
  ariaLabel?: string;
  className?: string;
  items: LocalMenuNavItemLink[];
  menuWidth?: number;
}

const useLocalMenuNavStyles = createUseStyles(localMenuNavStyles);

export const LocalMenuNav = ({
  ariaLabel = 'Kategorie',
  className,
  items,
  menuWidth = 186,
}: LocalMenuNavProps) => {
  const [isHiddenMenuVisible, setIsHiddenMenuVisible] = useState<boolean>(false);
  const [splitIndex, setSplitIndex] = useState<number | null>(null);
  const [sizes, setSizes] = useState<number[]>([]);
  const [isVisible, setIsVisible] = useState(false);
  const [visibleItems, setVisibleItems] = useState<LocalMenuNavItemLink[]>(items);
  const [hiddenItems, setHiddenItems] = useState<LocalMenuNavItemLink[]>([]);

  const classes = useLocalMenuNavStyles();

  // NAV kontejner
  const navRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const names = items.map((item) => item.title).join('');
  const { states } = useGlobalHeaderContext();

  // Pokud není k dispozici velikost jednotlivých elementů, spočítá je
  useEffect(() => {
    // pockame na donacteni webovych fontu
    if (!navRef.current || sizes.length > 0 || !isVisible) {
      return;
    }
    const newSizes: number[] = [];

    navRef.current.querySelectorAll(`.${classes.localMenuNavListItem}`).forEach((item) => {
      newSizes.push(item.clientWidth);
    });

    setSizes(newSizes);
  }, [names, sizes, isVisible, classes.localMenuNavListItem]);

  // pokud dostaneme nové children, musíme znovu vygenerovat, kde menu rozdělit
  useEffect(() => {
    setSplitIndex(null);
    setSizes([]);
  }, [names]);

  // na změnu velikosti okna vytvoří bod, kde je třeba menu rozdělit
  useEffect(() => {
    const handleContainerWidthChange = () => {
      if (navRef.current?.clientWidth) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
      if (!navRef.current || sizes.length === 0) {
        return;
      }

      const add = (accumulator: number, a: number) => accumulator + a;

      // pokud je sirka zobrazenych polozek nizsi nez sirka wrapperu odecteme sirku tlacitka dalsi
      const offset = navRef.current.clientWidth < sizes.reduce(add, 0) ? NEXT_BUTTON_WIDTH : 0;
      const maxWidth = navRef.current.clientWidth - offset;

      let width = 0;
      const index = sizes.findIndex((size) => {
        if (width + size < maxWidth) {
          width += size;
          return false;
        }
        return true;
      });
      setSplitIndex(index < 0 ? sizes.length : index);
    };

    setTimeout(() => {
      // nastavení šířky při načtění
      handleContainerWidthChange();
    }, 200);

    window.addEventListener('resize', handleContainerWidthChange);
    return () => {
      window.removeEventListener('resize', handleContainerWidthChange);
    };
  }, [navRef, sizes, splitIndex]);

  useEffect(() => {
    setVisibleItems(splitIndex && splitIndex > 0 ? items.slice(0, splitIndex) : items);
    setHiddenItems(splitIndex && splitIndex > 0 ? items.slice(splitIndex) : []);
  }, [items, splitIndex]);

  const toggleHiddenMenuVisibility = (visibility?: boolean) => {
    if (typeof visibility === 'undefined') {
      setIsHiddenMenuVisible(!isHiddenMenuVisible);
    } else {
      setIsHiddenMenuVisible(visibility);
    }

    const menuAnalyticsData = {
      menu: {
        type: 'product',
      },
      interaction: true,
      _clear: true,
    };

    if (isHiddenMenuVisible) {
      analytics.trigger({
        type: 'MenuClose',
        data: menuAnalyticsData,
      });
    } else {
      analytics.trigger({
        type: 'MenuOpen',
        data: menuAnalyticsData,
      });
    }
  };

  const onLinkClick = (e: LocalMenuNavItemLink) => {
    const menuAnalyticsData = {
      menu: {
        type: 'product',
        level1: e.title,
      },
      interaction: true,
      _clear: true,
    };

    analytics.trigger({ type: 'MenuClick', data: menuAnalyticsData });
  };

  const isHiddenItemActive = hiddenItems.some((item) => item.active);

  return (
    <div
      aria-label={ariaLabel}
      className={classNames(
        classes.localMenuNav,
        className,
        !(sizes.length > 0 && splitIndex !== null && isVisible) && classes.localMenuNavGradient
      )}
      ref={navRef}
      role="region"
    >
      <ul className={classNames(classes.localMenuNavList)}>
        {visibleItems.map((link, index) => (
          <LocalMenuNavItem key={`${index.toString()}`} onLinkClick={onLinkClick} {...link} />
        ))}
        {hiddenItems.length > 0 && (
          <li key="more">
            <div
              className={classNames(
                classes.localMenuNavButtonContainer,
                classes.localMenuNavListItem
              )}
            >
              <NativeButton
                aria-controls="local-menu"
                aria-expanded={isHiddenMenuVisible}
                className={classNames(
                  classes.localMenuNavButton,
                  classes.insetFocusVisible,
                  isHiddenItemActive && classes.localMenuNavButtonActive,
                  isHiddenMenuVisible && classes.localMenuNavButtonActive,
                  { dark: states.isDark }
                )}
                ref={buttonRef}
                tabIndex={0}
                type="button"
                onClick={() => {
                  toggleHiddenMenuVisibility();
                }}
              >
                Další...
              </NativeButton>
              <PositionedMenu
                buttonRef={buttonRef}
                isVisible={isHiddenMenuVisible}
                items={hiddenItems}
                maxWidth={menuWidth}
                padding={10}
                toggleMenuVisibility={toggleHiddenMenuVisibility}
              />
            </div>
          </li>
        )}
      </ul>
    </div>
  );
};
