import { useEffect, useState } from 'react';
import {
  THREE_ROWS_HEIGHT_DESKTOP,
  TWO_ROWS_HEIGHT_DESKTOP,
  TWO_ROWS_HEIGHT_MOBILE,
} from '../constants/main';

export interface FloatingProps {
  headerNotFullyScrolled: boolean;
  isAtTop: boolean;
  isFixed: boolean;
  isScrolledUp: boolean;
  scrolledDownThenUp: boolean;
}

// z důvodu performance je ideální ten hook použít jen jednou
export const useFloating = (
  isFixedState: boolean,
  isMenuOpen?: boolean,
  headerHeight?: number
): FloatingProps => {
  const [isAtTop, setIsAtTop] = useState(true);
  const [isFixed, setIsFixed] = useState(false);
  const [isScrolledUp, setIsScrolledUp] = useState(false);
  const [headerNotFullyScrolled, setHeaderNotFullyScrolled] = useState(true);
  const [y, setY] = useState(0);
  const [scrolledDown, setScrolledDown] = useState(false);
  const [scrolledDownThenUp, setScrolledDownThenUp] = useState(false);
  const [upScrollBuffer, setUpScrollBuffer] = useState(0);
  const UP_SCROLL_BUFFER = 200;
  const hasThirdRow = headerHeight === THREE_ROWS_HEIGHT_DESKTOP;

  useEffect(() => {
    const callback = (e: Event) => {
      const window = e.currentTarget as Window;
      const isMobile = window.innerWidth < 768;
      // pokud se jedna o scroll event beze zmeny scrollu, nic nedelame
      if (y === window.scrollY) {
        setIsScrolledUp(false);
        setScrolledDownThenUp(false);
        return;
      }

      const maxScrollY = document.body.scrollHeight - window.innerHeight;
      const isAtTop = window.scrollY <= 0;
      // TODO vyresit i pro situaci, kdy se zobrazuje pouze jeden radek hlavicky
      const headerNotFullyScrolled =
        window.scrollY < (isMobile ? TWO_ROWS_HEIGHT_MOBILE : TWO_ROWS_HEIGHT_DESKTOP);
      let isScrolledUp = false;

      // pro scroll nahoru máme nastaven buffer, aby se menu neukazovalo při malém scrollu nahoru
      if (y >= window.scrollY && window.scrollY <= maxScrollY && !isAtTop) {
        if (upScrollBuffer < UP_SCROLL_BUFFER) {
          setUpScrollBuffer(upScrollBuffer + (y - window.scrollY));
        }
        if (upScrollBuffer >= UP_SCROLL_BUFFER) {
          isScrolledUp = true;
        }
      }

      if (window.scrollY > y) {
        setScrolledDown(true);
        setUpScrollBuffer(0);
        setScrolledDownThenUp(false);
      }

      if (window.scrollY < y) {
        if (scrolledDown && !isMenuOpen) {
          setScrolledDownThenUp(true);
        }
        setScrolledDown(false);
      }
      // kdyz je menu otevrene, chceme zachovat informace o scrollu v defaultu
      if (isMenuOpen) {
        setScrolledDownThenUp(false);
        setIsScrolledUp(false);
        setScrolledDown(false);
      }

      let isFixed =
        isScrolledUp &&
        !isAtTop &&
        (isFixedState ||
          window.scrollY > (isMobile ? TWO_ROWS_HEIGHT_MOBILE : TWO_ROWS_HEIGHT_DESKTOP));

      if (isMenuOpen) {
        isFixed = true;
        if (isAtTop) {
          isFixed = false;
        }
      }

      if (hasThirdRow && !isAtTop && !headerNotFullyScrolled) {
        isFixed = true;
      }

      setIsAtTop(isAtTop);
      setHeaderNotFullyScrolled(headerNotFullyScrolled);
      setIsFixed(isFixed);
      setIsScrolledUp(isScrolledUp);
      setY(window.scrollY > maxScrollY ? maxScrollY : window.scrollY);
    };

    window.addEventListener('scroll', callback);
    return () => {
      window.removeEventListener('scroll', callback);
    };
  }, [isFixedState, y, scrolledDownThenUp, scrolledDown, isMenuOpen, upScrollBuffer, hasThirdRow]);

  return { isAtTop, isFixed, isScrolledUp, headerNotFullyScrolled, scrolledDownThenUp };
};
