import React, { createRef, memo, RefObject, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { Blue_base, Grey_10, DefaultBoxShadow } from '@czechtv/styles';
import { useKeyFocusCycle } from '../../../uses/useKeyFocusCycle';
import { LocalMenuNavItemLink } from '../LocalMenuNav/LocalMenuNavItem/LocalMenuNavItem';
import { ContextMenu } from '../../ContextMenu/ContextMenu';

const useStyles = createUseStyles({
  contextMenu: {}, // Class FK
  contextMenuItemDisabled: {}, // Class FK
  contextMenuItemActive: {}, // Class FK
  localMenuMobileNav: {
    position: 'relative',
    backgroundColor: '#fff',
    boxShadow: DefaultBoxShadow,
    marginLeft: 'auto',
    display: 'none',
    width: '100%',
    '& $contextMenu': {
      backgroundColor: 'transparent',
      width: '100%',
      boxShadow: 'none',
      padding: [16, 0],
      '& li:not($contextMenuItemDisabled)': {
        '& > a:hover,& > span&:hover': {
          backgroundColor: Grey_10,
        },
      },
      '& li$contextMenuItemActive > a,& li$contextMenuItemActive > span, & li > a:hover': {
        color: Blue_base,
      },
    },
  },
  localMenuMobileNavVisible: {
    display: 'block',
  },
});

export interface LocalMenuMobileNavProps {
  ariaLabel?: string;
  buttonRef?: RefObject<HTMLButtonElement>;
  items: LocalMenuNavItemLink[];
  onClickOutside?: () => void;
  onLinkClick?: (item: LocalMenuNavItemLink) => void;
  visible?: boolean;
}

export const LocalMenuMobileNav = memo(
  ({
    visible = false,
    onClickOutside = () => {},
    items,
    buttonRef,
    ariaLabel = 'Lokální menu',
    onLinkClick,
  }: LocalMenuMobileNavProps) => {
    const ref: RefObject<HTMLDivElement> = createRef();
    const classes = useStyles();

    useKeyFocusCycle({
      containerRef: ref,
      buttonRef,
      active: visible,
    });

    useEffect(() => {
      const handleOnClick = (e: Event) => {
        if (ref.current && !ref.current.contains(e.target as Node) && visible) {
          // Pri kliknuti mimo rozbalene menu skryjeme menu
          if (!buttonRef?.current?.contains(e.target as Node)) {
            onClickOutside();
          }
        }
      };

      document.addEventListener('click', handleOnClick);

      return () => {
        document.removeEventListener('click', handleOnClick);
      };
    }, [buttonRef, onClickOutside, ref, visible]);

    const onMenuItemSelected = (_link: LocalMenuNavItemLink, index: number) => {
      if (onLinkClick) {
        onLinkClick(items[index]);
      }
    };

    return (
      <div
        aria-hidden={!visible}
        aria-label={ariaLabel}
        className={classNames(
          classes.localMenuMobileNav,
          visible && classes.localMenuMobileNavVisible
        )}
        ref={ref}
        role="region"
      >
        <ContextMenu items={items} onLinkClick={onMenuItemSelected} />
      </div>
    );
  }
);
