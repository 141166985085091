import React, { memo } from 'react';
import classNames from 'classnames';
import { Property } from 'csstype';
import { createUseStyles } from 'react-jss';
import { ContextMenuItem, ContextMenuLink } from './ContextMenuItem/ContextMenuItem';
import { ContextMenuItemDarkTheme, contextMenuStyles } from './ContextMenu.style';

export interface ContextMenuProps {
  className?: string;
  dark?: boolean;
  items: ContextMenuLink[];
  maxWidth?: Property.MaxWidth | number;
  onLinkClick?: (link: ContextMenuLink, index: number) => void;
}

export const ContextMenu = memo(
  ({ items, onLinkClick, maxWidth, dark = false, className }: ContextMenuProps) => {
    const useContextMenuStyles = createUseStyles(contextMenuStyles);
    const classes = useContextMenuStyles({ maxWidth });

    return (
      <ul
        className={classNames(
          classes.contextMenuReset,
          className || classes.contextMenu,
          dark && classes.contextMenuDark
        )}
        data-testid="context-menu"
      >
        {items.map((item, index) => (
          <ContextMenuItem
            icon={item.icon}
            key={`${index.toString()}${item.title}`}
            link={item}
            theme={dark ? ContextMenuItemDarkTheme : undefined}
            onLinkClick={() => (onLinkClick ? onLinkClick(item, index) : undefined)}
          />
        ))}
      </ul>
    );
  }
);
