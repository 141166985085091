import 'focus-visible';
import classNames from 'classnames';
import { useFocusVisibleClassnames } from './useFocusVisible.css';

export const FOCUS_VISIBLE_ACTIVE_CLASS_NAME = 'focus-visible';

interface Props {
  always?: boolean;
  detectOnly?: boolean;
  inset?: boolean;
}

export const useFocusVisibleClassName = (
  props: Props | undefined = { always: false, inset: false, detectOnly: false }
) => {
  const { always = false, inset = false, detectOnly = false } = props;

  return classNames([
    'js-focus-visible',
    { [useFocusVisibleClassnames.focusVisible]: !detectOnly },
    { [FOCUS_VISIBLE_ACTIVE_CLASS_NAME]: always },
    { [useFocusVisibleClassnames.insetFocusVisible]: inset },
  ]);
};
