export const DEFAULT_FONT = 'SourceSansPro, sans-serif';
export const IMPORTANT_FONT = 'TvSansScreen, sans-serif';

const DEFAULT_FONT_SIZE = 16;

/* UTILS */

export const lineClamp = (numberOfLines: number) => ({
  overflow: 'hidden' as const,
  textOverflow: 'ellipsis' as const,
  display: '-webkit-box' as const,
  boxOrient: 'vertical' as const,
  lineClamp: numberOfLines,
});

export const pxToRem = (pixels: number): string => {
  return `${pixels / DEFAULT_FONT_SIZE}rem`;
};

/* HERO */

export const hero60 = {
  fontFamily: IMPORTANT_FONT,
  fontSize: pxToRem(60),
  lineHeight: pxToRem(64),
  letterSpacing: pxToRem(-1.5),
};

export const hero48 = {
  fontFamily: IMPORTANT_FONT,
  fontSize: pxToRem(48),
  lineHeight: pxToRem(52),
  letterSpacing: pxToRem(-1),
};

export const hero36 = {
  fontFamily: IMPORTANT_FONT,
  fontSize: pxToRem(36),
  lineHeight: pxToRem(40),
  letterSpacing: pxToRem(-0.4),
};

/* HEADER */

export const header28 = {
  fontFamily: IMPORTANT_FONT,
  fontSize: pxToRem(28),
  lineHeight: pxToRem(32),
  letterSpacing: 0,
};

export const header24 = {
  fontFamily: IMPORTANT_FONT,
  fontSize: pxToRem(24),
  lineHeight: pxToRem(28),
  letterSpacing: 0,
};

export const header21 = {
  fontFamily: IMPORTANT_FONT,
  fontSize: pxToRem(21),
  lineHeight: pxToRem(26),
  letterSpacing: 0,
};

export const header18 = {
  fontFamily: IMPORTANT_FONT,
  fontSize: pxToRem(18),
  lineHeight: pxToRem(22),
  letterSpacing: 0,
};

export const header16 = {
  fontFamily: IMPORTANT_FONT,
  fontSize: pxToRem(16),
  lineHeight: pxToRem(20),
  letterSpacing: 0,
};

/* BODY */

export const body21 = {
  fontFamily: DEFAULT_FONT,
  fontSize: pxToRem(21),
  lineHeight: pxToRem(28),
  letterSpacing: 0,
};

export const body18 = {
  fontFamily: DEFAULT_FONT,
  fontSize: pxToRem(18),
  lineHeight: pxToRem(24),
  letterSpacing: 0,
};

export const body16 = {
  fontFamily: DEFAULT_FONT,
  fontSize: pxToRem(16),
  lineHeight: pxToRem(22),
  letterSpacing: 0,
};

export const body14 = {
  fontFamily: DEFAULT_FONT,
  fontSize: pxToRem(14),
  lineHeight: pxToRem(20),
  letterSpacing: pxToRem(0.2),
};

/* CAPTION */

export const caption12 = {
  fontFamily: DEFAULT_FONT,
  fontSize: pxToRem(12),
  lineHeight: pxToRem(16),
  letterSpacing: pxToRem(0.4),
};

export const caption11 = {
  fontFamily: DEFAULT_FONT,
  fontSize: pxToRem(11),
  lineHeight: pxToRem(16),
  letterSpacing: pxToRem(0.5),
};

/* LABEL */

export const label16 = {
  fontFamily: DEFAULT_FONT,
  fontSize: pxToRem(16),
  lineHeight: pxToRem(18),
  letterSpacing: pxToRem(0.5),
  textTransform: 'uppercase' as const,
  fontWeight: 600,
};

export const label14 = {
  fontFamily: DEFAULT_FONT,
  fontSize: pxToRem(14),
  lineHeight: pxToRem(16),
  letterSpacing: pxToRem(0.5),
  textTransform: 'uppercase' as const,
  fontWeight: 600,
};

export const label12 = {
  fontFamily: DEFAULT_FONT,
  fontSize: pxToRem(12),
  lineHeight: pxToRem(14),
  letterSpacing: pxToRem(0.6),
  textTransform: 'uppercase' as const,
  fontWeight: 600,
};
