import { Blue_base, buildMediaQuery, tablet768 } from '@czechtv/styles';
import { GlobalHeaderStyleConfig } from '../../providers/GlobalHeaderProvider';
import { TWO_ROWS_HEIGHT_DESKTOP, TWO_ROWS_HEIGHT_MOBILE } from '../../constants/main';

export interface WrapperFactoryStylesProps {
  hasThirdRow?: boolean;
  scrollbarWidth?: number;
  style: GlobalHeaderStyleConfig;
}

export const wrapperFactoryStyles = {
  bodyDisableScroll: {}, // Class FK
  localMobileMenuOpened: {}, // Class FK - class pro body
  header: {
    backgroundColor: (props: WrapperFactoryStylesProps) => {
      return props.style.backgroundColor ?? '#fff';
    },
    color: Blue_base,
  },
  headerDark: {
    color: '#fff',
    backgroundColor: (props: WrapperFactoryStylesProps) => {
      return props.style.backgroundColor ?? Blue_base;
    },
  },
  headerPositionFixed: {
    boxShadow: (props: WrapperFactoryStylesProps) =>
      props.style.dark
        ? '0px 12px 24px -8px rgba(255, 255, 255, 0.15)'
        : '0px 12px 24px -8px rgba(0, 0, 0, 0.15)',
    backgroundColor: (props: WrapperFactoryStylesProps) =>
      props.style.dark ? Blue_base : props.style.fixedBackgroundColor ?? '#fff',
    zIndex: 100,
  },
  wrapperFixed: {
    position: 'fixed',
    zIndex: 100,
    top: 0,
    backgroundColor: (props: WrapperFactoryStylesProps) => {
      return props.style.backgroundColor ?? '#fff';
    },
    boxShadow: '0px 12px 24px -8px rgba(0, 0, 0, 0.15)',
    '$bodyDisableScroll &$wrapperFixed': {
      [buildMediaQuery({ minWidth: tablet768 })]: {
        paddingRight: 0,
      },
    },
  },
  wrapperAbsolute: {
    position: 'absolute',
    zIndex: 100,
  },
  wrapperFixedThirdRow: {
    top: -TWO_ROWS_HEIGHT_MOBILE,
    [buildMediaQuery({ minWidth: tablet768 })]: {
      top: -TWO_ROWS_HEIGHT_DESKTOP,
    },
  },
  wrapperFixedAnimated: {
    animation: 'ctg-header-show 360ms forwards',
  },
  wrapperFixedAnimatedThirdRow: {
    animation: 'ctg-header-show-2-mobile 360ms forwards',
    [buildMediaQuery({ minWidth: tablet768 })]: {
      animation: 'ctg-header-show-2 360ms forwards',
    },
  },
  '@keyframes ctg-header-show': {
    from: {
      opacity: 0,
      transform: 'translateY(-100%)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(0%)',
    },
  },
  '@keyframes ctg-header-show-2': {
    from: {
      opacity: 1,
      transform: `translateY(-${TWO_ROWS_HEIGHT_DESKTOP}px)`,
    },
    to: {
      opacity: 1,
      transform: 'translateY(0%)',
    },
  },
  '@keyframes ctg-header-show-2-mobile': {
    from: {
      opacity: 1,
      transform: `translateY(-${TWO_ROWS_HEIGHT_MOBILE}px)`,
    },
    to: {
      opacity: 1,
      transform: 'translateY(0%)',
    },
  },
};
