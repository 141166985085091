import { RefObject, useEffect } from 'react';
import { useGlobalHeaderContext } from '../providers/GlobalHeaderProvider';

interface UseKeyFocusCycleProps {
  active?: boolean;
  buttonRef?: RefObject<HTMLButtonElement>;
  containerRef: RefObject<HTMLElement>;
}

export const useKeyFocusCycle = ({ active, containerRef, buttonRef }: UseKeyFocusCycleProps) => {
  const { states, setStates } = useGlobalHeaderContext();
  useEffect(() => {
    const handleTabKey = (e: KeyboardEvent) => {
      if (!containerRef.current) {
        return;
      }

      const focusableElements = containerRef.current.querySelectorAll('a[href], button');
      const firstElementInContainer = focusableElements[0] as HTMLElement;
      const lastElementInContainer = focusableElements[focusableElements.length - 1] as HTMLElement;

      if (
        buttonRef?.current &&
        !e.shiftKey &&
        document.activeElement &&
        document.activeElement === buttonRef.current
      ) {
        firstElementInContainer.focus();
        e.preventDefault();
        return;
      }

      if (
        buttonRef?.current &&
        e.shiftKey &&
        document.activeElement &&
        document.activeElement === buttonRef.current
      ) {
        lastElementInContainer.focus();
        e.preventDefault();
        return;
      }

      if (
        !e.shiftKey &&
        document.activeElement &&
        document.activeElement === lastElementInContainer
      ) {
        if (!buttonRef?.current) {
          firstElementInContainer.focus();
          e.preventDefault();
          return;
        }

        buttonRef.current.focus();
        e.preventDefault();
        return;
      }

      if (
        e.shiftKey &&
        document.activeElement &&
        document.activeElement === firstElementInContainer
      ) {
        if (!buttonRef?.current) {
          lastElementInContainer.focus();
          e.preventDefault();
          return;
        }

        buttonRef.current.focus();
        e.preventDefault();
      }
    };

    const handleEscKey = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (buttonRef?.current) {
          buttonRef.current.focus();
          setStates({
            ...states,
            ...{
              searchMobileOpened: false,
              localMobileMenuNavOpened: false,
              globalMenuNavOpened: false,
              accountMenuNavOpened: false,
            },
          });
        }
      }
    };

    const keyListenersMap = new Map([
      ['Tab', handleTabKey],
      ['Escape', handleEscKey],
    ]);

    function keyListener(e: KeyboardEvent) {
      const listener = keyListenersMap.get(e.key);

      return listener && listener(e);
    }

    if (!active) {
      document.removeEventListener('keydown', keyListener);
    } else {
      document.addEventListener('keydown', keyListener);
    }

    return () => {
      document.removeEventListener('keydown', keyListener);
    };
  }, [active, buttonRef, containerRef, setStates, states]);
};
