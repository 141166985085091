import React, { memo, RefObject, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { tablet768, useMediaQuery } from '@czechtv/styles';
import { desktop1280, desktop920 } from '../../../common/breakpoints';
import { defaultLogo, desktopSHPCTLogo, mobileSHPCTLogo } from '../../common/defaultConfig';
import { useGlobalHeaderContext } from '../../providers/GlobalHeaderProvider';
import { GlobalMenu } from '../../components/GlobalMenu/GlobalMenu';
import { GlobalMenuNavButton } from '../../components/GlobalMenu/GlobalMenuNav/GlobalMenuNavButton/GlobalMenuNavButton';
import { GlobalMenuAccountWrapper } from '../../components/GlobalMenu/GlobalMenuAccount/GlobalMenuAccountWrapper/GlobalMenuAccountWrapper';
import { GlobalMenuAccountLoginLink } from '../../components/GlobalMenu/GlobalMenuAccount/GlobalMenuAccountLoginLink/GlobalMenuAccountLoginLink';
import { GlobalMenuSearch } from '../../components/GlobalMenu/GlobalMenuSearch/GlobalMenuSearch';
import {
  triggerGlobalMenuLogoLinkClick,
  triggerGlobalMenuNavClosed,
  triggerGlobalMenuNavOpened,
  triggerSuperHomepageNavItemLinkClick,
} from './GlobalMenuFactory.analytics';
import { globalMenuFactoryStyles } from './GlobalMenuFactory.style';

export interface GlobalMenuFactoryProps {
  accountMenuButtonRef?: RefObject<HTMLButtonElement>;
  globalMenuButtonRef?: RefObject<HTMLButtonElement>;
}
const useGlobalMenuFactoryStyles = createUseStyles(globalMenuFactoryStyles);

export const GlobalMenuFactory = memo(
  ({ globalMenuButtonRef, accountMenuButtonRef }: GlobalMenuFactoryProps) => {
    const classes = useGlobalMenuFactoryStyles();

    const { config, states, setStates } = useGlobalHeaderContext();
    const is920 = useMediaQuery({ minWidth: desktop920 });
    const is768 = useMediaQuery({ minWidth: tablet768 });
    const is1280 = useMediaQuery({ minWidth: desktop1280 });

    const isSuperHomepageNavVisible = useMemo(() => {
      return is920 && config.superHomepageNavVisible;
    }, [config.superHomepageNavVisible, is920]);

    const getLogoProps = useMemo(() => {
      let logoConfig = config.logo;

      if (config.superHomepageNavVisible && is768 && !is920 && states.searchMobileOpened) {
        logoConfig = mobileSHPCTLogo;
      } else if (config.superHomepageNavVisible && is920 && !is1280) {
        logoConfig = mobileSHPCTLogo;
      } else if (config.superHomepageNavVisible) {
        logoConfig = desktopSHPCTLogo;
      }

      return logoConfig;
    }, [
      config.superHomepageNavVisible,
      config.logo,
      is1280,
      is768,
      is920,
      states.searchMobileOpened,
    ]);

    const onClickMenuNavButton = useCallback(
      (_event: React.MouseEvent<HTMLElement>, nav: string) => {
        const statesNew = {
          ...states,
          ...(nav === 'accountMenuNavOpened' &&
            states.globalMenuNavOpened && { globalMenuNavOpened: !states.globalMenuNavOpened }),
          ...(nav === 'globalMenuNavOpened' &&
            states.accountMenuNavOpened && { accountMenuNavOpened: !states.accountMenuNavOpened }),
          ...{ [nav]: !states[nav as keyof typeof states] },
          ...{
            localMobileMenuNavOpened: !states.globalMenuNavOpened
              ? false
              : states.localMobileMenuNavOpened,
          },
        };
        setStates(statesNew);

        // TODO dodelat analytiku, takhle to na obe menu posila stejne eventy
        if (statesNew.globalMenuNavOpened || statesNew.accountMenuNavOpened) {
          triggerGlobalMenuNavOpened();
        } else {
          triggerGlobalMenuNavClosed();
        }
      },
      [setStates, states]
    );

    return (
      <>
        <GlobalMenu
          className={classNames(states.searchMobileOpened && classes.mobileSearchOpened, {
            dark: states.isDark || states.style.headerFirstRow?.darkTheme,
          })}
          hasSuperHomepageNav={config.superHomepageNavVisible}
          isSuperHomepageNavVisible={isSuperHomepageNavVisible}
          logoProps={{
            onClick: triggerGlobalMenuLogoLinkClick,
            link: getLogoProps ?? defaultLogo,
          }}
          onClickSuperHomepageNavItemLink={triggerSuperHomepageNavItemLinkClick}
        >
          {config.search && <GlobalMenuSearch {...config.search} />}

          {config.account && (
            <GlobalMenuAccountWrapper>
              <GlobalMenuAccountLoginLink
                accountConfig={config.account}
                active={states.accountMenuNavOpened}
                buttonRef={accountMenuButtonRef}
                onClick={(e) => {
                  onClickMenuNavButton(e, 'accountMenuNavOpened');
                }}
              />
            </GlobalMenuAccountWrapper>
          )}
          {!states.searchMobileOpened && (
            <GlobalMenuNavButton
              active={states.globalMenuNavOpened}
              buttonRef={globalMenuButtonRef}
              onClick={(e) => {
                onClickMenuNavButton(e, 'globalMenuNavOpened');
              }}
            />
          )}
        </GlobalMenu>
      </>
    );
  }
);
