import React, { memo, RefObject } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { buildMediaQuery, tablet768, mobile428 } from '@czechtv/styles';
import analytics from '../../../common/analytics';
import { GLOBAL_MENU_DESKTOP_HEIGHT, GLOBAL_MENU_MOBILE_HEIGHT } from '../../constants/main';
import { useGlobalHeaderContext } from '../../providers/GlobalHeaderProvider';
import { GlobalMenuNavChannel } from '../../components/GlobalMenu/GlobalMenuNav/GlobalMenuNavChannels/GlobalMenuNavChannels';
import { ContextMenuLink } from '../../components/ContextMenu/ContextMenuItem/ContextMenuItem';
import { GlobalMenuNav } from '../../components/GlobalMenu/GlobalMenuNav/GlobalMenuNav';
import { channels, primaryItems, secondaryItems } from '../../constants/globalMenuNavItems';

const useStyles = createUseStyles({
  bodyDisableScroll: {}, // Class FK
  globalMenuNavWrapper: {
    position: 'fixed',
    top: GLOBAL_MENU_MOBILE_HEIGHT,
    height: `calc(100% - ${GLOBAL_MENU_MOBILE_HEIGHT}px)`,
    width: '100%',
    zIndex: 101,
    overflow: 'auto',
    right: 0,
    visibility: 'hidden',
    boxSizing: 'border-box',
    [buildMediaQuery({ minWidth: mobile428 })]: {
      width: 'fit-content',
      padding: [8],
    },
    [buildMediaQuery({ minWidth: tablet768 })]: {
      padding: [16],
      top: GLOBAL_MENU_DESKTOP_HEIGHT,
      height: `calc(100% - ${GLOBAL_MENU_DESKTOP_HEIGHT}px)`,
    },
  },
  globalMenuNav: {
    marginLeft: 'auto',
  },
  globalMenuNavOpen: {
    visibility: 'visible',
    backgroundColor: 'rgba(0, 0, 0, .3)',
    [buildMediaQuery({ minWidth: mobile428 })]: {
      backgroundColor: 'transparent',
    },
  },
});

export interface GlobalMenuNavFactoryProps {
  globalMenuButtonRef?: RefObject<HTMLButtonElement>;
}

export const GlobalMenuNavFactory = memo(({ globalMenuButtonRef }: GlobalMenuNavFactoryProps) => {
  const classes = useStyles();

  const { config, states, setStates } = useGlobalHeaderContext();

  // zatim dark mode tady nechceme
  // const isDark = config.style?.default?.dark ?? false;

  const hideGlobalMenuNav = () => {
    const menuAnalyticsData = {
      menu: {
        type: 'global',
      },
      interaction: true,
      _clear: true,
    };

    analytics.trigger({
      type: 'MenuClose',
      data: menuAnalyticsData,
    });
    setStates({ ...states, ...{ globalMenuNavOpened: false } });
  };

  const onLinkClick = (e: ContextMenuLink | GlobalMenuNavChannel) => {
    const menuAnalyticsData = {
      menu: {
        type: 'global',
        level1: e.title,
      },
      interaction: true,
      _clear: true,
    };

    analytics.trigger({ type: 'MenuClick', data: menuAnalyticsData });
  };

  return (
    <div
      className={classNames(
        classes.globalMenuNavWrapper,
        states.globalMenuNavOpened && classes.globalMenuNavOpen
      )}
    >
      <GlobalMenuNav
        buttonRef={globalMenuButtonRef}
        channelItems={channels}
        hasMainChannelLink={!config.superHomepageNavVisible}
        isDark={false} // zatim dark mode tady nechceme
        primaryItems={primaryItems}
        secondaryItems={secondaryItems}
        visible={states.globalMenuNavOpened}
        onClickOutside={hideGlobalMenuNav}
        onLinkClick={onLinkClick}
      />
    </div>
  );
});
