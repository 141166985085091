import classNames from 'classnames';
import React, { ButtonHTMLAttributes, forwardRef, memo } from 'react';
import { useFocusVisibleClassName } from '../../hooks/useFocusVisible';
import { nativeButtonClassnames } from './NativeButton.css';

export const NativeButton = memo(
  forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(
    ({ className, ...rest }, ref) => {
      const focusVisibleClassName = useFocusVisibleClassName();

      return (
        <button
          className={classNames(className, nativeButtonClassnames.button, focusVisibleClassName)}
          {...rest}
          ref={ref}
          // eslint-disable-next-line react/button-has-type
          type={rest.type !== undefined ? rest.type : 'button'}
        />
      );
    }
  )
);
