import { Blue_base, DEFAULT_FONT } from '@czechtv/styles';
import { mediaMaxTablet767 } from '../../../../../common/breakpoints';
import { universalWrapperStyles } from '../../../../factories/GlobalMenuFactory/GlobalMenuFactory.style';

export const globalMenuNavButtonStyles = {
  globalMenuNavButtonWrapper: {
    ...universalWrapperStyles,
    display: 'flex',
    alignItems: 'center',
    color: Blue_base,

    '& > button': {
      display: 'flex',
      alignItems: 'center',
      padding: [10, 20],
      // color: 'inherit',
    },
    [mediaMaxTablet767]: {
      borderLeft: 0,
      '$mobileSearchOpened &': {
        display: 'none',
      },
    },
    '&.dark': {
      color: '#fff',
      borderLeftColor: 'rgba(255, 255, 255, 0.2)',
      '& $searchInput': {
        color: '#fff',
      },
      '@media (hover: hover)': {
        '& > a:hover, & > button:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
      },
      '& > $menuButtonActive': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    },
  },
  menuButton: {
    '& > svg': {
      height: 24,
      width: 24,
      marginRight: '8px',
      color: 'inherit',
    },
    '& > span': {
      color: 'inherit',
      fontFamily: DEFAULT_FONT,
      lineHeight: '24px',
    },
  },
  menuButtonActive: {},
  insetFocusVisible: {},
};
