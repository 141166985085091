import React from 'react';
import { GlobalHeaderConfig, GlobalHeaderProvider } from './providers/GlobalHeaderProvider';
import { GlobalHeaderFactory } from './factories/GlobalHeaderFactory/GlobalHeaderFactory';
import { GlobalHeaderJssProvider } from './providers/GlobalHeaderJssProvider';

export const GlobalHeader: {
  (props: GlobalHeaderConfig): React.ReactElement<GlobalHeaderConfig>;
} = (props) => (
  <GlobalHeaderJssProvider>
    <GlobalHeaderProvider config={props}>
      <GlobalHeaderFactory />
    </GlobalHeaderProvider>
  </GlobalHeaderJssProvider>
);
