import React, { memo, RefObject } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { NativeButton } from '@czechtv/components';
import { useGlobalHeaderContext } from '../../../providers/GlobalHeaderProvider';
import { localMenuMobileButtonStyles } from './LocalMenuMobileButton.style';

export interface GlobalHeaderLocalMenuMobileButtonProps {
  active?: boolean;
  buttonRef?: RefObject<HTMLButtonElement>;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}
const useLocalMenuMobileButtonStyles = createUseStyles(localMenuMobileButtonStyles);

export const LocalMenuMobileButton = memo(
  ({ onClick, active, buttonRef }: GlobalHeaderLocalMenuMobileButtonProps) => {
    const classes = useLocalMenuMobileButtonStyles();
    const { config } = useGlobalHeaderContext();

    return (
      <div className={classNames(classes.localMenuMobileButtonWrapper)}>
        <NativeButton
          aria-label={`${active ? 'Skrýt otevřený' : 'Otevřít'} výběr kategorií`} // todo: `kategorií` by mělo jít nastavováno z configu (Přes props a nastaveno ve view)
          className={classNames(
            classes.localMenuMobileButton,
            active && classes.localMenuMobileButtonActive,
            classes.insetFocusVisible
          )}
          data-testid="menu-button"
          ref={buttonRef}
          tabIndex={0}
          type="button"
          onClick={onClick}
        >
          <svg
            fill="none"
            height="24"
            viewBox="0 0 24 25"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              d="M20.4 16.5a.6.6 0 01.6.6v.8a.6.6 0 01-.6.6H3.6a.6.6 0 01-.6-.6v-.8a.6.6 0 01.6-.6h16.8zm0-5a.6.6 0 01.6.6v.8a.6.6 0 01-.6.6H3.6a.6.6 0 01-.6-.6v-.8a.6.6 0 01.6-.6h16.8zm0-5a.6.6 0 01.6.6v.8a.6.6 0 01-.6.6H3.6a.6.6 0 01-.6-.6v-.8a.6.6 0 01.6-.6h16.8z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>
          <span>{config.productMenu?.mobileTitle ?? 'Kategorie'}</span>
        </NativeButton>
      </div>
    );
  }
);
