import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useMediaQuery } from '@czechtv/styles';
import { Link } from '../../../Link';
import analytics from '../../../../../common/analytics';
import { LocalMenuNavItemLink } from '../../LocalMenuNav/LocalMenuNavItem/LocalMenuNavItem';
import { tablet639 } from '../../../../../common/breakpoints';
import { localMenuHotlinkNavItemStyles } from './LocalMenuHotlinkNavItem.style';

export interface LocalMenuHotlinkNavItemLink extends Omit<LocalMenuNavItemLink, 'href'> {
  buttonAriaLabel?: string;
  href?: string;
  icon?: React.SVGProps<SVGSVGElement>;
  mobileTitle?: string;
  onHotlinkClick?: (e: Event) => void;
  styles?: LocalMenuHotlinkNavItemStyles;
}

export interface LocalMenuHotlinkNavItemStyles {
  activeOrHover?: LocalMenuHotlinkNavItemStyle;
  default?: LocalMenuHotlinkNavItemStyle;
  floating?: LocalMenuHotlinkNavItemStyle;
  icon?: LocalMenuHotlinkNavItemIconStyle;
  theme?: Jss.Theme;
}

export interface LocalMenuHotlinkNavItemStyle {
  backgroundColor?: string;
  color?: string;
}

export interface LocalMenuHotlinkNavItemIconStyle {
  activeOrHoverColor?: string;
  color?: string;
}

export interface LocalMenuHotlinkNavItemProps extends LocalMenuHotlinkNavItemLink {
  isFloatingStyle?: boolean;
}
const useLocalMenuHotlinkNavItemStyles = createUseStyles(localMenuHotlinkNavItemStyles);

export const LocalMenuHotlinkNavItem = ({
  isFloatingStyle,
  styles: stylesProp,
  active,
  title,
  mobileTitle,
  href,
  disabled,
  icon,
  buttonAriaLabel,
  onHotlinkClick,
  ...rest
}: LocalMenuHotlinkNavItemProps) => {
  const isMobile = useMediaQuery({ maxWidth: tablet639 });

  const [styles, setStyles] = useState<LocalMenuHotlinkNavItemStyles | undefined>(undefined);

  const classes = useLocalMenuHotlinkNavItemStyles(styles);

  useEffect(() => {
    const styles = { ...stylesProp };

    if (isFloatingStyle && styles.floating) {
      styles.default = styles.floating;
    }

    setStyles(styles);
  }, [isFloatingStyle, stylesProp]);

  const onLinkClick = (e: LocalMenuHotlinkNavItemLink) => {
    const menuAnalyticsData = {
      menu: {
        type: 'product',
        level1: e.title,
      },
      interaction: true,
      _clear: true,
    };

    analytics.trigger({ type: 'MenuClick', data: menuAnalyticsData });
  };

  return (
    <li
      className={classNames(classes.hotlinkNavListItem, active && classes.hotlinkNavListItemActive)}
    >
      {active && !onHotlinkClick && (
        <span aria-current="page" aria-label={title}>
          <span aria-hidden>{isMobile && mobileTitle ? mobileTitle : title}</span>
        </span>
      )}
      {(!active || onHotlinkClick) && (
        <Link
          aria-label={title}
          buttonAriaLabel={buttonAriaLabel}
          className={classes.insetFocusVisible}
          disabled={!!disabled}
          href={href || ''}
          isNotLink={!!onHotlinkClick}
          onClick={(e: any) => {
            onLinkClick({ active, title, href, disabled });
            if (onHotlinkClick) {
              onHotlinkClick(e);
            }
          }}
          {...rest}
        >
          <>
            {icon}
            <span aria-hidden>{isMobile && mobileTitle ? mobileTitle : title}</span>
          </>
        </Link>
      )}
    </li>
  );
};
