import React, { createRef, memo, RefObject, useEffect } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useKeyFocusCycle } from '../../../uses/useKeyFocusCycle';
import { ContextMenuLink } from '../../ContextMenu/ContextMenuItem/ContextMenuItem';
import { ContextMenu } from '../../ContextMenu/ContextMenu';
import { MenuNavWrapper } from './MenuNavWrapper/MenuNavWrapper';
import { globalMenuNavStyles } from './GlobalMenuNav.style';
import {
  GlobalMenuNavChannel,
  GlobalMenuNavChannels,
} from './GlobalMenuNavChannels/GlobalMenuNavChannels';

export interface GlobalMenuNavProps {
  ariaLabel?: string;
  buttonRef?: RefObject<HTMLButtonElement>;
  channelItems?: GlobalMenuNavChannel[];
  hasMainChannelLink?: boolean;
  isDark: boolean;
  onClickOutside?: () => void;
  onLinkClick?: (item: ContextMenuLink | GlobalMenuNavChannel) => void;
  primaryItems?: ContextMenuLink[];
  secondaryItems?: ContextMenuLink[];
  visible?: boolean;
}
const useGlobalMenuNavStyles = createUseStyles(globalMenuNavStyles);

export const GlobalMenuNav = memo(
  ({
    visible = false,
    onClickOutside = () => {},
    hasMainChannelLink = false,
    buttonRef,
    ariaLabel = 'Výběr produktů České televize',
    onLinkClick,
    channelItems,
    isDark,
    primaryItems,
    secondaryItems,
  }: GlobalMenuNavProps) => {
    const ref: RefObject<HTMLDivElement> = createRef();
    const classes = useGlobalMenuNavStyles();

    useKeyFocusCycle({ containerRef: ref, buttonRef, active: visible });

    useEffect(() => {
      const handleOnClick = (e: Event) => {
        if (ref.current && !ref.current.contains(e.target as Node) && visible) {
          // Pri kliknuti mimo rozbalene menu skryjeme menu
          if (buttonRef?.current?.contains(e.target as Node)) {
            return;
          }
          onClickOutside();
        }
      };

      document.addEventListener('click', handleOnClick);

      return () => {
        document.removeEventListener('click', handleOnClick);
      };
    }, [onClickOutside, ref, visible, buttonRef]);

    const primaryItemsModified =
      primaryItems &&
      primaryItems.map((item) => {
        return {
          ...item,
          ...{
            active:
              typeof window !== 'undefined'
                ? window.location.href.toString().includes(String(item.href))
                : false,
          },
        };
      });

    const secondaryItemsModified =
      secondaryItems &&
      secondaryItems.map((item) => {
        return {
          ...item,
          ...{
            active:
              typeof window !== 'undefined'
                ? window.location.href.toString().includes(String(item.href))
                : false,
          },
        };
      });

    const onItemSelected = (link: ContextMenuLink) => {
      if (onLinkClick) {
        onLinkClick(link);
      }
    };

    const onChannelItemSelected = (item: GlobalMenuNavChannel) => {
      if (onLinkClick) {
        onLinkClick(item);
      }
    };

    return (
      <MenuNavWrapper
        ariaLabel={ariaLabel}
        datatestid="ctg-global-menu"
        id="global-menu"
        ref={ref}
        visible={visible}
      >
        {channelItems && (
          <GlobalMenuNavChannels
            hasMainChannelLink={hasMainChannelLink}
            isDark={isDark}
            items={channelItems}
            onLinkClick={onChannelItemSelected}
          />
        )}

        {primaryItemsModified && (
          <ContextMenu
            className={classNames(classes.contextMenuPrimary)}
            items={primaryItemsModified}
            onLinkClick={onItemSelected}
          />
        )}

        {secondaryItemsModified && (
          <ContextMenu
            className={classNames(classes.contextMenuSecondary)}
            items={secondaryItemsModified}
            onLinkClick={onItemSelected}
          />
        )}
      </MenuNavWrapper>
    );
  }
);
