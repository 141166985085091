export const localMenuHotlinkNavStyles = {
  hotlinkNav: {},
  hotlinkNavList: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  hotlinkNavButtonContainer: {
    position: 'relative',
  },
  hotlinkWrapperDark: {},
};
