import React, { memo, RefObject } from 'react';
import classnames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useGlobalHeaderContext } from '../../providers/GlobalHeaderProvider';
import LocalMenuSecondary from '../LocalMenuSecondary/LocalMenuSecondary';
import { LocalMenuMobileButton } from './LocalMenuMobileButton/LocalMenuMobileButton';
import { LocalMenuHotlinkNavItemLink } from './LocalMenuHotlinkNav/LocalMenuHotlinkNavItem/LocalMenuHotlinkNavItem';
import { localMenuStyles } from './LocalMenu.style';
import { CTGHeaderHotlinkNav } from './LocalMenuHotlinkNav/LocalMenuHotlinkNav';
import { LocalMenuNav } from './LocalMenuNav/LocalMenuNav';
import { LocalMenuNavItemLink } from './LocalMenuNav/LocalMenuNavItem/LocalMenuNavItem';

export interface LocalMenuProps {
  ariaHidden?: boolean;
  hotlinks: LocalMenuHotlinkNavItemLink[];
  isFloatingStyle?: boolean;
  items: LocalMenuNavItemLink[];
  maxWidth?: number;
  mobileMenuButtonActive?: boolean;
  mobileMenuButtonRef?: RefObject<HTMLButtonElement>;
  onClickMobileMenuButton?: (event: React.MouseEvent<HTMLElement>) => void;
}
const useLocalMenuStyles = createUseStyles(localMenuStyles);

export const LocalMenu = memo(
  ({
    hotlinks,
    items,
    ariaHidden = false,
    mobileMenuButtonActive,
    onClickMobileMenuButton,
    mobileMenuButtonRef,
    isFloatingStyle,
    maxWidth,
  }: LocalMenuProps) => {
    const classes = useLocalMenuStyles();
    const { config, states } = useGlobalHeaderContext();
    const activeItem = items.find((item) => item.active);
    const hasSecondaryMenu = activeItem && activeItem.items && activeItem.items.length > 0;

    return (
      <div
        aria-hidden={ariaHidden}
        className={classnames(classes.localMenuWrapper)}
        style={{ maxWidth: maxWidth != null ? `${maxWidth}px` : undefined }}
      >
        <nav aria-label="Produktová">
          <div
            className={classnames(classes.localMenu, {
              hideBottomLine:
                states.isFixed && !states.localMobileMenuNavOpened && !hasSecondaryMenu,
              secondaryMenu: hasSecondaryMenu,
            })}
          >
            <LocalMenuMobileButton
              active={mobileMenuButtonActive}
              buttonRef={mobileMenuButtonRef}
              onClick={onClickMobileMenuButton}
            />

            <LocalMenuNav
              ariaLabel={config.productMenu?.mobileTitle ?? 'Kategorie'} // todo: mělo by být nastavováno z configu (Přes props a nastaveno ve view)
              className={classnames(classes.localNavWrapper)}
              items={items}
            />

            <CTGHeaderHotlinkNav
              ariaLabel="Hotlinks"
              className={classnames(classes.hotlinkNavWrapper)}
              isFloatingStyle={isFloatingStyle}
              items={hotlinks}
            />
          </div>
          <div className={classes.localMenuSecondaryWrapper}>
            {activeItem && activeItem.items && (
              <LocalMenuSecondary isDark={states.isDark} items={activeItem.items} />
            )}
          </div>
        </nav>
      </div>
    );
  }
);
