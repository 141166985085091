// global style constants
import { Red_base } from '@czechtv/utils';

export {
  Channel_CT1,
  Channel_CT2,
  Channel_CT3,
  Channel_CT24,
  Channel_CT_Sport,
  Channel_CT_Art,
  Channel_CT_D,
  Red_base,
} from '@czechtv/utils';

export const DefaultRadius = 4;
export const DefaultRadiusSmall = 2;

export const Grey_10 = '#f4f4f4';
export const Grey_20 = '#e0e0e0';
export const Grey_30 = '#c6c6c6';
export const Grey_40 = '#a8a8a8';
export const Grey_50 = '#8d8d8d';
export const Grey_60 = '#6f6f6f';
export const Grey_70 = '#525252';
export const Grey_80 = '#393939';
export const Grey_100 = '#161616';
export const SkeletonGrey1 = '#d8d8dc';
export const SkeletonGrey2 = '#e4e4e8';
export const Nero = '#292929';
export const BlackOpacity50 = 'rgba(0,0,0, .5)';
export const BlackOpacity25 = 'rgba(0,0,0, .25)';
export const BlackOpacity10 = 'rgba(0, 0, 0, 0.1)';
export const WhiteOpacity20 = 'rgba(255, 255, 255, 0.2)';
export const WhiteOpacity40 = 'rgba(255, 255, 255, 0.4)';
export const WhiteOpacity80 = 'rgba(255, 255, 255, 0.8)';
export const WhiteOpacity60 = 'rgba(255, 255, 255, 0.6)';
export const Valid = '#6dd400';
export const Red_base_opacity50 = 'rgba(237,28,36, .5)';
export const Red_lighter_10 = '#fa5c5f';
export const Red_lighter_50 = '#f58d91';
export const Red_darker_10 = '#bf141b';
export const Red_lighter_20 = '#fb8d8f';
export const Blue_base = '#041e42';
export const Blue_dark_background = '#001830';
export const Blue_lighter_60 = '#8faefa';
export const Blue_lighter_40 = '#2479e7';
export const Blue_lighter_20 = '#124990';
export const Focus_base = '#60a0ff';
export const Notice = '#edb01c';

export const DefaultBoxShadow =
  '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 12px 24px -8px rgba(0, 0, 0, 0.15)';

export const ScrimBackground = 'rgba(0, 0, 0, .3)';

/* DefaultBoxShadowInner neodpovida kodem box-shadow v abstractu
   je to tak schvalne aby odpovidal vizualne */
export const DefaultBoxShadowInner = `inset rgba(0,0,0, .13) 0px 1px 3px 0px`;
export const BoxShadowDefault = `inset 0px 0px 0px 1px ${Grey_30}`;
export const BoxShadowDisabled = `inset 0px 0px 0px 1px ${Grey_20}`;
export const BoxShadowFocus = `0px 0px 0px 2px ${Focus_base}`;
export const BoxShadowFocusInset = `inset ${BoxShadowFocus}`;
export const BoxShadowError = `inset 0px 0px 0px 2px ${Red_base}`;
export const BoxShadowHover = `inset 0px 0px 0px 1px ${Grey_40}`;
export const BasicBoxShadow = `0px 1px 2px 0px ${BlackOpacity10}`;

export const SelectInput = `${DefaultBoxShadowInner}, ${BoxShadowDefault}`;
export const SelectInputFocus = `${DefaultBoxShadowInner}, ${BoxShadowFocus}`;
export const SelectInputError = `${DefaultBoxShadowInner}, ${BoxShadowError}`;
export const SelectInputHover = `${DefaultBoxShadowInner}, ${BoxShadowHover}`;

export const ButtonTransitionDuration = '150ms';
