export const footerLinks = [
  {
    href: 'https://www.ceskatelevize.cz/zive/',
    title: 'Živé vysílání',
    testId: 'zive_vysilani',
    id: 'ziveVysilani',
  },
  {
    href: 'https://www.ceskatelevize.cz/vse-o-ct/',
    title: 'Vše o ČT',
    testId: 'vse_o_ct',
    id: 'vseoCt',
  },
  {
    href: 'https://www.ceskatelevize.cz/tv-program/',
    title: 'TV program',
    testId: 'tv_program',
    id: 'tvProgram',
  },
  {
    href: 'https://www.ceskatelevize.cz/brno/',
    title: 'Studio Brno',
    testId: 'studio_brno',
    id: 'studioBrno',
  },
  {
    href: 'https://ct24.ceskatelevize.cz/pocasi',
    title: 'Počasí',
    testId: 'pocasi',
    id: 'pocasi',
  },
  {
    href: 'https://www.ceskatelevize.cz/ostrava/',
    title: 'Studio Ostrava',
    testId: 'studio_ostrava',
    id: 'studioOstrava',
  },
  {
    href: 'https://www.ceskatelevize.cz/hbbtv/',
    title: 'HbbTV aplikace',
    testId: 'hbbtv_aplikace',
    id: 'hbbtvAplikace',
  },
  {
    href: 'https://www.ceskatelevize.cz/rada-ct/',
    title: 'Rada ČT',
    testId: 'rada_ct',
    id: 'radaCt',
  },
  {
    href: 'https://www.ceskatelevize.cz/aplikace/',
    title: 'Mobilní aplikace',
    testId: 'mobilni_aplikace',
    id: 'mobilniAplikace',
  },
  {
    href: 'https://www.ceskatelevize.cz/vse-o-ct/reklama/',
    title: 'Reklama',
    testId: 'reklama',
    id: 'reklama',
  },
  {
    href: 'https://poplatky.ceskatelevize.cz/domacnost',
    title: 'TV poplatky',
    testId: 'tv_poplatky',
    id: 'tvPoplatky',
  },
  {
    href: 'https://www.ceskatelevize.cz/vse-o-ct/kariera',
    title: 'Kariéra',
    testId: 'kariera',
    id: 'kariera',
  },
  {
    href: 'https://eshop.ceskatelevize.cz/',
    title: 'E-shop',
    testId: 'eshop',
    id: 'eshop',
  },
  {
    href: 'https://www.ceskatelevize.cz/vse-o-ct/vyberova-rizeni-konkurzy/konkurzy-pozvanky-na-nataceni/',
    title: 'Konkurzy',
    testId: 'konkurzy',
    id: 'konkurzy',
  },
  {
    href: 'https://www.ceskatelevize.cz/decko-pro-rodice/uvod/',
    title: 'Déčko pro rodiče',
    testId: 'decko_pro_rodice',
    id: 'deckoProRodice',
  },
  {
    href: 'https://www.ceskatelevize.cz/vse-o-ct/press/',
    title: 'Pro média',
    testId: 'pro_media',
    id: 'proMedia',
  },
  {
    href: 'https://teletext.ceskatelevize.cz/',
    title: 'Teletext',
    testId: 'teletext',
    id: 'teletext',
  },
  {
    href: 'https://www.ceskatelevize.cz/vse-o-ct/podavani-nametu-a-projektu/',
    title: 'Pro autory',
    testId: 'pro_autory',
    id: 'proAutory',
  },
  {
    href: 'https://www.ceskatelevize.cz/faq/',
    title: 'Časté dotazy',
    testId: 'caste_dotazy',
    id: 'casteDotazy',
  },
  {
    href: 'https://www.ceskatelevize.cz/telexport/',
    title: 'Telexport',
    testId: 'telexport',
    id: 'telexport',
  },
  {
    href: 'https://www.ceskatelevize.cz/filmy/',
    title: 'Zveme Vás do kina',
    testId: 'zveme_vas_do_kina',
    id: 'zvemeVasDoKina',
  },
  {
    href: 'https://www.ceskatelevize.cz/vse-o-ct/produkcni-sluzby/',
    title: 'Produkční služby',
    testId: 'produkcni_sluzby',
    id: 'produkcniSluzby,',
  },
  {
    href: 'https://www.ceskatelevize.cz/vse-o-ct/kontakty/',
    title: 'Kontakty',
    testId: 'kontakty',
    id: 'kontakty',
  },
];

const PROD_STATIC = 'https://ctfs.ceskatelevize.cz/static/';
// const DEV_STATIC = 'https://ctfs-development.vecko.dev/static/assets/images/';

export const staticUrl = PROD_STATIC;
export const staticUrlChannels = `${PROD_STATIC}channels/`;
export const staticUrlIcons = `${PROD_STATIC}icons/`;
