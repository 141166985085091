import { Blue_base } from '@czechtv/styles';
import { mediaMaxTablet767, min768max1279 } from '../../../common/breakpoints';

export const universalWrapperStyles = {
  color: Blue_base,
  borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
  height: '100%',
  boxSizing: 'border-box',
  '& > a, & > button': {
    transition: 'backgroundColor 200ms,color 200ms',
  },
  '& $searchInput': {
    color: '#000',
  },
  '@media (hover: hover)': {
    '& > a:hover, & > button:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  ' & > $menuButtonActive': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
};

export const globalMenuFactoryStyles = {
  headerDark: {}, // Class FK
  hasSHPNav: {}, // Class FK
  searchWrapperMobileOpened: {}, // Class FK
  searchInput: {}, // Class FK
  menuButtonActive: {}, // Class FK
  mobileSearchOpened: {},
  globalMenuWrapper: {
    '&:not($hasSHPNav) $globalMenuLogoWrapper + $globalMenuRightItems > $searchWrapperMobile': {
      borderLeft: 0,
    },
    '$headerDark &': {
      color: '#fff',
      borderColor: 'rgba(255, 255, 255, 0.2)',
    },
  },
  globalMenuRightItems: {
    '$globalMenuWrapper$mobileSearchOpened &': {
      marginLeft: 0,
      flex: '1 1 auto',
    },
  },

  globalMenuSuperHomepageNav: {
    '$mobileSearchOpened &': {
      display: 'none',
    },
  },
  globalMenuSuperHomepageNavItemLink: {
    '$headerDark &': {
      color: '#FFFFFF',
      '&:hover': {
        color: '#fff',
      },
    },
  },
  globalMenuLogoWrapper: {
    '$mobileSearchOpened:not($hasSHPNav) &': {
      display: 'none',
    },
    [mediaMaxTablet767]: {
      '$mobileSearchOpened &': {
        display: 'none',
      },
    },
  },
  menuButton: {
    '$globalMenuWrapper &': {
      height: '100%',
      [mediaMaxTablet767]: {
        padding: [10, 10],
        '& > span': {
          display: 'none',
        },
        '& > svg': {
          marginRight: 0,
        },
      },
    },
  },
  searchWrapperMobile: {
    '$globalMenuWrapper$hasSHPNav &:not($searchWrapperMobileOpened)': {
      borderLeft: 0,
    },
    [mediaMaxTablet767]: {
      '$globalMenuWrapper$hasSHPNav &$searchWrapperMobileOpened': {
        borderLeft: 0,
      },
    },
    [min768max1279]: {
      '$globalMenuWrapper$hasSHPNav &:not($searchWrapperMobileOpened)': {
        width: 'auto',
      },
    },
  },
  searchMobileHideButton: {
    [min768max1279]: {
      '$hasSHPNav &': {
        borderLeft: 0,
        width: 56,
      },
    },
  },
  accountLoginLink: {
    '$globalMenuWrapper &': {
      [mediaMaxTablet767]: {
        padding: [10, 10],
        '& > svg': {
          display: 'block',
        },
        '& > span': {
          display: 'none',
        },
      },
    },
  },
};
