import React, { forwardRef } from 'react';
import classNames from 'classnames';
import {
  CustomLinkComponentType,
  LinkProps,
  useGlobalHeaderContext,
} from '../providers/GlobalHeaderProvider';

export const Link: React.ForwardRefRenderFunction<HTMLAnchorElement, LinkProps> = React.memo(
  forwardRef<HTMLAnchorElement, LinkProps>((props: LinkProps, ref) => {
    const { config } = useGlobalHeaderContext();

    const CustomLinkComponent = config.CustomLinkComponent as CustomLinkComponentType;

    const linkClassName = classNames(props.className);

    const { ...restCustom } = props;

    const {
      children,
      className,
      active,
      customLinkProps,
      isNotLink,
      onClick,
      buttonAriaLabel,
      ...rest
    } = props;

    // pokud dorazi komponenta linku z vnejsku, pouzijeme ji
    if (config.CustomLinkComponent) {
      return <CustomLinkComponent {...restCustom}>{children}</CustomLinkComponent>;
    }

    if (isNotLink) {
      return (
        <button
          aria-label={buttonAriaLabel}
          className={linkClassName}
          type="button"
          onClick={onClick}
        >
          {children}
        </button>
      );
    }

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <a
        className={linkClassName}
        ref={ref}
        onClick={onClick}
        onKeyDown={(e) => {
          e.key === 'Enter' ?? onClick;
        }}
        {...rest}
      >
        {children}
      </a>
    );
  })
);
