import React, { memo, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { Blue_base, body14 } from '@czechtv/styles';
import { useFocusVisibleClassName } from '@czechtv/components';
import { LocalMenuNavItemLink } from '../LocalMenu/LocalMenuNav/LocalMenuNavItem/LocalMenuNavItem';
import { Link } from '../Link';
import { useGlobalHeaderContext } from '../../providers/GlobalHeaderProvider';
import analytics from '../../../common/analytics';

interface LocalMenuSecondaryProps {
  isDark?: boolean;
  items: LocalMenuNavItemLink[];
}

interface StylesOptions {
  scrimGradientBaseColor?: string;
}

const useStyles = createUseStyles({
  itemList: {
    ...body14,
    height: 48,
    display: 'flex',
    margin: 0,
    padding: 0,
  },

  item: {
    ...body14,
    color: Blue_base,
    display: 'flex',
    alignItems: 'center',
    textWrap: 'nowrap',
    listStyle: 'none',
    '&.active': {
      boxShadow: ` inset 0px -2px ${Blue_base}`,
      fontWeight: 600,
      cursor: 'default',
    },
    ' &:hover': {
      boxShadow: ` inset 0px -2px ${Blue_base}`,
      cursor: 'default',
    },
    '&>a, &>span': {
      color: Blue_base,
      paddingLeft: 14,
      paddingBottom: 12,
      paddingRight: 14,
      paddingTop: 12,
      '&:hover, &:visited, &:active': {
        color: Blue_base,
      },
    },
    '& a': { textDecoration: 'none' },
    '&.dark': {
      '&.active': {
        boxShadow: ` inset 0px -2px #fff`,
      },
      '&:hover': {
        boxShadow: ` inset 0px -2px #fff`,
      },
      color: '#fff',
      '&>a, &>span': {
        color: '#fff',
        '&:hover, &:visited, &:active': {
          color: '#fff',
        },
      },
    },
  },
  scrimWrapper: {
    position: 'relative',
    width: '100%',
  },
  secondaryMenuWrapper: {
    position: 'relative',
    width: '100%',
    'overflow-x': 'auto',
    'overflow-y': 'hidden',
    boxShadow: 'inset 0px -1px #00000033',
    '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
    'scrollbar-width': 'none' /* Firefox */,
    '&:-webkit-scrollbar': {
      display: 'none' /* Safari and Chrome */,
    },
    '&.dark': {
      color: '#fff',
      backgroundColor: 'transparent',
      boxShadow: 'inset 0px -1px #FFFFFF33',
    },
    '&.noLine': {
      boxShadow: 'none',
    },
  },
  scrollScrimEnd: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 56,
    height: 45,
    background: ({ scrimGradientBaseColor }: StylesOptions) =>
      scrimGradientBaseColor
        ? `linear-gradient(270deg, rgba(${scrimGradientBaseColor}) 0%, rgba(${scrimGradientBaseColor}, 0.36) 48.5%, rgba(${scrimGradientBaseColor}, 0) 100%)`
        : 'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.36) 48.5%, rgba(255, 255, 255, 0) 100%)',
    '&.dark': {
      background: ({ scrimGradientBaseColor }: StylesOptions) =>
        scrimGradientBaseColor
          ? `linear-gradient(270deg, rgba(${scrimGradientBaseColor}) 0%, ${scrimGradientBaseColor} 48.5%, rgba(0, 24, 48, 0) 100%)`
          : 'linear-gradient(270deg, #001830 0%, rgba(0, 24, 48, 0.36) 48.5%, rgba(0, 24, 48, 0) 100%)',
    },
  },
  scrollScrimStart: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: 56,
    height: 45,
    background: ({ scrimGradientBaseColor }: StylesOptions) =>
      scrimGradientBaseColor
        ? `linear-gradient(90deg, rgba(${scrimGradientBaseColor}) 0%, rgba(${scrimGradientBaseColor}, 0.36) 48.5%, rgba(${scrimGradientBaseColor}, 0) 100%)`
        : 'linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.36) 48.5%, rgba(255, 255, 255, 0) 100%)',
    '&.dark': {
      background: ({ scrimGradientBaseColor }: StylesOptions) =>
        scrimGradientBaseColor
          ? `linear-gradient(90deg, rgba(${scrimGradientBaseColor}) 0%, rgba(${scrimGradientBaseColor}, 0.36) 48.5%, rgba(${scrimGradientBaseColor}, 0) 100%)`
          : 'linear-gradient(90deg, #001830 0%, rgba(0, 24, 48, 0.36) 48.5%, rgba(0, 24, 48, 0) 100%)',
    },
  },
  insetFocusVisible: {},
});

const LocalMenuSecondary = ({ items, isDark }: LocalMenuSecondaryProps) => {
  const [showScrollScrimEnd, setShowScrollScrimEnd] = useState(false);
  const [showScrollScrimStart, setShowScrollScrimStart] = useState(false);
  const { config, states } = useGlobalHeaderContext();
  const stylesOptions: StylesOptions = {
    scrimGradientBaseColor: states.isFixed
      ? config.style?.floating?.scrimGradientColor
      : config.style?.default?.scrimGradientColor,
  };
  const classes = useStyles(stylesOptions);
  const scrollableRef = useRef<HTMLDivElement>(null);

  const focusVisibleClassName = useFocusVisibleClassName();

  const onLinkClick = (e: LocalMenuNavItemLink) => {
    const menuAnalyticsData = {
      menu: {
        type: 'product',
        level1: e.title,
      },
      interaction: true,
      _clear: true,
    };

    analytics.trigger({ type: 'MenuClick', data: menuAnalyticsData });
  };

  const handleWheel = (e: WheelEvent) => {
    e.preventDefault();
    if (!scrollableRef.current) {
      return;
    }
    scrollableRef.current.scrollLeft += e.deltaY;
  };

  const handleScroll = () => {
    if (scrollableRef.current === null) {
      return;
    }

    if (
      scrollableRef.current.scrollLeft + scrollableRef.current.offsetWidth ===
      scrollableRef.current.scrollWidth
    ) {
      setShowScrollScrimEnd(false);
    } else {
      setShowScrollScrimEnd(true);
    }

    if (scrollableRef.current.scrollLeft === 0) {
      setShowScrollScrimStart(false);
    } else {
      setShowScrollScrimStart(true);
    }
  };

  useEffect(() => {
    const node = scrollableRef.current;
    if (!node) {
      return;
    }
    // resime timto zpusobem, protoze v react onWheel prop nelze zavolat e.preventDefault() pro zablokovani defaultniho scrolovani
    node.addEventListener('wheel', handleWheel as EventListener, { passive: false });
    return () => {
      node.removeEventListener('wheel', handleWheel as EventListener);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (scrollableRef.current === null) {
        return;
      }
      const parentWidth = scrollableRef.current.offsetWidth;
      if (parentWidth < scrollableRef.current.scrollWidth) {
        setShowScrollScrimEnd(true);
      } else {
        setShowScrollScrimEnd(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div className={classes.scrimWrapper}>
      {showScrollScrimStart && (
        <div className={classNames(classes.scrollScrimStart, { dark: isDark })}></div>
      )}
      <div
        className={classNames(classes.secondaryMenuWrapper, {
          dark: isDark,
          noLine: states.isFixed && !states.localMobileMenuNavOpened,
        })}
        ref={scrollableRef}
        onScroll={handleScroll}
      >
        <ul className={classes.itemList}>
          {items.map((item) => {
            return (
              <li
                className={classNames(classes.item, { active: item.active, dark: isDark })}
                key={item.title}
              >
                {item.href && (
                  <Link
                    aria-current={item.active && 'page'}
                    className={focusVisibleClassName}
                    disabled={!!item.disabled}
                    target={item.target}
                    onClick={() => {
                      onLinkClick({ href: item.href, title: item.title });
                    }}
                    {...item}
                  >
                    {item.title}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      {showScrollScrimEnd && (
        <div className={classNames(classes.scrollScrimEnd, { dark: isDark })}></div>
      )}
    </div>
  );
};

export default memo(LocalMenuSecondary);
