import React, { memo, useEffect } from 'react';
import { useAuthDataFromCookie } from '../../../common/cookies';
import { WrapperPositions } from '../../components/Wrapper/Wrapper';
import { useGlobalHeaderContext } from '../../providers/GlobalHeaderProvider';
import { useFloating } from '../../uses/useFloating';
import { WrapperFactory } from '../WrapperFactory/WrapperFactory';

export const GlobalHeaderFactory = memo(() => {
  const { states, setStates, config } = useGlobalHeaderContext();

  const isMenuOpen =
    states.localMobileMenuNavOpened || states.globalMenuNavOpened || states.accountMenuNavOpened;

  const { isFixed } = useFloating(states.isFixed, isMenuOpen, states.headerHeight);

  // Zjistime prihlaseneho uzivatele
  useAuthDataFromCookie();

  // Nastavíme pozici wrapperu hlavičky při scollu/default
  useEffect(() => {
    if (states.isFixedLocked) {
      return;
    }
    if (isFixed !== states.isFixed) {
      setStates({
        ...states,
        ...{
          isFixed,
          wrapperPosition: isFixed
            ? WrapperPositions.fixed
            : config.defaultPosition ?? WrapperPositions.relative,
        },
      });
    }

    if (isFixed !== states.isFixed) {
      setStates({ ...states, ...{ isFixed } });
    }
  }, [
    config.defaultPosition,
    isFixed,
    setStates,
    states,
    states.globalMenuNavOpened,
    states.accountMenuNavOpened,
    states.isFixed,
    states.isFixedLocked,
  ]);

  return <WrapperFactory />;
});
