import { Blue_dark_background } from '@czechtv/styles';

export const globalMenuNavChannelsStyles = {
  channelsList: {
    listStyle: 'none',
    padding: [19, 29, 19, 29],
    margin: 0,
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    '&.isDark': {
      backgroundColor: Blue_dark_background,
    },
  },
  channelsListItem: {
    flex: '1 1 auto',
    width: '50%',
    padding: [8, 0],
    '& img': {
      height: 21,
      display: 'block',
    },
    '& a': {
      display: `block`,
      padding: 5,
    },
  },
  channelsListItemMainLink: {
    marginBottom: 14,
    width: '100%',
    '& img': {
      height: 24,
    },
  },
};
