import React, { createRef, memo, RefObject, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { tablet768, useMediaQuery } from '@czechtv/styles';
import { NativeButton, useFocusVisibleClassName } from '@czechtv/components';
import { desktop1280 } from '../../../../common/breakpoints';
import {
  GlobalHeaderGlobalMenuSearchConfig,
  useGlobalHeaderContext,
} from '../../../providers/GlobalHeaderProvider';
import { globalMenuSearchStyles } from './GlobalMenuSearch.style';

const useGlobalMenuSearchStyles = createUseStyles(globalMenuSearchStyles);

export const GlobalMenuSearch = memo(
  ({
    queryParameter = 'q',
    url,
    target = '_self',
    placeholder = 'Hledat ...',
    onSubmit,
  }: GlobalHeaderGlobalMenuSearchConfig) => {
    const classes = useGlobalMenuSearchStyles();

    const inputRef: RefObject<HTMLInputElement> | undefined = createRef();

    const { config, states, setStates } = useGlobalHeaderContext();

    const focusVisibleClassName = useFocusVisibleClassName();

    const is768 = useMediaQuery({ minWidth: tablet768 });
    const isMin768Max1280 = useMediaQuery({ minWidth: tablet768, maxWidth: desktop1280 - 1 });

    const isMobileVisible = useMemo(() => {
      return states.searchMobileOpened;
    }, [states.searchMobileOpened]);

    const setMobileOpened = useCallback(
      (value: boolean) => {
        setStates({ ...states, ...{ searchMobileOpened: value, isFixedLocked: value } });
      },
      [setStates, states]
    );

    const setMobileVisible = useCallback(
      (value: boolean) => {
        void Promise.all([setMobileOpened(value)]).then(() => {
          const focusedInput = document.getElementById(
            'ctg-header__searchInput'
          ) as HTMLInputElement;

          if (!focusedInput) {
            return;
          }

          focusedInput.value = '';

          if (value) {
            focusedInput.setAttribute('autofocus', 'autofocus');
            focusedInput.focus();
            /* window.scrollTo({
                      top: window.scrollY,
                    }); */
            setStates({ ...states, ...{ isFixedLocked: false, searchMobileOpened: true } });
          } else {
            focusedInput.removeAttribute('autofocus');
          }
        });
      },
      [setMobileOpened, setStates, states]
    );

    const isWrapperMobile = useMemo(() => {
      if (config.superHomepageNavVisible) {
        if (!is768) {
          return true;
        }
        if (isMin768Max1280) {
          return true;
        }
      } else if (!is768) {
        return true;
      }

      return false;
    }, [config.superHomepageNavVisible, is768, isMin768Max1280]);

    useEffect(() => {
      if (!isWrapperMobile && isMobileVisible) {
        setMobileVisible(false);
      }
    }, [isMobileVisible, isWrapperMobile, setMobileVisible]);

    const handleFormSubmit = useCallback(() => {
      if (!inputRef.current?.value) {
        return;
      }

      const inputValue = onSubmit
        ? inputRef.current.value.trim()
        : encodeURIComponent(inputRef.current.value.trim());

      if (onSubmit) {
        onSubmit(inputValue);
        return;
      }

      let finalURL = url.trim();
      if (finalURL.includes('?')) {
        finalURL = `${url}&${queryParameter}=${inputValue}`;
      } else {
        // stranka vyhledavani ma problem, kdyz nekdo nastavi pouze treba
        // https://www.ceskatelevize.cz/hledani bez '/' na konci - nefunguje pak na webu
        // spravne parsovani viceslovnych query parametru:
        // kontrolujeme a pripadne pridavame vzdy '/' pred query casti
        const delimiter = finalURL[finalURL.length - 1] === '/' ? '?' : '/?';
        finalURL = `${url}${delimiter}${queryParameter}=${inputValue}`;
      }

      window.open(finalURL, target);
    }, [inputRef, onSubmit, url, target, queryParameter]);

    return (
      <div
        className={classNames(
          classes.searchWrapper,
          isWrapperMobile && classes.searchWrapperMobile,
          isMobileVisible && classes.searchWrapperMobileOpened,
          { dark: states.style.headerFirstRow?.darkTheme ?? states.isDark }
        )}
      >
        <form
          className={classNames(classes.searchForm)}
          data-testid="ctg-header-searchForm"
          id="ctg-header__searchForm-desktop"
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            required
            className={classNames(classes.searchInput, classes.insetFocusVisible)}
            data-testid="ctg-header-searchInput"
            id="ctg-header__searchInput"
            maxLength={50}
            minLength={3}
            name={queryParameter}
            placeholder={placeholder}
            ref={inputRef}
            type="search"
            onBlur={(e) => {
              focusVisibleClassName
                .split(' ')
                .map((className: string) => e.currentTarget.classList.remove(className));
            }}
            onKeyDown={(e) => {
              e.currentTarget.classList.remove('focus-visible');
              // FIXME: je mozne, ze s key='Enter' bude problem na Androidu, pripadne pouzit keyCode
              if (e.key === 'Enter') {
                handleFormSubmit();
              }
            }}
            onKeyUp={(e) => {
              focusVisibleClassName
                .split(' ')
                .map((className: string) => e.currentTarget.classList.add(className));
            }}
          />
          <NativeButton
            aria-label="Hledat"
            className={classNames(classes.insetFocusVisible)}
            data-testid="ctg-header-search-submit"
            form="ctg-header__searchForm-desktop"
            type="submit"
            onClick={handleFormSubmit}
          >
            <svg
              aria-hidden
              fill="none"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M9.5 2a7.5 7.5 0 015.83 12.22c.404-.066.83.034 1.17.302l.115.102 4.43 4.3a1.5 1.5 0 01-1.974 2.254l-.116-.102-4.43-4.3a1.498 1.498 0 01-.43-1.348A7.5 7.5 0 119.5 2zm0 1.8a5.7 5.7 0 100 11.4 5.7 5.7 0 000-11.4z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </NativeButton>
        </form>
        <NativeButton
          aria-label="Zobrazit vyhledávání"
          className={classNames(
            classes.searchMobileButton,
            classes.searchMobileShowButton,
            classes.insetFocusVisible
          )}
          onClick={() => {
            setMobileVisible(true);
          }}
        >
          <svg
            aria-hidden
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              d="M9.5 2a7.5 7.5 0 015.83 12.22c.404-.066.83.034 1.17.302l.115.102 4.43 4.3a1.5 1.5 0 01-1.974 2.254l-.116-.102-4.43-4.3a1.498 1.498 0 01-.43-1.348A7.5 7.5 0 119.5 2zm0 1.8a5.7 5.7 0 100 11.4 5.7 5.7 0 000-11.4z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>
        </NativeButton>
        <NativeButton
          aria-label="Skrýt vyhledávání"
          className={classNames(
            classes.searchMobileButton,
            classes.searchMobileHideButton,
            states.isDark && classes.searchMobileHideButtonDark,
            classes.insetFocusVisible
          )}
          onClick={() => setMobileVisible(false)}
        >
          <svg
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              d="M5.613 4.21l.094.083L12 10.585l6.293-6.292a1 1 0 011.497 1.32l-.083.094L13.415 12l6.292 6.293a1 1 0 01-1.32 1.497l-.094-.083L12 13.415l-6.293 6.292a1 1 0 01-1.497-1.32l.083-.094L10.585 12 4.293 5.707a1 1 0 011.32-1.497z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>
        </NativeButton>
      </div>
    );
  }
);
