import { GlobalMenuSuperHomepageNavItemLink } from '../components/GlobalMenu/GlobalMenuSuperHomepageNav/GlobalMenuSuperHomepageNavItem/GlobalMenuSuperHomepageNavItem';

export const globalMenuSuperHomepageNavItems: GlobalMenuSuperHomepageNavItemLink[] = [
  {
    title: 'iVysílání',
    href: 'https://www.ceskatelevize.cz/ivysilani/',
  },
  {
    title: 'ČT24',
    href: 'https://ct24.ceskatelevize.cz/',
  },
  {
    title: 'Sport',
    href: 'https://sport.ceskatelevize.cz/',
  },
  {
    title: 'Déčko',
    href: 'https://decko.ceskatelevize.cz/',
  },
  {
    title: 'TV program',
    href: 'https://www.ceskatelevize.cz/tv-program/',
  },
  {
    title: 'Živě',
    href: 'https://www.ceskatelevize.cz/zive/',
  },
];
