import React from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { Link } from '../../../Link';
import {
  CustomLinkProps,
  useGlobalHeaderContext,
} from '../../../../providers/GlobalHeaderProvider';
import { localMenuNavItemStyles } from './LocalMenuNavItem.style';

export interface LocalMenuNavItemLink {
  active?: boolean;
  children?: React.ReactNode;
  customLinkComponent?: boolean;
  customLinkProps?: CustomLinkProps;
  disabled?: boolean;
  href: string;
  items?: LocalMenuNavItemLink[];
  target?: string;
  title: string;
}

export interface LocalMenuNavItemProps extends LocalMenuNavItemLink {
  onLinkClick: (item: LocalMenuNavItemLink) => void;
}
const useLocalMenuNavItemStyles = createUseStyles(localMenuNavItemStyles);

export const LocalMenuNavItem = ({
  active,
  title,
  href,
  disabled,
  onLinkClick,
  target,
  ...rest
}: LocalMenuNavItemProps) => {
  const classes = useLocalMenuNavItemStyles();

  const { states } = useGlobalHeaderContext();

  return (
    <li
      className={classNames(
        classes.localMenuNavListItem,
        active && classes.localMenuNavListItemActive,
        { dark: states.isDark }
      )}
    >
      {href && (
        <Link
          aria-current={active && 'page'}
          className={classes.insetFocusVisible}
          disabled={!!disabled}
          href={href}
          target={target}
          onClick={() => {
            onLinkClick({ active, title, href, disabled });
          }}
          {...rest}
        >
          {title}
        </Link>
      )}
    </li>
  );
};
