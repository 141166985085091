import { Blue_base, body16, Grey_10, Grey_20, Red_base, header21 } from '@czechtv/styles';

export const globalMenuAccountNavStyles = {
  accountContextMenu: {
    ...body16,
    margin: 0,
    color: Blue_base,
    boxShadow: 'none',
    padding: [0, 0, 12, 0],
    paddingTop: '0 !important',
    '& li:nth-child(2), li:nth-child(4), li:nth-child(5)': {
      borderTop: `1px solid ${Grey_20}`,
      paddingTop: 8,
    },
    '& li:nth-child(1), li:nth-child(3), li:nth-child(4)': {
      paddingBottom: 8,
    },
    '& li:not($contextMenuItemDisabled):not(:first-child)': {
      '& > a:hover': {
        backgroundColor: Grey_10,
      },
    },
    '& li$contextMenuItemActive': {
      '& > a': {
        color: Red_base,
        '& svg': {
          color: Blue_base,
        },
      },
    },
    '& li:not(:first-child)': {
      '& a': {
        padding: [9, 24],
      },
    },
  }, // Class FK
  profileName: {
    cursor: 'initial',
    paddingTop: 12,
    '& p': {
      ...header21,
      fontWeight: 500,
      color: 'black',
      padding: [9, 24, 0, 24],
      margin: 0,
    },
  },
  contextMenuItemActive: {}, // Class FK
  contextMenuItemDisabled: {}, // Class FK
};
