import {
  mediaMaxMobile359,
  mediaMinMobile360,
  mediaMinTablet768,
} from '../../../../common/breakpoints';

export const globalMenuLogoStyles = {
  globalMenuLogoWrapper: {
    display: 'flex',
    alignItems: 'center',
    [mediaMaxMobile359]: {
      borderLeft: 0,
    },
  },
  globalMenuLogoLink: {
    height: 20,
    margin: [0, 16],
    [mediaMinMobile360]: {
      height: 24,
    },
    [mediaMinTablet768]: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 24,
      marginRight: 24,
    },
  },
  globalMenuLogo: {
    maxHeight: '100%',
    display: 'block',
    height: '100%',
  },
};
