import React, { memo } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { globalMenuSuperHomepageStyles } from './GlobalMenuSuperHomepageNav.style';
import {
  GlobalMenuSuperHomepageNavItem,
  GlobalMenuSuperHomepageNavItemLink,
  GlobalMenuSuperHomepageNavItemProps,
} from './GlobalMenuSuperHomepageNavItem/GlobalMenuSuperHomepageNavItem';

export interface GlobalMenuSuperHomepageNavProps {
  items: GlobalMenuSuperHomepageNavItemLink[];
  onLinkClick?: GlobalMenuSuperHomepageNavItemProps['onLinkClick'];
}
const useGlobalMenuSuperHomepageStyles = createUseStyles(globalMenuSuperHomepageStyles);

export const GlobalMenuSuperHomepageNav = memo(
  ({ items, onLinkClick }: GlobalMenuSuperHomepageNavProps) => {
    const classes = useGlobalMenuSuperHomepageStyles();

    return (
      <nav aria-label="Globální" className={classNames(classes.globalMenuSuperHomepageNav)}>
        <ul className={classNames(classes.globalMenuSuperHomepageNavItems)}>
          {items.map((item, index) => (
            <GlobalMenuSuperHomepageNavItem
              item={item}
              key={`${index.toString()}`}
              onLinkClick={onLinkClick}
            />
          ))}
        </ul>
      </nav>
    );
  }
);
