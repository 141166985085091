export const wrapperStyles = {
  wrapper: {
    width: '100%',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    content: 'a',
  },
  wrapperAbsolute: {},
  wrapperFixed: {},
  wrapperFixedThirdRow: {},
};
