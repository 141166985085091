export const PRODUCT_MENU_DESKTOP_HEIGHT = 57;
export const PRODUCT_MENU_MOBILE_HEIGHT = 49;

export const PRODUCT_MENU_SECONDARY_HEIGHT = 48;

export const GLOBAL_MENU_DESKTOP_HEIGHT = 65;
export const GLOBAL_MENU_MOBILE_HEIGHT = 56;

export const THREE_ROWS_HEIGHT_DESKTOP =
  PRODUCT_MENU_DESKTOP_HEIGHT + GLOBAL_MENU_DESKTOP_HEIGHT + PRODUCT_MENU_SECONDARY_HEIGHT;

export const TWO_ROWS_HEIGHT_DESKTOP = PRODUCT_MENU_DESKTOP_HEIGHT + GLOBAL_MENU_DESKTOP_HEIGHT;

export const THREE_ROWS_HEIGHT_MOBILE =
  PRODUCT_MENU_MOBILE_HEIGHT + GLOBAL_MENU_MOBILE_HEIGHT + PRODUCT_MENU_SECONDARY_HEIGHT;

export const TWO_ROWS_HEIGHT_MOBILE = PRODUCT_MENU_MOBILE_HEIGHT + GLOBAL_MENU_MOBILE_HEIGHT;

export const JSS_PREFIX = 'ctg-header__';
