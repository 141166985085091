import React, { Ref, createContext, useEffect, useMemo, useState } from 'react';
import { useContextWithProvider } from '@czechtv/components';
import { WrapperPositions } from '../components/Wrapper/Wrapper';
import { LocalMenuHotlinkNavItemLink } from '../components/LocalMenu/LocalMenuHotlinkNav/LocalMenuHotlinkNavItem/LocalMenuHotlinkNavItem';
import { GlobalMenuLogoLink } from '../components/GlobalMenu/GlobalMenuLogo/GlobalMenuLogo';
import { defaultConfig, defaultStates } from '../common/defaultConfig';
import { LocalMenuNavItemLink } from '../components/LocalMenu/LocalMenuNav/LocalMenuNavItem/LocalMenuNavItem';
import { AccountMenuLinkId } from '../constants/accountMenuNavItems';
import { GlobalMenuLinkId } from '../constants/globalMenuNavItems';

export interface GlobalHeaderConfig {
  // Konfigurace vlastní komponenty linku
  CustomLinkComponent?: CustomLinkComponentType;
  // Konfigurace přihlašování/uživatelského účtu
  account?: GlobalHeaderGlobalMenuAccountConfig;
  // Rozšiřující třída pro wrapper hlavičky
  className?: string;
  // Výchozí pozice hlavičky
  defaultPosition?: WrapperPositions;
  // Konfigurace linků globálního menu (pokud chci definovat vlastní linky na produkty)
  globalMenu?: {
    [key in GlobalMenuLinkId]?: LinkProps;
  };
  // Konfigurace loga
  // Ignoruje se při `superHomepageNavVisible: true`
  logo?: GlobalMenuLogoLink;
  // Konfigurace produktového menu (položky a hotlinks)
  productMenu?: GlobalHeaderProductMenuConfig;
  // Konfigurace vyhledávání
  search?: GlobalHeaderGlobalMenuSearchConfig;
  // Konfigurace stylu globální hlavičky (výchozí a floating)
  style?: GlobalHeaderStylesConfig;
  // Konfigurace zda je navigace pro superhomepage viditelná
  // Při `true` se ignoruje konfigurace `logo`
  superHomepageNavVisible?: boolean;
}

export type CustomLinkComponentType = React.ComponentType<React.HTMLProps<HTMLElement>>;

export interface LinkProps extends React.HTMLProps<HTMLAnchorElement | HTMLButtonElement> {
  active?: boolean;
  buttonAriaLabel?: string;
  children?: React.ReactNode;
  customLinkProps?: CustomLinkProps;
  href: string;
  isNotLink?: boolean;
  ref?: Ref<HTMLAnchorElement>;
}

export interface CustomLinkProps {
  [key: string]: unknown;
}

export interface GlobalHeaderGlobalMenuSearchConfig {
  onSubmit?: (keyword: string) => void;
  placeholder?: string;
  // url query parameter pro vyhledávání
  queryParameter: string;
  target?: string;
  url: string;
  // todo: přijde upravit a rozšířit o našeptávač ...
}

export interface GlobalHeaderGlobalMenuAccountConfig {
  accountMenuItems: {
    [key in AccountMenuLinkId]?: LinkProps;
  };
  active?: boolean;
  loggedIn?: boolean;
  // Link pro přihlášení
  loginLink?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export interface GlobalHeaderProductMenuConfig {
  // Hotlinks položky produktového menu
  hotlinks?: LocalMenuHotlinkNavItemLink[];
  // Položky produktového menu
  items?: LocalMenuNavItemLink[];
  // nastaveni maximální šířky produktového menu
  maxWidth?: number;
  // název tlačítka menu na mobilu
  mobileTitle?: string;
}

export interface GlobalHeaderAccountMenuConfig {
  items?: LocalMenuNavItemLink[];
}

export interface GlobalHeaderStatesProps {
  // Je navigace uzivatelskeho menu otevřená?
  accountMenuNavOpened: boolean;
  // Je navigace globálního menu otevřená?
  globalMenuNavOpened: boolean;
  // Výška hlavičky
  headerHeight: number;
  // Je hlavicka v dark modu?
  isDark: boolean;
  // Je hlavička fixní (floating - pokud scrolluji nahoru a hlavicka je videt)
  isFixed: boolean;
  // Blokuje nastavení fixní polohy (floating)
  isFixedLocked: boolean;
  // Je spacer viditelní ?
  isSpacerVisible: boolean;
  // Je lokální mobilní menu otevřené ?
  localMobileMenuNavOpened: boolean;
  // Je mobilní vyhledávání otevřené ?
  searchMobileOpened: boolean;
  // aktuální styl hlavičky
  style: GlobalHeaderStyleConfig;
  // Informace o uživateli
  userData?: UserData;
  // Pozice wrapperu hlavičky
  wrapperPosition: WrapperPositions;
}

export interface GlobalHeaderStylesConfig {
  // Výchozí styl hlavičky
  default?: GlobalHeaderStyleConfig;
  // Floting styl hlavičky
  floating?: GlobalHeaderStyleConfig;
}

export interface FirstRowStyleConfig {
  // Pozadí prvního řádku hlavičky
  background?: string;
  // Tmavý mód prvního řádku hlavičky
  darkTheme?: boolean;
}

export interface UserData {
  fullName: string | null;
  isLogged: boolean;
  userImage: string | null;
}

export interface GlobalHeaderStyleConfig {
  backgroundColor?: string;
  dark?: boolean;
  fixedBackgroundColor?: string;
  headerFirstRow?: FirstRowStyleConfig;
  hoverBackgroundColor?: string;
  hoverColor?: string;
  // zadává se jako string trojčíslí rgb (např. '255, 255, 255') aby se udržela informace o průhlednosti
  scrimGradientColor?: string;
}

export interface GlobalHeaderContextProps {
  // Konfigurace hlavičky
  config: GlobalHeaderConfig;
  // Nastavení stavů hlavičky
  setStates: (states: GlobalHeaderStatesProps) => void;
  // Stavy hlavičky
  states: GlobalHeaderStatesProps;
}

export const GlobalHeaderContext = createContext<GlobalHeaderContextProps | undefined>(undefined);

export const useGlobalHeaderContext = () => useContextWithProvider(GlobalHeaderContext);

export const GlobalHeaderProvider = ({
  children,
  config,
}: React.PropsWithChildren<{ config: GlobalHeaderConfig }>) => {
  const [mergedConfig, setMergedConfig] = useState<GlobalHeaderConfig>({
    ...defaultConfig,
    ...config,
  });

  const defaultSetup = {
    ...defaultStates,
    ...{ wrapperPosition: config.defaultPosition ?? WrapperPositions.relative },
    ...{
      isDark: config.style?.default?.dark ?? defaultStates.isDark,
    },
    ...{
      style: config.style?.default ?? defaultStates.style,
    },
  };
  const [states, setStates] = useState<GlobalHeaderStatesProps>({ ...defaultSetup });

  useEffect(() => {
    const newMergedConfig = { ...defaultConfig, ...config };
    setStates({
      ...states,
      ...{ wrapperPosition: config.defaultPosition ?? WrapperPositions.relative },
    });
    setMergedConfig(newMergedConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  const contextValue = useMemo<GlobalHeaderContextProps>(
    () => ({
      config: mergedConfig,
      states,
      setStates,
    }),
    [mergedConfig, states]
  );

  return (
    <GlobalHeaderContext.Provider value={contextValue}>{children}</GlobalHeaderContext.Provider>
  );
};
