import React, { memo } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { Link } from '../../../Link';
import { CustomLinkProps } from '../../../../providers/GlobalHeaderProvider';
import { globalMenuSuperHomepageNavItemStyles } from './GlobalMenuSuperHomepageNavItem.style';

export interface GlobalMenuSuperHomepageNavItemLink {
  customLinkProps?: CustomLinkProps;
  href: string;
  title: string;
}

export interface GlobalMenuSuperHomepageNavItemProps {
  item: GlobalMenuSuperHomepageNavItemLink;
  onLinkClick?: (item: GlobalMenuSuperHomepageNavItemLink) => void;
}
const useGlobalMenuSuperHomepageNavItemStyles = createUseStyles(
  globalMenuSuperHomepageNavItemStyles
);

export const GlobalMenuSuperHomepageNavItem = memo(
  ({ item, onLinkClick }: GlobalMenuSuperHomepageNavItemProps) => {
    const classes = useGlobalMenuSuperHomepageNavItemStyles();

    const handleOnLinkClick = () => {
      if (onLinkClick) {
        onLinkClick(item);
      }
    };

    return (
      <li className={classNames(classes.globalMenuSuperHomepageNavItem)}>
        <Link
          className={classNames(classes.globalMenuSuperHomepageNavItemLink)}
          href={item.href}
          onClick={handleOnLinkClick}
        >
          {item.title}
        </Link>
      </li>
    );
  }
);
