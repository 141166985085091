import { WrapperPositions } from '../components/Wrapper/Wrapper';
import { GlobalMenuLogoLink } from '../components/GlobalMenu/GlobalMenuLogo/GlobalMenuLogo';
import {
  GlobalHeaderConfig,
  GlobalHeaderStatesProps,
  GlobalHeaderStyleConfig,
} from '../providers/GlobalHeaderProvider';
import { ChannelLogos } from '../constants/channelLogos';

export const defaultStyle: GlobalHeaderStyleConfig = {
  backgroundColor: '#fff',
  dark: false,
};

export const defaultFloatingStyle: GlobalHeaderStyleConfig = {
  backgroundColor: '#fff',
  dark: false,
};

export const defaultLogo: GlobalMenuLogoLink = {
  title: 'Česká televize',
  src: ChannelLogos.SRC_CT,
  srcDark: ChannelLogos.SRC_CT_DARK,
  href: 'https://www.ceskatelevize.cz/',
};

export const desktopSHPCTLogo: GlobalMenuLogoLink = {
  title: 'Česká televize',
  src: ChannelLogos.SRC_CT,
  srcDark: ChannelLogos.SRC_CT_DARK,
  href: 'https://www.ceskatelevize.cz/',
};

export const mobileSHPCTLogo: GlobalMenuLogoLink = {
  title: 'Česká televize',
  src: ChannelLogos.SRC_CT_MOBILE,
  href: 'https://www.ceskatelevize.cz/',
};

export const defaultConfig: GlobalHeaderConfig = {
  defaultPosition: WrapperPositions.relative,
  className: undefined,
  superHomepageNavVisible: true,
  style: {
    default: defaultStyle,
    floating: defaultFloatingStyle,
  },
  logo: defaultLogo,
};

export const defaultStates: GlobalHeaderStatesProps = {
  isFixed: false,
  isDark: false,
  searchMobileOpened: false,
  isFixedLocked: false,
  globalMenuNavOpened: false,
  accountMenuNavOpened: false,
  isSpacerVisible: false,
  localMobileMenuNavOpened: false,
  wrapperPosition: WrapperPositions.relative,
  userData: undefined,
  style: {},
  headerHeight: 0,
};
