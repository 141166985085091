import { GlobalMenuNavChannel } from '../components/GlobalMenu/GlobalMenuNav/GlobalMenuNavChannels/GlobalMenuNavChannels';
import { ContextMenuLink } from '../components/ContextMenu/ContextMenuItem/ContextMenuItem';
import { ChannelLogos } from './channelLogos';

export enum GlobalMenuLinkId {
  CT = 'ct',
  CT_24 = 'ct_24',
  CT_ART = 'ct_art',
  CT_D = 'ct_d',
  CT_EDU = 'ct_edu',
  CT_IVYSILANI = 'ct_ivysilani',
  CT_SPORT = 'ct_sport',
}

export const channels: GlobalMenuNavChannel[] = [
  {
    id: GlobalMenuLinkId.CT,
    title: 'Česká televize',
    href: 'https://www.ceskatelevize.cz/',
    src: ChannelLogos.SRC_CT,
    srcDark: ChannelLogos.SRC_CT_DARK,
    isMainLink: true,
  },
  {
    id: GlobalMenuLinkId.CT_IVYSILANI,
    title: 'iVysílání',
    href: 'https://www.ceskatelevize.cz/ivysilani/',
    src: ChannelLogos.SRC_CT_IVYSILANI,
    srcDark: ChannelLogos.SRC_CT_IVYSILANI_DARK,
  },
  {
    id: GlobalMenuLinkId.CT_24,
    title: 'ČT24',
    href: 'https://ct24.ceskatelevize.cz',
    src: ChannelLogos.SRC_CT_24,
    srcDark: '',
  },
  {
    id: GlobalMenuLinkId.CT_D,
    title: 'ČT Déčko',
    href: 'https://decko.ceskatelevize.cz',
    src: ChannelLogos.SRC_CT_D,
    srcDark: '',
  },
  {
    id: GlobalMenuLinkId.CT_SPORT,
    title: 'ČT Sport',
    href: 'https://sport.ceskatelevize.cz',
    src: ChannelLogos.SRC_CT_SPORT,
    srcDark: '',
  },
  {
    id: GlobalMenuLinkId.CT_ART,
    title: 'ČT art',
    href: 'https://art.ceskatelevize.cz/',
    src: ChannelLogos.SRC_CT_ART,
    srcDark: '',
  },
  {
    id: GlobalMenuLinkId.CT_EDU,
    title: 'ČT edu',
    href: 'https://edu.ceskatelevize.cz/',
    src: ChannelLogos.SRC_CT_EDU,
    srcDark: ChannelLogos.SRC_CT_EDU_DARK,
  },
];

export const primaryItems: ContextMenuLink[] = [
  {
    title: 'Živé vysílání',
    href: 'https://www.ceskatelevize.cz/zive/',
  },
  {
    title: 'TV program',
    href: 'https://www.ceskatelevize.cz/tv-program/',
  },
];

export const secondaryItems: ContextMenuLink[] = [
  {
    title: 'Počasí',
    href: 'https://ct24.ceskatelevize.cz/pocasi',
  },
  {
    title: 'Teletext',
    href: 'https://teletext.ceskatelevize.cz/',
  },
  {
    title: 'E-shop',
    href: 'https://eshop.ceskatelevize.cz/',
  },
  {
    title: 'Vše o ČT',
    href: 'https://www.ceskatelevize.cz/vse-o-ct/',
  },
  {
    title: 'TV poplatky',
    href: 'https://poplatky.ceskatelevize.cz/domacnost',
  },
  {
    title: 'Kontakty',
    href: 'https://www.ceskatelevize.cz/vse-o-ct/kontakty/',
  },
];
