import { Blue_base, buildMediaQuery, tablet768 } from '@czechtv/styles';
import { PRODUCT_MENU_DESKTOP_HEIGHT, PRODUCT_MENU_MOBILE_HEIGHT } from '../../constants/main';

export const localMenuStyles = {
  headerDark: {}, // Class FK
  localMenuMobileButton: {}, // Class FK
  localMenuMobileButtonActive: {}, // Class FK
  localMenuWrapper: {
    position: 'relative',
    [buildMediaQuery({ minWidth: tablet768 })]: {
      maxWidth: 1168,
      margin: [0, 'auto'],
      padding: [0, 24],
    },
  },

  localMenu: {
    display: 'grid',
    gridTemplateColumns: '1fr min-content min-content',
    height: PRODUCT_MENU_MOBILE_HEIGHT,
    alignItems: 'center',
    justifyContent: 'stretch',
    position: 'relative',
    zIndex: 1,
    boxSizing: 'border-box',
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    [buildMediaQuery({ minWidth: tablet768 })]: {
      height: PRODUCT_MENU_DESKTOP_HEIGHT,
      gridTemplateColumns: 'minmax(0, 100%) min-content min-content',
      gridTemplateRows: PRODUCT_MENU_DESKTOP_HEIGHT,
      maxWidth: '100%',
      '&.secondaryMenu': {
        height: 'auto',
      },
    },
    '&.hideBottomLine': {
      borderBottom: 0,
      height: PRODUCT_MENU_MOBILE_HEIGHT - 1,
      [buildMediaQuery({ minWidth: tablet768 })]: {
        height: PRODUCT_MENU_DESKTOP_HEIGHT - 1,
        gridTemplateRows: PRODUCT_MENU_DESKTOP_HEIGHT - 1,
      },
    },
  },
  LocalMenuPrimaryWrapper: {},
  localMenuSecondaryWrapper: {
    width: '100%',
  },
  localNavWrapper: {
    display: 'none',
    [buildMediaQuery({ minWidth: tablet768 })]: {
      position: 'relative',
      display: 'block',
    },
  },
  hotlinkNavWrapper: {
    height: '100%',
    position: 'relative',
    [buildMediaQuery({ minWidth: tablet768 })]: {
      position: 'relative',
      display: 'block',
    },
  },
  localMenuMobileButtonWrapper: {
    height: '100%',
    [buildMediaQuery({ minWidth: tablet768 })]: {
      display: 'none',
    },
    '& $localMenuMobileButton': {
      width: '100%',
      height: '100%',
    },
    '& $localMenuMobileButton$localMenuMobileButtonActive': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '@media (hover: hover)': {
      '& $localMenuMobileButton:hover': {
        backgroundColor: Blue_base,
        color: '#fff',
        '.inverted &': {
          backgroundColor: 'transparent',
          color: '#fff',
        },
      },
    },
  },
};
