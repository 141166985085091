import analytics from '../../../common/analytics';
import { GlobalMenuLogoLink } from '../../components/GlobalMenu/GlobalMenuLogo/GlobalMenuLogo';
import { GlobalMenuSuperHomepageNavItemLink } from '../../components/GlobalMenu/GlobalMenuSuperHomepageNav/GlobalMenuSuperHomepageNavItem/GlobalMenuSuperHomepageNavItem';

export const triggerGlobalMenuNavOpened = () => {
  const menuAnalyticsData = {
    menu: {
      type: 'global',
    },
    interaction: true,
    _clear: true,
  };

  analytics.trigger({
    type: 'MenuOpen',
    data: menuAnalyticsData,
  });
};

export const triggerGlobalMenuNavClosed = () => {
  const menuAnalyticsData = {
    menu: {
      type: 'global',
    },
    interaction: true,
    _clear: true,
  };

  analytics.trigger({
    type: 'MenuClose',
    data: menuAnalyticsData,
  });
};

export const triggerGlobalMenuLogoLinkClick = (link: GlobalMenuLogoLink) => {
  const menuAnalyticsData = {
    menu: {
      type: 'global',
      level1: link.title,
    },
    interaction: true,
    _clear: true,
  };

  analytics.trigger({ type: 'MenuClick', data: menuAnalyticsData });
};

export const triggerSuperHomepageNavItemLinkClick = (item: GlobalMenuSuperHomepageNavItemLink) => {
  const menuAnalyticsData = {
    menu: {
      type: 'global',
      level1: item.title,
    },
    interaction: true,
    _clear: true,
  };

  analytics.trigger({ type: 'MenuClick', data: menuAnalyticsData });
};
