import { useCallback, useEffect, useState } from 'react';
import type { IIdTokenPayload } from '@identity/authorization';
import { jwtDecode } from 'jwt-decode';
import type { GtmUserContext } from '@czechtv/analytics';
import { UserData, useGlobalHeaderContext } from '../Header/providers/GlobalHeaderProvider';
import analytics from './analytics';

function parseJwt<Data extends object>(token: string): object {
  const payload: Data = jwtDecode(token);
  return payload;
}

const parseIdToken: typeof parseJwt<IIdTokenPayload> = parseJwt;

const getTokenByEnvironment = () => {
  if (window.location.hostname === 'localhost') {
    return 'iVysilaniIdToken.localhost';
  }
  if (window.location.hostname.includes('development')) {
    return 'iVysilaniIdToken.development';
  }
  if (window.location.hostname.includes('testing')) {
    return 'iVysilaniIdToken.testing';
  }
  if (window.location.hostname.includes('staging')) {
    return 'iVysilaniIdToken.staging';
  }
  return 'iVysilaniIdToken';
};

export interface UseDataFromCookieProps {
  refreshCookie: () => void;
  userCookie: UserData;
}
export const useAuthDataFromCookie = (): UseDataFromCookieProps => {
  const [userCookie, setUserCookie] = useState<UserData>({
    fullName: null,
    userImage: null,
    isLogged: false,
  });

  const { states, setStates, config } = useGlobalHeaderContext();

  const refreshCookie = useCallback(() => {
    let userContext: GtmUserContext = {};
    // pokud neni account v configu, necteme cookie
    if (!config.account) {
      analytics.setContext(userContext);
      return;
    }

    const cookie = document.cookie
      .match(`(^|;)\\s*${getTokenByEnvironment()}\\s*=\\s*([^;]+)`)
      ?.pop();

    let fullName = null;
    let userImage = null;
    let isLogged = false;

    let userData: IIdTokenPayload | null = null;

    if (cookie) {
      userData = parseIdToken(cookie) as IIdTokenPayload;

      if (!userData.name && !userData.picture) {
        console.warn('unable to parse token');
        isLogged = false;
      } else {
        fullName = userData.name;
        userImage = userData.picture;
        isLogged = true;
        userContext = { user: { id: userData.user_id } };
      }
    }
    analytics.setContext(userContext);

    setUserCookie({ fullName, userImage, isLogged });

    const statesNew = {
      ...states,
      userData: { fullName, userImage, isLogged },
    };
    setStates(statesNew);
  }, []);

  // na nacteni configu zjistime, jestli je uzivatel prihlaseny
  useEffect(() => {
    refreshCookie();
  }, [refreshCookie, config]);

  return { userCookie, refreshCookie };
};
