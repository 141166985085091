import React, { memo, RefObject } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { menuNavWrapperStyles } from './MenuNavWrapper.style';

export interface GlobalMenuAccountNavProps {
  ariaLabel?: string;
  children?: React.ReactNode;
  datatestid?: string;
  id: string;
  ref: RefObject<HTMLDivElement>;
  visible?: boolean;
}
const useMenuNavWrapperStyles = createUseStyles(menuNavWrapperStyles);

export const MenuNavWrapper = memo(
  React.forwardRef<HTMLDivElement, GlobalMenuAccountNavProps>(
    ({ ariaLabel, children, visible, id, datatestid }, ref) => {
      const classes = useMenuNavWrapperStyles();

      return (
        <div
          aria-hidden={!visible}
          aria-label={ariaLabel}
          className={classNames(classes.menuNavWrapper, visible && classes.menuNavVisible)}
          data-testid={datatestid}
          id={id}
          ref={ref}
          role="region"
        >
          {children}
        </div>
      );
    }
  )
);
