import React, { memo, MouseEvent } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { Blue_base, body16, DEFAULT_FONT, Grey_60, WhiteOpacity80 } from '@czechtv/styles';
import { link } from '../../../common/styles';
import { mediaMinDesktop1024, mediaMinTablet768, min768max1023 } from '../../../common/breakpoints';
import { staticUrlIcons } from '../../../common/variables';

interface ContactsProps {
  className?: string;
  darkMode?: boolean;
  onLinkClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

const PHONE_SRC = `${staticUrlIcons}phone.svg`;
const MAIL_SRC = `${staticUrlIcons}mail.svg`;
const PHONE_SRC_DARK = `${staticUrlIcons}phone_onDark.svg`;
const MAIL_SRC_DARK = `${staticUrlIcons}mail_onDark.svg`;

const useStyles = createUseStyles({
  contacts: {
    ...body16,
    color: Grey_60,
    lineHeight: '20px',
    textAlign: 'left',
    '& a': {
      ...link,
    },
    [min768max1023]: {
      textAlign: 'center',
    },
  },
  contactsList: {
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    flexDirection: 'column',
    [mediaMinTablet768]: {
      flexDirection: 'row',
    },
    [mediaMinDesktop1024]: {
      justifyContent: 'start',
    },
    '&.darkMode span': {
      color: '#FFFFFF',
    },
  },
  contactsLink: {
    display: 'flex',
    alignContent: 'center',
    alignSelf: 'flex-start',
    lineHeight: '22px',
    margin: [0, 0, 0, 0],
    color: Blue_base,
    '& img': {
      marginRight: 10,
    },
    '&:first-child': {
      margin: [0, 0, 18, 0],
      [mediaMinTablet768]: {
        marginRight: 26,
      },
    },
  },
  strong: {
    // tady si treba nejsem jisty, jestli je potreba davat to fontFamily,
    // mozna by jen stacilo fontWeight, ale zase nikde nevidim, od ceho by to podelilo family,
    // ten odstavec ji take nema nastavenou...?
    fontFamily: DEFAULT_FONT,
    fontWeight: 600,
  },
  openingHours: {
    margin: [0, 0, 16, 0],
    color: '#000',
    '& span:last-child': {
      display: 'inline-block',
      color: Grey_60,
      marginTop: '8px',
    },
    '&.darkMode': {
      '& span:first-child': {
        color: '#FFFFFF',
      },
      '& span:last-child': {
        color: WhiteOpacity80,
      },
    },
  },
});

export const Contacts = memo(({ className, darkMode, onLinkClick }: ContactsProps) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.contacts, className)}>
      <div className={classNames(classes.openingHours, { darkMode })}>
        <span className={classes.strong}>Divácké centrum</span>
        <br />
        <span>každý všední den: 7:30—17:30 hodin</span>
      </div>
      <div className={classNames(classes.contactsList, { darkMode })}>
        <a
          className={classNames(classes.contactsLink)}
          href="tel:+420261136113"
          title="Telefonní číslo na divácké centrum"
          onClick={onLinkClick}
        >
          <img alt="" height="20" src={darkMode ? PHONE_SRC_DARK : PHONE_SRC} width="20" />
          <span>+420 261 136 113</span>
        </a>
        <a
          className={classNames(classes.contactsLink)}
          href="mailto:info@ceskatelevize.cz"
          title="Emailová adresa na zákaznické centrum"
          onClick={onLinkClick}
        >
          <img alt="" height="20" src={darkMode ? MAIL_SRC_DARK : MAIL_SRC} width="20" />
          <span>info@ceskatelevize.cz</span>
        </a>
      </div>
    </div>
  );
});
