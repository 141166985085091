import {
  Blue_base,
  buildMediaQuery,
  DEFAULT_FONT,
  mediaMinTablet768,
  pxToRem,
  tablet768,
} from '@czechtv/styles';
import { universalWrapperStyles } from '../../../factories/GlobalMenuFactory/GlobalMenuFactory.style';
import { GLOBAL_MENU_MOBILE_HEIGHT } from '../../../constants/main';
import { desktop1280, mediaMaxMobile359 } from '../../../../common/breakpoints';

export const globalMenuSearchStyles = {
  headerDark: {}, // Class FK
  globalMenuLogoWrapper: {}, // Class FK
  hasSHPNav: {}, // Class FK
  globalMenuWrapper: {},
  searchWrapper: {
    ...universalWrapperStyles,
    position: 'relative',
    color: Blue_base,

    [mediaMinTablet768]: {
      width: '240px',
    },

    '&.dark': {
      color: '#fff',
      borderLeftColor: 'rgba(255, 255, 255, 0.2)',
      '& $searchInput': {
        color: '#fff',
      },
      '& > a:hover, & > button:hover, & > $menuButtonActive': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    },
  },
  searchWrapperMobile: {
    display: 'flex',
    [mediaMaxMobile359]: {
      borderLeft: 0,
    },
  },
  searchWrapperMobileOpened: {
    width: '100%',
  },
  searchForm: {
    height: '100%',
    color: 'inherit',
    position: 'relative',
    '& button': {
      height: '100%',
      fontSize: 0,
      margin: 0,
      padding: [10, 20],
      position: 'absolute',
      right: 0,
      top: 0,
      background: 'none',
      border: 0,
      color: 'inherit',
      display: 'block',
      fontFamily: DEFAULT_FONT,
      [buildMediaQuery({ minWidth: tablet768, maxWidth: desktop1280 - 1 })]: {
        '$hasSHPNav &': {
          padding: [10, 16],
        },
      },
      [buildMediaQuery({ maxWidth: tablet768 - 1 })]: {
        animation: 'ctg-search-button-hide 200ms forwards',
      },
    },
    '$searchWrapperMobile:not($searchWrapperMobileOpened) &': {
      display: 'none',
    },
    '$searchWrapperMobile &': {
      flex: '1 1 auto',
      display: 'flex',
    },
  },
  searchInput: {
    appearance: 'none',
    color: 'inherit',
    backgroundColor: 'transparent',
    border: 0,
    boxSizing: 'border-box',
    display: 'block',
    flex: '0 1 auto',
    width: '100%',
    fontFamily: DEFAULT_FONT,
    fontSize: pxToRem(16),
    height: '100%',
    outline: 'none',
    padding: [0, 60, 0, 18],
    borderRadius: 0,
    '&::placeholder': {
      color: 'inherit',
      opacity: 0.8,
    },
    '&::-webkit-search-cancel-button': {
      appearance: 'none',
    },
    '&:-webkit-autofill': {
      transitionDelay: '999999s !important',
      transitionProperty: 'background-color, color',
    },
    '$searchWrapperMobile &': {
      flex: '1 1 100%',
      height: '100%',
      padding: [0, 64, 0, 14],
    },
  },
  searchMobileButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: [10, 10],
    '$searchWrapper:not($searchWrapperMobile) &': {
      display: 'none',
    },
    [buildMediaQuery({ minWidth: tablet768, maxWidth: desktop1280 - 1 })]: {
      '$hasSHPNav &': {
        width: 64,
      },
    },
  },
  searchMobileShowButton: {
    '$searchWrapperMobile$searchWrapperMobileOpened &': {
      display: 'none',
    },
  },
  searchMobileHideButton: {
    width: GLOBAL_MENU_MOBILE_HEIGHT - 2, // ošetření 1px poskočení lupičky pokud zavřeme search mode
    borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
    '$searchWrapperMobile:not($searchWrapperMobileOpened) &': {
      display: 'none',
    },
  },
  searchMobileHideButtonDark: {
    borderLeftColor: 'rgba(255, 255, 255, 0.2)',
  },
  '@keyframes ctg-search-button-hide': {
    from: {
      transform: 'translate3d(60px, 0, 0)',
    },
    to: {
      transform: 'translate3d(0, 0, 0)',
    },
  },
  insetFocusVisible: {},
};
