import React, { memo, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { useGlobalHeaderContext } from '../../providers/GlobalHeaderProvider';
import { defaultLogo } from '../../common/defaultConfig';
import { globalMenuSuperHomepageNavItems } from '../../constants/globalMenuSuperHomepageNavItems';
import { globalMenuStyles, GlobalMenuStylesOptions } from './GlobalMenu.style';
import { GlobalMenuLogo, GlobalMenuLogoProps } from './GlobalMenuLogo/GlobalMenuLogo';
import { GlobalMenuSuperHomepageNav } from './GlobalMenuSuperHomepageNav/GlobalMenuSuperHomepageNav';
import { GlobalMenuSuperHomepageNavItemProps } from './GlobalMenuSuperHomepageNav/GlobalMenuSuperHomepageNavItem/GlobalMenuSuperHomepageNavItem';

export interface GlobalMenuProps {
  className?: string;
  hasSuperHomepageNav?: boolean;
  isSuperHomepageNavVisible?: boolean;
  logoProps?: GlobalMenuLogoProps;
  onClickSuperHomepageNavItemLink?: GlobalMenuSuperHomepageNavItemProps['onLinkClick'];
}
const useGlobalMenuStyles = createUseStyles(globalMenuStyles);

export const GlobalMenu = memo(
  ({
    className,
    isSuperHomepageNavVisible = false,
    hasSuperHomepageNav = false,
    logoProps = { link: defaultLogo },
    onClickSuperHomepageNavItemLink,
    children,
  }: PropsWithChildren<GlobalMenuProps>) => {
    const { states } = useGlobalHeaderContext();

    const hasDefinedBackground =
      states.style.headerFirstRow?.background || states.style.backgroundColor;

    const stylesOptions: GlobalMenuStylesOptions = {
      background: states.style.headerFirstRow?.background ?? states.style.backgroundColor,
      darkTheme: states.style.headerFirstRow?.darkTheme ?? states.style.dark,
    };
    const classes = useGlobalMenuStyles(stylesOptions);

    return (
      <div
        className={classNames(
          className,
          classes.globalMenuWrapper,
          hasDefinedBackground && classes.backgroundClass,
          hasSuperHomepageNav && classes.hasSHPNav
        )}
      >
        <GlobalMenuLogo {...logoProps} />

        {hasSuperHomepageNav && isSuperHomepageNavVisible && (
          <GlobalMenuSuperHomepageNav
            items={globalMenuSuperHomepageNavItems}
            onLinkClick={onClickSuperHomepageNavItemLink}
          />
        )}

        <div className={classNames(classes.globalMenuRightItems)}>{children}</div>
      </div>
    );
  }
);
