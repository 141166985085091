import React, { memo, RefObject, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { tablet768, useMediaQuery } from '@czechtv/styles';
import analytics from '../../../common/analytics';
import { LocalMenuNavItemLink } from '../../components/LocalMenu/LocalMenuNav/LocalMenuNavItem/LocalMenuNavItem';
import { useGlobalHeaderContext } from '../../providers/GlobalHeaderProvider';
import { LocalMenuMobileNav } from '../../components/LocalMenu/LocalMenuMobileNav/LocalMenuMobileNav';
import { localMenuMobileNavFactoryStyles } from './LocalMenuMobileNavFactory.style';

export interface LocalMenuMobileNavFactoryProps {
  items?: LocalMenuNavItemLink[];
  localMobileMenuButtonRef?: RefObject<HTMLButtonElement>;
}
const useLocalMenuMobileNavFactoryStyles = createUseStyles(localMenuMobileNavFactoryStyles);

export const LocalMenuMobileNavFactory = memo(
  ({ items, localMobileMenuButtonRef }: LocalMenuMobileNavFactoryProps) => {
    const [parsedItems, setParsedItems] = useState<LocalMenuNavItemLink[]>([]);
    const is768 = useMediaQuery({
      minWidth: tablet768,
    });

    const classes = useLocalMenuMobileNavFactoryStyles();

    const {
      config: { productMenu },
      states,
      setStates,
    } = useGlobalHeaderContext();

    useEffect(() => {
      if (!items || items.length < 1) {
        setParsedItems([]);
        return;
      }
      setParsedItems(
        items.flatMap((item) => {
          let isActive = false;
          if (item.href) {
            isActive = item.active || item.href === window.location.pathname;
          }

          return item.href
            ? {
                ...item,
                ...{ active: isActive },
              }
            : [];
        })
      );
    }, [items]);

    // zavření mobilního lokálního menu
    const hideLocalMenuMobileNav = useCallback(() => {
      setStates({ ...states, ...{ localMobileMenuNavOpened: false } });
    }, [setStates, states]);

    useEffect(() => {
      // zavřeme mobilní menu je li otevřené a jsme na desktop verzi
      if (is768 && states.localMobileMenuNavOpened) {
        hideLocalMenuMobileNav();
      }
    }, [hideLocalMenuMobileNav, is768, states.localMobileMenuNavOpened]);

    const onLinkClick = (e: LocalMenuNavItemLink) => {
      const menuAnalyticsData = {
        menu: {
          type: 'product',
          level1: e.title,
        },
        interaction: true,
        _clear: true,
      };

      analytics.trigger({ type: 'MenuClick', data: menuAnalyticsData });
    };

    return (
      <>
        {productMenu?.items && (
          <div
            className={classNames(
              classes.localMenuMobileNavWrapper,
              states.localMobileMenuNavOpened && classes.localMenuMobileNavWrapperVisible
            )}
          >
            <LocalMenuMobileNav
              ariaLabel="Kategorie"
              buttonRef={localMobileMenuButtonRef}
              items={parsedItems}
              visible={states.localMobileMenuNavOpened}
              onClickOutside={hideLocalMenuMobileNav}
              onLinkClick={onLinkClick}
            />
          </div>
        )}
      </>
    );
  }
);
