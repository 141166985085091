import { Blue_base, pxToRem, Red_base } from '@czechtv/styles';

export const globalMenuSuperHomepageNavItemStyles = {
  globalMenuSuperHomepageNavItem: {},
  globalMenuSuperHomepageNavItemLink: {
    padding: [5],
    whiteSpace: 'nowrap',
    color: Blue_base,
    fontSize: pxToRem(16),
    lineHeight: '20px',
    fontWeight: 500,
    textDecoration: 'none',
    '&:hover': {
      color: Red_base,
    },
  },
};
