import { Blue_base } from '@czechtv/styles';

export const localMenuNavStyles = {
  contextMenu: {}, // Class FK
  localMenuNavListItem: {}, // Class FK
  localMenuNav: {
    // zabrání přetékání prvků než spočítáme kolik jich zobrazit
    '&$localMenuNavGradient': {
      '-webkit-mask-image':
        'linear-gradient( to right, black calc(100% - 150px), transparent calc(100% - 100px))',
      '-webkit-mask-size': 'auto',
      '-webkit-mask-position': '0 0, 100% 0',
      '-webkit-mask-repeat': 'no-repeat, no-repeat',
      maskImage:
        'linear-gradient( to right, black calc(100% - 150px), transparent calc(100% - 100px))',
      maskSize: 'auto',
      maskPosition: '0 0, 100% 0',
      maskRepeat: 'no-repeat, no-repeat',
    },
  },
  localMenuNavList: {
    width: 'fit-content',
    position: 'relative',
    display: 'flex',
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  localMenuNavButtonContainer: {
    position: 'relative',
    '& $contextMenu': {
      left: '-30px',
    },
  },
  localMenuNavButton: {
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      zIndex: 2,
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: -6,
      height: 0,
      opacity: 0,
      pointerEvents: 'none',
      transition: 'opacity .150ms ease',
      borderLeft: '6px solid transparent',
      borderTop: `6px solid ${Blue_base}`,
      borderRight: '6px solid transparent',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  localMenuNavButtonActive: {
    backgroundColor: Blue_base,

    color: '#fff !important',
    '&:before': {
      opacity: 1,
    },
    '&.dark': {
      backgroundColor: '#fff !important',
      color: `${Blue_base} !important`,
    },
    '&.dark:before': {
      borderTop: `6px solid #fff`,
    },
  },
  localMenuNavGradient: {},
  insetFocusVisible: {},
};
