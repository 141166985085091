import React, { memo } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { Blue_base } from '@czechtv/styles';
import { mediaMaxTablet767 } from '../../../../../common/breakpoints';
import { universalWrapperStyles } from '../../../../factories/GlobalMenuFactory/GlobalMenuFactory.style';
import { useGlobalHeaderContext } from '../../../../providers/GlobalHeaderProvider';

const useStyles = createUseStyles({
  accountWrapper: {
    ...universalWrapperStyles,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    color: Blue_base,
    boxSizing: 'border-box',
    '& > a:hover': {
      transition: 'backgroundColor 200ms,color 200ms',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    [mediaMaxTablet767]: {
      borderLeft: 0,
      '$mobileSearchOpened &': {
        display: 'none',
      },
    },
    '&.dark': {
      color: '#fff',
      borderLeftColor: 'rgba(255, 255, 255, 0.2)',
      '& $searchInput': {
        color: '#fff',
      },
      '& > a:hover, & > button:hover, & > $menuButtonActive': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
    },
  },
  accountLoginLink: {
    boxSizing: 'border-box',
    height: '100%',
  },
});

export interface GlobalMenuAccountWrapperProps {
  children: React.ReactNode;
}

export const GlobalMenuAccountWrapper = memo(({ children }: GlobalMenuAccountWrapperProps) => {
  const classes = useStyles();
  const { states } = useGlobalHeaderContext();

  return (
    <div
      className={classNames(classes.accountWrapper, {
        dark: states.style.headerFirstRow?.darkTheme ?? states.isDark,
      })}
    >
      {children}
    </div>
  );
});
