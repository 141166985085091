// eslint-disable-next-line canonical/filename-no-index
import { useContextWithProvider } from '@czechtv/components';
import { AnalyticsContext, AnalyticsContextInterface } from './AnalyticsContext';

// hook pro pristup k analytickemu contextu, ktery obsahuje instanci analytiky
export const useAnalytics = <AnalyticsInternalContext extends Record<string, any> = any>() =>
  useContextWithProvider(
    AnalyticsContext as React.Context<AnalyticsContextInterface<AnalyticsInternalContext>>
  );

export * from './AnalyticsContext';
