import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import React, { memo } from 'react';
import { GlobalMenuLinkId } from '../../../../constants/globalMenuNavItems';
import { Link } from '../../../Link';
import {
  CustomLinkProps,
  useGlobalHeaderContext,
} from '../../../../providers/GlobalHeaderProvider';
import { globalMenuNavChannelsStyles } from './GlobalMenuNavChannels.style';

export interface GlobalMenuNavChannelsProps {
  className?: string;
  hasMainChannelLink?: boolean;
  isDark?: boolean;
  items: GlobalMenuNavChannel[];

  onLinkClick?: (item: GlobalMenuNavChannel) => void;
}

export interface GlobalMenuNavChannel {
  customLinkProps?: CustomLinkProps;
  href: string;
  id: GlobalMenuLinkId;
  isMainLink?: boolean;
  src: string;
  srcDark: string;
  title: string;
}
const useGlobalMenuNavChannelsStyles = createUseStyles(globalMenuNavChannelsStyles);

export const GlobalMenuNavChannels = memo(
  ({
    className,
    onLinkClick,
    items,
    hasMainChannelLink = false,
    isDark = false,
  }: GlobalMenuNavChannelsProps) => {
    const classes = useGlobalMenuNavChannelsStyles();

    const {
      config: { globalMenu },
    } = useGlobalHeaderContext();

    const updatedItems = items.map((item) => {
      return {
        ...item,
        href: globalMenu?.[item.id]?.href || item.href,
        customLinkProps: globalMenu?.[item.id]?.customLinkProps || item.customLinkProps,
      };
    });

    return (
      <>
        <ul className={classNames(classes.channelsList, className, { isDark })}>
          {updatedItems.map((item) => {
            if (!hasMainChannelLink && item.isMainLink) {
              return '';
            }

            const imageSrc = isDark && item.srcDark ? item.srcDark : item.src;

            return (
              <li
                className={classNames(
                  classes.channelsListItem,
                  item.isMainLink && classes.channelsListItemMainLink
                )}
                key={item.title + item.href}
              >
                <Link
                  customLinkProps={item.customLinkProps}
                  href={item.href}
                  title={item.title}
                  onClick={() => (onLinkClick ? onLinkClick(item) : undefined)}
                >
                  <img aria-hidden alt={item.title} src={imageSrc} />
                </Link>
              </li>
            );
          })}
        </ul>
      </>
    );
  }
);
