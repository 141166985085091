import { DEFAULT_FONT, Blue_base, ButtonTransitionDuration } from '@czechtv/styles';
import { LocalMenuHotlinkNavItemStyles } from './LocalMenuHotlinkNavItem';

export const localMenuHotlinkNavItemStyles = {
  insetFocusVisible: {}, // Class FK
  hotlinkNavListItem: {
    position: 'relative',
    '@media (max-width: 767px)': {
      borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
    },
    '& > a, & > button, & > span': {
      textDecoration: 'none',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
      margin: 0,
      fontFamily: DEFAULT_FONT,
      fontSize: 16,
      fontWeight: 600,

      transition: `backgroundColor ${ButtonTransitionDuration}, borderColor ${ButtonTransitionDuration},color ${ButtonTransitionDuration}`,
      letterSpacing: 'normal',
      height: '100%',
      paddingTop: 5,
      paddingBottom: 5,
      paddingRight: 14,
      paddingLeft: 14,
      lineHeight: '1.314rem',
      position: 'relative',

      backgroundColor: (styles?: LocalMenuHotlinkNavItemStyles) =>
        styles?.default?.backgroundColor
          ? `${styles.default.backgroundColor} !important`
          : 'transparent',
      color: (styles?: LocalMenuHotlinkNavItemStyles) =>
        styles?.default?.color ? `${styles.default.color} !important` : Blue_base,
      '& svg': {
        marginRight: '4px',

        color: (styles?: LocalMenuHotlinkNavItemStyles) =>
          styles?.icon?.color ? `${styles.icon.color} !important` : 'inherit',
      },
      '$hotlinkNavListItemActive& svg': {
        color: (styles?: LocalMenuHotlinkNavItemStyles) =>
          styles?.activeOrHover?.color ? `${styles.activeOrHover.color} !important` : 'inherit',
      },
      '$hotlinkNavListItemActive&': {
        backgroundColor: (styles?: LocalMenuHotlinkNavItemStyles) =>
          styles?.activeOrHover?.backgroundColor
            ? `${styles.activeOrHover.backgroundColor} !important`
            : Blue_base,
        color: (styles?: LocalMenuHotlinkNavItemStyles) =>
          styles?.activeOrHover?.color ? `${styles.activeOrHover.color} !important` : '#fff',
        borderColor: (styles?: LocalMenuHotlinkNavItemStyles) =>
          styles?.activeOrHover?.backgroundColor
            ? `${styles.activeOrHover.backgroundColor} !important`
            : Blue_base,
      },

      '@media (hover: hover)': {
        '&:hover svg': {
          color: (styles?: LocalMenuHotlinkNavItemStyles) =>
            styles?.activeOrHover?.color ? `${styles.activeOrHover.color} !important` : 'inherit',
        },
        '&:hover': {
          backgroundColor: (styles?: LocalMenuHotlinkNavItemStyles) =>
            styles?.activeOrHover?.backgroundColor
              ? `${styles.activeOrHover.backgroundColor} !important`
              : Blue_base,
          color: (styles?: LocalMenuHotlinkNavItemStyles) =>
            styles?.activeOrHover?.color ? `${styles.activeOrHover.color} !important` : '#fff',
          borderColor: (styles?: LocalMenuHotlinkNavItemStyles) =>
            styles?.activeOrHover?.backgroundColor
              ? `${styles.activeOrHover.backgroundColor} !important`
              : Blue_base,
          textDecoration: 'none',
        },
      },
    },
    '& > span': {
      cursor: 'default',
    },
  },
  hotlinkNavListItemActive: {
    '&:before': {
      content: '""',
      width: 0,
      height: 0,
      position: 'absolute',
      bottom: -6,
      marginLeft: -6,
      left: '50%',
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent',

      borderTop: (styles?: LocalMenuHotlinkNavItemStyles) =>
        styles?.activeOrHover?.backgroundColor
          ? `6px solid ${styles.activeOrHover.backgroundColor} !important`
          : `6px solid ${Blue_base}`,
    },
  },
};
