import React, { memo } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { CustomLinkProps, useGlobalHeaderContext } from '../../../providers/GlobalHeaderProvider';
import { Link } from '../../Link';
import { ChannelLogos } from '../../../constants/channelLogos';
import { globalMenuLogoStyles } from './GlobalMenuLogo.style';

export interface GlobalMenuLogoLink {
  customLinkProps?: CustomLinkProps;
  href: string;
  src: ChannelLogos;
  srcDark?: ChannelLogos;
  title: string;
}

export interface GlobalMenuLogoProps {
  link: GlobalMenuLogoLink;
  onClick?: (link: GlobalMenuLogoLink) => void;
}

const useGlobalMenuLogoStyles = createUseStyles(globalMenuLogoStyles);

export const GlobalMenuLogo = memo((props: GlobalMenuLogoProps) => {
  const classes = useGlobalMenuLogoStyles();
  const { states } = useGlobalHeaderContext();

  const { srcDark, customLinkProps, ...rest } = props.link;

  const onLinkClick = () => {
    if (props.onClick) {
      props.onClick(props.link);
    }
  };

  return (
    <div className={classNames(classes.globalMenuLogoWrapper)}>
      <Link
        aria-label={props.link.title}
        className={classes.globalMenuLogoLink}
        data-testid="ctg-header-logo-link"
        onClick={onLinkClick}
        {...rest}
      >
        <img
          aria-hidden
          alt=""
          className={classNames(classes.globalMenuLogo)}
          src={
            states.style.headerFirstRow?.darkTheme ?? states.isDark
              ? props.link.srcDark
              : props.link.src
          }
        />
      </Link>
    </div>
  );
});
