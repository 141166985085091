import React, { memo, RefObject } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { NativeButton } from '@czechtv/components';
import { useGlobalHeaderContext } from '../../../../providers/GlobalHeaderProvider';
import { globalMenuNavButtonStyles } from './GlobalMenuNavButton.style';

export interface GlobalMenuNavButtonProps {
  active?: boolean;
  buttonRef?: RefObject<HTMLButtonElement>;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}
const useGlobalMenuNavButtonStyles = createUseStyles(globalMenuNavButtonStyles);

export const GlobalMenuNavButton = memo(
  ({ onClick, active, buttonRef }: GlobalMenuNavButtonProps) => {
    const classes = useGlobalMenuNavButtonStyles();

    const { states } = useGlobalHeaderContext();

    return (
      <div
        className={classNames(classes.globalMenuNavButtonWrapper, {
          dark: states.style.headerFirstRow?.darkTheme ?? states.isDark,
        })}
      >
        <NativeButton
          aria-controls="global-menu"
          aria-haspopup="menu"
          aria-label={`${active ? 'Skrýt otevřený' : 'Otevřít'} výběr produktů České televize`}
          className={classNames(
            classes.menuButton,
            active && classes.menuButtonActive,
            classes.insetFocusVisible
          )}
          data-testid="ctg-global-menu-button"
          ref={buttonRef}
          tabIndex={0}
          type="button"
          onClick={onClick}
        >
          <svg
            aria-hidden
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              d="M19 17a2 2 0 110 4 2 2 0 010-4zm-7 0a2 2 0 110 4 2 2 0 010-4zm-7 0a2 2 0 110 4 2 2 0 010-4zm14-7a2 2 0 110 4 2 2 0 010-4zm-7 0a2 2 0 110 4 2 2 0 010-4zm-7 0a2 2 0 110 4 2 2 0 010-4zm14-7a2 2 0 110 4 2 2 0 010-4zm-7 0a2 2 0 110 4 2 2 0 010-4zM5 3a2 2 0 110 4 2 2 0 010-4z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>
          <span>ČT</span>
        </NativeButton>
      </div>
    );
  }
);
