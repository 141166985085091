import {
  Blue_base,
  buildMediaQuery,
  Grey_10,
  Grey_20,
  IMPORTANT_FONT,
  pxToRem,
  DefaultBoxShadow,
  Red_base,
} from '@czechtv/styles';

export const globalMenuNavStyles = {
  contextMenu: {}, // Class FK
  contextMenuItemActive: {}, // Class FK
  contextMenuItemDisabled: {}, // Class FK
  globalMenuNav: {
    position: 'relative',
    backgroundColor: '#fff',
    boxShadow: DefaultBoxShadow,
    display: 'none',
    width: '100%',
    [buildMediaQuery({ minWidth: 428 })]: {
      width: 320,
      borderRadius: 4,
    },
    '& > ul + ul': {
      padding: 'initial',
      borderTop: `1px solid ${Grey_20}`,
    },
    '& > ul:last-child:first-child': {
      marginBottom: 0,
    },
    '& $contextMenu': {
      backgroundColor: 'transparent',
      width: '100%',
      boxShadow: 'none',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 0,
      paddingRight: 0,
      '& li > a,& li > span': {
        paddingTop: 9,
        paddingBottom: 9,
        paddingLeft: 56,
        paddingRight: 56,
      },
      '& li:not($contextMenuItemDisabled)': {
        '& > a,& > span': {
          '&:hover': {
            color: Red_base,
            backgroundColor: Grey_10,
          },
        },
      },
      '& li$contextMenuItemActive': {
        '& > a,& > span': {
          color: Red_base,
          '& svg': {
            color: Blue_base,
          },
        },
      },
    },
  },
  globalMenuNavVisible: {
    display: 'block',
  },
  contextMenuPrimary: {
    '& li > a,& li > span': {
      textDecoration: 'none',
      paddingTop: 9,
      paddingBottom: 9,
      paddingLeft: 40,
      paddingRight: 40,
      fontFamily: IMPORTANT_FONT,
      fontSize: pxToRem(18),
      fontWeight: 500,
      transition: 'background-color 200ms ease-in-out, color 200ms ease-in-out',
      '&:hover': {
        color: `${Red_base} !important`,
        backgroundColor: `${Grey_10} !important`,
      },
    },
  },
  contextMenuSecondary: {
    '& li > a,& li > span': {
      textDecoration: 'none',
      paddingLeft: 40,
      paddingRight: 40,
      transition: 'background-color 200ms ease-in-out, color 200ms ease-in-out',
      '&:hover': {
        color: `${Red_base} !important`,
        backgroundColor: `${Grey_10} !important`,
      },
    },
  },
};
