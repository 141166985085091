import {
  Blue_base,
  buildMediaQuery,
  Grey_10,
  Grey_20,
  IMPORTANT_FONT,
  pxToRem,
  DefaultBoxShadow,
  Red_base,
} from '@czechtv/styles';

export const menuNavWrapperStyles = {
  menuNavWrapper: {
    position: 'relative',
    backgroundColor: '#fff',
    boxShadow: DefaultBoxShadow,
    width: '100%',
    marginRight: 0,
    marginLeft: 'auto',
    opacity: '0%',
    pointerEvents: 'none',
    transformOrigin: 'top right',
    transition:
      'transform 10ms cubic-bezier(0, 0, 0.3, 1) 300ms,visibility 200ms cubic-bezier(0, 0, 0.3, 1),opacity 200ms cubic-bezier(0, 0, 0.3, 1)',
    textDecoration: 'none',
    visibility: 'hidden',
    [buildMediaQuery({ minWidth: 428 })]: {
      width: 320,
      borderRadius: 4,
    },
    '& > ul + ul': {
      borderTop: `1px solid ${Grey_20}`,
      paddingTop: 8,
      paddingBottom: 8,
      margin: 0,
    },
    '& > ul:last-child:first-child': {
      marginBottom: 0,
    },
    '& $contextMenu': {
      backgroundColor: 'transparent',
      width: '100%',
      boxShadow: 'none',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 0,
      paddingRight: 0,
      '& li > a,& li > span': {
        paddingTop: 9,
        paddingBottom: 9,
        paddingLeft: 56,
        paddingRight: 56,
      },
      '& li:not($contextMenuItemDisabled)': {
        '& > a,& > span': {
          '&:hover': {
            color: Red_base,
            backgroundColor: Grey_10,
          },
        },
      },
      '& li$contextMenuItemActive': {
        '& > a,& > span': {
          color: Red_base,
          '& svg': {
            color: Blue_base,
          },
        },
      },
    },
    '& $contextMenu$contextMenuPrimary': {
      '& li > a,& li > span': {
        paddingTop: 9,
        paddingBottom: 9,
        paddingLeft: 56,
        paddingRight: 56,
        fontFamily: IMPORTANT_FONT,
        fontSize: pxToRem(18),
        fontWeight: 500,
      },
    },
  },
  menuNavVisible: {
    opacity: 1,
    marginRight: 0,
    pointerEvents: 'all',
    marginLeft: 'auto',
    visibility: 'visible',
    transition:
      'transform 10ms cubic-bezier(0, 0, 0.3, 1) 300ms,visibility 200ms cubic-bezier(0, 0, 0.3, 1),opacity 200ms cubic-bezier(0, 0, 0.3, 1)',
  },
};
