import classNames from 'classnames';
import React, { memo } from 'react';
import { spacerClassnames } from './Spacer.css';

export interface SpacerProps {
  visible?: boolean;
  withProductMenuHeight?: boolean;
}

export const Spacer = memo(({ visible = false, withProductMenuHeight = false }: SpacerProps) => {
  return (
    <div
      className={classNames(
        spacerClassnames.spacer,
        withProductMenuHeight && spacerClassnames.spacerWithProductMenu,
        visible && spacerClassnames.spacerVisible
      )}
    />
  );
});
