import { Blue_base } from '@czechtv/styles';

export const link = {
  color: Blue_base,
  textDecoration: 'none',
  transition: 'color 300ms ease-in',
  '&:hover': {
    textDecoration: 'underline',
  },
};
