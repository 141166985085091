import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Property } from 'csstype';
import { createUseStyles } from 'react-jss';
import analytics from '../../../common/analytics';
import { GLOBAL_MENU_MOBILE_HEIGHT } from '../../constants/main';
import { ContextMenuLink } from '../ContextMenu/ContextMenuItem/ContextMenuItem';
import { ContextMenu, ContextMenuProps } from '../ContextMenu/ContextMenu';
import { positionedMenuStyles } from './PositionedMenu.style';

interface PositionedMenuProps extends ContextMenuProps {
  buttonRef?: React.RefObject<HTMLButtonElement>;
  className?: string;
  isVisible: boolean;
  minWidth?: number;
  onLinkClick?: (link: ContextMenuLink, index: number) => void;
  padding?: number;
  toggleMenuVisibility: (visibility?: boolean) => void;
}

const usePositionedMenuStyles = createUseStyles(positionedMenuStyles);
const SCREEN_SPACE = 24;

export const PositionedMenu = memo(
  ({
    isVisible,
    toggleMenuVisibility,
    items,
    className,
    minWidth = 160,
    onLinkClick,
    padding = 10,
    buttonRef,
  }: PositionedMenuProps) => {
    const classes = usePositionedMenuStyles();
    const [maxWidth, setMaxWidth] = useState<Property.MaxWidth | number | undefined>(undefined);

    const handleOnLinkClick = (link: ContextMenuLink, index: number) => {
      const menuAnalyticsData = {
        menu: {
          type: 'product',
          level1: link.title,
        },
        interaction: true,
        _clear: true,
      };
      analytics.trigger({ type: 'MenuClick', data: menuAnalyticsData });

      // Predpokladame, ze polozka menu lze vybrat pouze z viditelneho menu
      toggleMenuVisibility();
      if (onLinkClick) {
        onLinkClick(link, index);
      }
    };

    const menuContainerRef = useRef<HTMLDivElement>(null);

    const handleMenuOverflow = useCallback(() => {
      if (!menuContainerRef.current) {
        return;
      }

      const menuContainer = menuContainerRef.current;

      const parent = menuContainer.parentElement;

      const parentHeight = parent?.getBoundingClientRect().height || 0;

      menuContainer.style.right = 'auto';
      menuContainer.style.bottom = `auto`;
      menuContainer.style.top = `${parentHeight + padding}px`;

      const size = menuContainer.getBoundingClientRect();

      const width = Math.max(
        minWidth,
        Math.floor(document.body.offsetWidth - size.x - SCREEN_SPACE)
      );

      if (width + size.x + SCREEN_SPACE > document.body.offsetWidth) {
        menuContainer.style.right = '0';
      }

      if (size.bottom > window.innerHeight) {
        menuContainer.style.top = 'auto';
        menuContainer.style.bottom = `${parentHeight + padding}px`;
      }

      // preklopime menu dolu pokud se nevejde do viewportu
      if (size.height > size.y - GLOBAL_MENU_MOBILE_HEIGHT) {
        menuContainer.style.bottom = 'auto';
        menuContainer.style.top = `${parentHeight + padding}px`;
      }

      setMaxWidth(width);
    }, [minWidth, padding]);

    useEffect(() => {
      const onClick = (e: Event) => {
        // a nen9 to klik na tla49tko menu
        if (
          menuContainerRef.current &&
          !menuContainerRef.current.contains(e.target as Node) &&
          !buttonRef?.current?.contains(e.target as Node)
        ) {
          // Pri kliknuti mimo rozbalene menu skryjeme menu
          if (isVisible) {
            toggleMenuVisibility(false);
          }
        }
      };

      handleMenuOverflow();

      window.addEventListener('resize', handleMenuOverflow);
      document.addEventListener('click', onClick);

      return () => {
        window.removeEventListener('resize', handleMenuOverflow);
        document.removeEventListener('click', onClick);
      };
    }, [handleMenuOverflow, toggleMenuVisibility, buttonRef]);

    return (
      <div
        aria-hidden={!isVisible}
        aria-modal={isVisible ? 'true' : 'false'}
        className={classNames(
          classes.positionedMenuWrapper,
          !isVisible && classes.positionedMenuWrapperHidden,
          isVisible && classes.positionedMenuWrapperShow,
          className
        )}
        id="local-menu"
        ref={menuContainerRef}
      >
        <ContextMenu items={items} maxWidth={maxWidth} onLinkClick={handleOnLinkClick} />
      </div>
    );
  }
);
