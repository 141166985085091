import classNames from 'classnames';
import React, { memo, MouseEvent } from 'react';
import { createUseStyles } from 'react-jss';
import { SrOnly } from '@czechtv/styles';
import { mediaMinDesktop1024, mediaMinTablet768 } from '../../../common/breakpoints';
import { staticUrlChannels } from '../../../common/variables';

interface ChannelsProps {
  className?: string;
  darkMode?: boolean;
  onLinkClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}
interface ChannelLogoProps {
  alt: string;
  href: string;
  onLinkClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  src: string;
  title: string;
}

const useStyles = createUseStyles({
  ChannelsList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'none',
    width: '100%',
    [mediaMinTablet768]: {
      display: 'flex',
    },
    [mediaMinDesktop1024]: {
      flexWrap: 'wrap',
    },
  },
  ChannelsListItem: {
    flex: '1 1 auto',
    justifyContent: 'center',
    display: 'flex',
    marginBottom: 40,
    [mediaMinDesktop1024]: {
      flex: '0 1 30%',
      justifyContent: 'start',
    },
    '& img': {
      height: 20,
      [mediaMinDesktop1024]: {
        height: 24,
      },
    },
  },
  srOnly: {
    ...SrOnly,
  },
});

const CT_24_SRC = `${staticUrlChannels}ct24.svg`;
const CT_SPORT_SRC = `${staticUrlChannels}ctsport.svg`;
const IVYSILANI_SRC = `${staticUrlChannels}ivysilani.svg`;
const CT_D_SRC = `${staticUrlChannels}ctd.svg`;
const CT_ART_SRC = `${staticUrlChannels}ctart.svg`;
const CT_EDU_SRC = `${staticUrlChannels}edu.svg`;
const IVYSILANI_SRC_DARK = `${staticUrlChannels}ivysilani_onDark.svg`;
const CT_EDU_SRC_DARK = `${staticUrlChannels}edu_onDark.svg`;

const ChannelLogo = ({ src, alt, title, href, onLinkClick }: ChannelLogoProps) => {
  const classes = useStyles();
  return (
    <li className={classes.ChannelsListItem}>
      <a href={href} title={title} onClick={onLinkClick}>
        <img alt={alt} src={src} />
      </a>
    </li>
  );
};

export const Channels = memo(({ className, darkMode, onLinkClick }: ChannelsProps) => {
  const classes = useStyles();
  return (
    <>
      <h5 className={classes.srOnly}>Rozcestník stránek</h5>
      <ul className={classNames(classes.ChannelsList, className)}>
        <ChannelLogo
          alt="ČT24"
          data-testid="ct24"
          href="https://ct24.ceskatelevize.cz"
          src={CT_24_SRC}
          title="Stránky ČT24"
          onLinkClick={onLinkClick}
        />
        <ChannelLogo
          alt="ČT Sport"
          data-testid="ct4"
          href="https://sport.ceskatelevize.cz"
          src={CT_SPORT_SRC}
          title="Stránky ČT Sport"
          onLinkClick={onLinkClick}
        />
        <ChannelLogo
          alt="iVysílání"
          data-testid="ivysilani"
          href="https://www.ceskatelevize.cz/ivysilani/"
          src={darkMode ? IVYSILANI_SRC_DARK : IVYSILANI_SRC}
          title="Stránky iVysílání"
          onLinkClick={onLinkClick}
        />
        <ChannelLogo
          alt="ČT Déčko"
          data-testid="decko"
          href="https://decko.ceskatelevize.cz"
          src={CT_D_SRC}
          title="Stránky ČT Déčko"
          onLinkClick={onLinkClick}
        />
        <ChannelLogo
          alt="ČT art"
          data-testid="art"
          href="https://art.ceskatelevize.cz/"
          src={CT_ART_SRC}
          title="Stránky ČT art"
          onLinkClick={onLinkClick}
        />
        <ChannelLogo
          alt="ČT edu"
          data-testid="edu"
          href="https://edu.ceskatelevize.cz/"
          src={darkMode ? CT_EDU_SRC_DARK : CT_EDU_SRC}
          title="Stránky ČT edu"
          onLinkClick={onLinkClick}
        />
      </ul>
    </>
  );
});
