import { buildMediaQuery, tablet768 } from '@czechtv/styles';
import { GLOBAL_MENU_DESKTOP_HEIGHT, GLOBAL_MENU_MOBILE_HEIGHT } from '../../constants/main';

export interface GlobalMenuStylesOptions {
  background?: string;
  darkTheme?: boolean;
}

export const globalMenuStyles = {
  hasSHPNav: {},
  globalMenuWrapper: {
    background: 'transparent',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid rgba(0, 0, 0, .2)`,
    height: GLOBAL_MENU_MOBILE_HEIGHT,
    [buildMediaQuery({ minWidth: tablet768 })]: {
      height: GLOBAL_MENU_DESKTOP_HEIGHT,
    },
  },
  backgroundClass: {
    background: ({ background }: GlobalMenuStylesOptions) => background || 'transparent',
  },
  globalMenuRightItems: {
    '$globalMenuWrapper &': {
      marginLeft: 'auto',
      display: 'flex',
      height: '100%',
    },
  },
  globalMenuLogoWrapper: {
    '$globalMenuWrapper &': {
      flex: [0, 1, 'auto'],
      height: '100%',
      color: 'inherit',
    },
  },
};
