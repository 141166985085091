import React, { memo, RefObject, useCallback, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useGlobalHeaderContext } from '../../providers/GlobalHeaderProvider';
import { LocalMenuHotlinkNavItemLink } from '../../components/LocalMenu/LocalMenuHotlinkNav/LocalMenuHotlinkNavItem/LocalMenuHotlinkNavItem';
import { LocalMenu } from '../../components/LocalMenu/LocalMenu';
import { LocalMenuNavItemLink } from '../../components/LocalMenu/LocalMenuNav/LocalMenuNavItem/LocalMenuNavItem';
import analytics from '../../../common/analytics';
import { localMenuFactoryStyles } from './LocalMenuFactory.style';

export interface CTGHeaderLocalMenuViewProps {
  hotlinks?: LocalMenuHotlinkNavItemLink[];
  items?: LocalMenuNavItemLink[];
  localMobileMenuButtonRef?: RefObject<HTMLButtonElement>;
  maxWidth?: number;
}

const useLocalMenuFactoryStyles = createUseStyles(localMenuFactoryStyles);

export const LocalMenuFactory = memo(
  ({ items, hotlinks, localMobileMenuButtonRef, maxWidth }: CTGHeaderLocalMenuViewProps) => {
    useLocalMenuFactoryStyles();

    const { states, setStates } = useGlobalHeaderContext();

    const getItems = (sourceItems: LocalMenuNavItemLink[] | LocalMenuHotlinkNavItemLink[]) => {
      if (!sourceItems || sourceItems.length < 1) {
        return [];
      }

      if (typeof window === 'undefined') {
        return [
          ...sourceItems.flatMap((sourceItem) => {
            return {
              ...sourceItem,
              ...{ active: sourceItem.active || false },
            };
          }),
        ];
      }

      return [
        ...sourceItems.flatMap((sourceItem) => {
          let isActive;
          if (!sourceItem.href) {
            return {
              ...sourceItem,
              ...{ active: isActive },
            };
          }
          if (sourceItem.href) {
            isActive = sourceItem.active || sourceItem.href === window.location.pathname;
          }

          return sourceItem.href
            ? {
                ...sourceItem,
                ...{ active: isActive },
              }
            : [];
        }),
      ];
    };

    const handleCloseMenus = useCallback(() => {
      if (!states.localMobileMenuNavOpened) {
        setStates({
          ...states,
          ...{ globalMenuNavOpened: false },
          ...{ accountMenuNavOpened: false },
        });
      }
    }, [states, setStates]);

    const onClickLocalMobileMenuButton = useCallback(() => {
      handleCloseMenus();

      setStates({ ...states, ...{ localMobileMenuNavOpened: !states.localMobileMenuNavOpened } });

      const menuAnalyticsData = {
        menu: {
          type: 'product',
        },
        interaction: true,
        _clear: true,
      };

      if (states.localMobileMenuNavOpened) {
        analytics.trigger({
          type: 'MenuClose',
          data: menuAnalyticsData,
        });
      } else {
        analytics.trigger({
          type: 'MenuOpen',
          data: menuAnalyticsData,
        });
      }
    }, [handleCloseMenus, setStates, states]);

    const [isFloatingStyle, setIsFloatingStyle] = useState(false);

    useEffect(() => {
      setIsFloatingStyle(states.isFixed);
    }, [states.isFixed]);

    return (
      <LocalMenu
        {...{
          items: getItems(items ?? []) as LocalMenuNavItemLink[],
          hotlinks: getItems(hotlinks ?? []) as LocalMenuHotlinkNavItemLink[],
          ariaHidden: states.localMobileMenuNavOpened,
          onClickMobileMenuButton: onClickLocalMobileMenuButton,
          mobileMenuButtonActive: states.localMobileMenuNavOpened,
          mobileMenuButtonRef: localMobileMenuButtonRef,
          isFloatingStyle,
          maxWidth,
        }}
      />
    );
  }
);
