import { DEFAULT_FONT, Blue_base, ButtonTransitionDuration, pxToRem } from '@czechtv/styles';
import { PRODUCT_MENU_DESKTOP_HEIGHT } from '../../../../constants/main';

export const localMenuNavItemStyles = {
  insetFocusVisible: {}, // Class FK
  localMenuNavListItem: {
    // odpovida cca 30ti znakum
    maxWidth: 235,
    position: 'relative',
    '& > a, & > button, & > span': {
      textDecoration: 'none',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      boxSizing: 'border-box',
      display: 'block',
      whiteSpace: 'nowrap',
      margin: 0,
      fontFamily: DEFAULT_FONT,
      fontSize: pxToRem(16),
      fontWeight: 600,

      transition: `background-color ${ButtonTransitionDuration}, border-color ${ButtonTransitionDuration},color ${ButtonTransitionDuration}`,
      letterSpacing: 'normal',
      height: PRODUCT_MENU_DESKTOP_HEIGHT,
      padding: '0 14px',
      lineHeight: `${PRODUCT_MENU_DESKTOP_HEIGHT}px`,
      position: 'relative',
      color: Blue_base,
      '$localMenuNavListItemActive&, &:hover': {
        backgroundColor: Blue_base,
        color: '#fff',
        borderColor: Blue_base,
        textDecoration: 'none',
      },
    },
    '& > span': {
      cursor: 'default',
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  localMenuNavListItemActive: {
    '&:before': {
      content: '""',
      width: 0,
      height: 0,
      position: 'absolute',
      bottom: -6,
      marginLeft: -6,
      left: '50%',
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent',
      borderTop: `6px solid ${Blue_base}`,
    },
  },
};
