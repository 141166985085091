import { IMPORTANT_FONT } from '@czechtv/styles';

export const globalMenuSuperHomepageStyles = {
  globalMenuSuperHomepageNav: {
    marginLeft: 7,
  },
  globalMenuSuperHomepageNavItems: {
    fontFamily: IMPORTANT_FONT,
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    '& li': {
      flex: '0 1',
      marginRight: 16,
    },
  },
};
