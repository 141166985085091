import React, { memo } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { CustomLinkProps } from '../../../providers/GlobalHeaderProvider';
import { Link } from '../../Link';
import { ContextMenuItemThemeProps, contextMenuItemStyles } from './ContextMenuItem.style';

export interface ContextMenuItemProps {
  className?: string;
  icon?: string;
  link: ContextMenuLink;
  onLinkClick?: (link: ContextMenuLink) => void;
  theme?: ContextMenuItemThemeProps;
}

export interface ContextMenuUrl {
  as?: string;
  href: string;
}

export interface ContextMenuLink {
  absolute?: boolean;
  active?: boolean;
  customLinkProps?: CustomLinkProps;
  disabled?: boolean;
  href: string;
  icon?: string;
  title: string;
  url?: ContextMenuUrl;
}

const useContextMenuItemStyles = createUseStyles(contextMenuItemStyles);

export const ContextMenuItem = memo(
  ({ link, onLinkClick, className, theme, icon }: ContextMenuItemProps) => {
    const classes = useContextMenuItemStyles(theme);

    const linkItem = () => {
      return link.disabled || link.active ? (
        <span aria-current="page" className={classNames(classes.contextMenuItemLink)}>
          {link.title}
        </span>
      ) : (
        <Link
          className={classNames(classes.contextMenuItemLink, classes.insetFocusVisible)}
          onClick={onLinkClick ? () => onLinkClick(link) : undefined}
          {...link}
        >
          {icon && (
            <div className={classNames(classes.contextMenuIcon)}>
              <img alt="" src={icon} />
            </div>
          )}
          {link.title}
        </Link>
      );
    };

    return (
      <li
        className={classNames(
          className,
          classes.contextMenuItem,
          link.active && classes.contextMenuItemActive,
          link.disabled && classes.contextMenuItemDisabled
        )}
      >
        {link.href ? linkItem() : <p>{link.title}</p>}
      </li>
    );
  }
);
