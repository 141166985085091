import React, { memo, useMemo } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { wrapperStyles } from './Wrapper.style';

export enum WrapperPositions {
  absolute = 'absolute',
  fixed = 'fixed',
  relative = 'relative',
}

export interface WrapperProps {
  ariaHidden: boolean;
  children?: React.ReactNode;
  className?: string;
  hasThirdRow?: boolean;
  position: WrapperPositions;
}
const useWrapperStyles = createUseStyles(wrapperStyles);

export const Wrapper = memo(({ ariaHidden = false, className, children }: WrapperProps) => {
  const classes = useWrapperStyles();

  const getClasses = useMemo(() => {
    const returned = [className, classes.wrapper];

    return returned;
  }, [className, classes]);

  return (
    <header aria-hidden={ariaHidden} className={classNames(getClasses)} data-testid="ctg-header">
      {children}
    </header>
  );
});
