import { GLOBAL_MENU_MOBILE_HEIGHT, PRODUCT_MENU_MOBILE_HEIGHT } from '../../constants/main';

export const localMenuMobileNavFactoryStyles = {
  localMenuMobileNavWrapper: {
    position: 'fixed',
    top: GLOBAL_MENU_MOBILE_HEIGHT + PRODUCT_MENU_MOBILE_HEIGHT, // upravit
    height: `calc(100% - ${GLOBAL_MENU_MOBILE_HEIGHT + PRODUCT_MENU_MOBILE_HEIGHT}px)`, // upravit
    width: '100%',
    zIndex: 101,
    overflow: 'auto',
    left: 0,
    padding: [0],
    visibility: 'hidden',
    backgroundColor: 'rgba(0, 0, 0, .3)',
  },
  localMenuMobileNavWrapperVisible: {
    visibility: 'visible',
  },
};
