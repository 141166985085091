import React, { memo, RefObject } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { buildMediaQuery, tablet768, mobile428 } from '@czechtv/styles';
import analytics from '../../../common/analytics';
import { GLOBAL_MENU_DESKTOP_HEIGHT, GLOBAL_MENU_MOBILE_HEIGHT } from '../../constants/main';
import { useGlobalHeaderContext } from '../../providers/GlobalHeaderProvider';
import { ContextMenuLink } from '../../components/ContextMenu/ContextMenuItem/ContextMenuItem';
import { GlobalMenuAccountNav } from '../../components/GlobalMenu/GlobalMenuNav/GlobalMenuAccountNav/GlobalMenuAccountNav';
import { accountMenuItems } from '../../constants/accountMenuNavItems';

const useStyles = createUseStyles({
  bodyDisableScroll: {}, // Class FK
  globalMenuAccountNavWrapper: {
    position: 'fixed',
    top: GLOBAL_MENU_MOBILE_HEIGHT,
    height: `calc(100% - ${GLOBAL_MENU_MOBILE_HEIGHT}px)`,
    width: '100%',
    zIndex: 101,
    overflow: 'auto',
    right: 0,
    visibility: 'hidden',
    boxSizing: 'border-box',
    [buildMediaQuery({ minWidth: mobile428 })]: {
      width: 'fit-content',
      padding: [8],
    },
    [buildMediaQuery({ minWidth: tablet768 })]: {
      padding: [16],
      top: GLOBAL_MENU_DESKTOP_HEIGHT,
      height: `calc(100% - ${GLOBAL_MENU_DESKTOP_HEIGHT}px)`,
      backgroundColor: 'transparent',
    },
  },
  globalMenuNav: {
    marginLeft: 'auto',
  },
  globalMenuAccountNavWrapperVisible: {
    visibility: 'visible',
    'body$bodyDisableScroll &': {
      // Fix aby neposkakovalo globální menu přidáno `body$bodyDisableScroll &`
      visibility: 'visible',
    },
  },
});

export interface GlobalMenuAccountNavFactoryProps {
  accountMenuButtonRef?: RefObject<HTMLButtonElement>;
}

export const GlobalMenuAccountNavFactory = memo(
  ({ accountMenuButtonRef }: GlobalMenuAccountNavFactoryProps) => {
    const classes = useStyles();

    const { states, setStates } = useGlobalHeaderContext();

    const hideGlobalMenuAccountNav = () => {
      const menuAnalyticsData = {
        menu: {
          type: 'global',
        },
        interaction: true,
        _clear: true,
      };

      analytics.trigger({
        type: 'MenuClose',
        data: menuAnalyticsData,
      });
      setStates({ ...states, ...{ accountMenuNavOpened: false } });
    };

    const onLinkClick = (e: ContextMenuLink) => {
      const menuAnalyticsData = {
        menu: {
          type: 'global',
          level1: e.title,
        },
        interaction: true,
        _clear: true,
      };

      analytics.trigger({ type: 'MenuClick', data: menuAnalyticsData });
    };

    return (
      <div
        className={classNames(
          classes.globalMenuAccountNavWrapper,
          states.accountMenuNavOpened && classes.globalMenuAccountNavWrapperVisible
        )}
      >
        <GlobalMenuAccountNav
          accountMenuItems={accountMenuItems}
          buttonRef={accountMenuButtonRef}
          visible={states.accountMenuNavOpened}
          onClickOutside={hideGlobalMenuAccountNav}
          onLinkClick={onLinkClick}
        />
      </div>
    );
  }
);
