import React, { ReactNode } from 'react';
import { JssProvider } from 'react-jss';
import { snakeCase } from 'snake-case';
import { JSS_PREFIX } from '../constants/main';

const createGenerateId = () => {
  return (rule: any) => {
    if (rule.type === 'keyframes') {
      return (rule as unknown as CSSKeyframesRule).name;
    }

    return `${JSS_PREFIX}${snakeCase(rule.key, { delimiter: '-' })}`;
  };
};

export interface GlobalHeaderJssProviderProps {
  children?: ReactNode;
}
export const GlobalHeaderJssProvider = ({ children }: GlobalHeaderJssProviderProps) => (
  <JssProvider generateId={createGenerateId()}>{children}</JssProvider>
);
