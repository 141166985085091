import { staticUrlIcons } from '../../common/variables';
import { ContextMenuLink } from '../components/ContextMenu/ContextMenuItem/ContextMenuItem';

export enum AccountMenuLinkId {
  ACCOUNT_SETTINGS = 'account_settings',
  FAVOURITE = 'favourite',
  LOGOUT = 'logout',
  TV_LOGIN = 'tv_login',
  WATCHED = 'watched',
}

export interface AccountMenuLink extends ContextMenuLink {
  id: AccountMenuLinkId;
}

export const accountMenuItems: AccountMenuLink[] = [
  {
    title: 'Spravovat účet',
    id: AccountMenuLinkId.ACCOUNT_SETTINGS,
    href: 'https://www.ceskatelevize.cz/profil/',
  },
  {
    title: 'Oblíbené pořady',
    icon: `${staticUrlIcons}heart.svg`,
    id: AccountMenuLinkId.FAVOURITE,
    href: 'https://www.ceskatelevize.cz/ivysilani/oblibene/',
  },
  {
    title: 'Rozkoukaná videa',
    icon: `${staticUrlIcons}clock.svg`,
    id: AccountMenuLinkId.WATCHED,
    href: 'https://www.ceskatelevize.cz/ivysilani/rozkoukane/',
  },
  {
    title: 'Přihlásit se do TV',
    icon: `${staticUrlIcons}tv.svg`,
    id: AccountMenuLinkId.TV_LOGIN,
    href: 'https://moje.ceskatelevize.cz/tv',
  },
  {
    title: 'Odhlásit se',
    icon: `${staticUrlIcons}logout.svg`,
    id: AccountMenuLinkId.LOGOUT,
    href: 'https://www.ceskatelevize.cz/profil/odhlasit/',
  },
];
