import { DEFAULT_FONT, pxToRem } from '@czechtv/styles';

export const localMenuMobileButtonStyles = {
  localMenuMobileButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',

    '& > button': {
      display: 'flex',
      alignItems: 'center',
      padding: [10, 16],
      color: 'inherit',
    },
  },
  localMenuMobileButton: {
    '& > svg': {
      height: 24,
      width: 24,
      marginRight: '4px',
      color: 'inherit',
    },
    '& > span': {
      color: 'inherit',
      fontFamily: DEFAULT_FONT,
      lineHeight: 1,
      fontSize: pxToRem(16),
    },
  },
  localMenuMobileButtonActive: {},
  insetFocusVisible: {},
};
