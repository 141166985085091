import { Grey_100, Grey_50, Grey_70 } from '@czechtv/styles';
import { ContextMenuProps } from './ContextMenu';
import { ContextMenuItemThemeProps } from './ContextMenuItem/ContextMenuItem.style';

export interface ContextMenuStylesProps {
  maxWidth: ContextMenuProps['maxWidth'];
}

export const ContextMenuItemDarkTheme: ContextMenuItemThemeProps = {
  itemLinkActiveColor: '#fff',
  itemLinkColor: '#fff',
  itemLinkHoverColor: '#fff',
  itemLinkHoverBackgroundColor: Grey_70,
  itemLinkDisabledColor: Grey_50,
  itemLinkDisabledBackgroundColor: 'transparent',
};

export const contextMenuStyles = {
  contextMenuReset: {
    paddingLeft: 0,
    listStyle: 'none',
    margin: 0,
  },
  contextMenu: {
    display: 'inline-flex',
    flexDirection: 'column',
    maxWidth: ({ maxWidth }: ContextMenuStylesProps) => maxWidth,
    background: '#fff',
    padding: [12, 0],
    margin: 0,
    boxSizing: 'border-box',
    width: '100%',
  },
  contextMenuDark: {
    '$contextMenu&': {
      background: Grey_100,
      border: `1px solid ${Grey_70}`,
    },
  },
};
