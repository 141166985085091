import { Blue_base, buildMediaQuery, tablet768 } from '@czechtv/styles';

export const localMenuFactoryStyles = {
  headerDark: {}, // Class FK
  localMenuNavListItemActive: {}, // Class FK
  hotlinkNavListItemActive: {}, // Class FK
  wrapperFixed: {}, // Class FK
  localMenuMobileButton: {}, // Class FK
  localMenuMobileButtonActive: {}, // Class FK
  localMenuNavListItem: {
    '$headerDark &': {
      '& > a, & > button, & > span': {
        color: '#fff',
        '&:hover': {
          backgroundColor: '#fff',
          color: Blue_base,
          borderColor: '#fff',
        },
      },
      '&$localMenuNavListItemActive': {
        '& > a, & > button, & > span': {
          backgroundColor: '#fff',
          color: Blue_base,
          borderColor: '#fff',
        },
        '&:before': {
          borderTopColor: '#fff',
        },
      },
    },
  },
  hotlinkNavListItem: {
    '$headerDark &': {
      '& > a, & > button, & > span': {
        color: '#fff',
        '$hotlinkNavListItemActive&, &:hover': {
          backgroundColor: '#fff',
          color: Blue_base,
          borderColor: '#fff',
        },
        '$hotlinkNavListItemActive&': {
          '&:before': {
            borderTop: '6px solid white',
          },
        },
      },
      '&$hotlinkNavListItemActive': {
        '& > a, & > button, & > span': {
          backgroundColor: '#fff',
          color: Blue_base,
          borderColor: '#fff',
          '&:before': {
            borderTop: '6px solid white',
          },
        },
      },
      [buildMediaQuery({ maxWidth: tablet768 - 1 })]: {
        borderColor: 'rgba(255, 255, 255, 0.2);',
      },
    },
  },
  localMenu: {
    '$headerDark &': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
    },
  },
  hotlinkNavWrapper: {
    '$headerDark & button:hover,$headerDark & button$localMenuMobileButtonActive': {
      color: Blue_base,
      backgroundColor: '#fff',
    },
  },
  localMenuMobileButtonWrapper: {
    '$headerDark & $localMenuMobileButton$localMenuMobileButtonActive': {
      color: Blue_base,
      backgroundColor: '#fff',
      // Border pouze pod buttonem u dark
      // borderBottom: '1px solid #D7DBDF', - VP-229
      height: 'calc(100% + 1px)',
    },
    '$headerDark & $localMenuMobileButton:hover': {
      '@media (hover: hover)': {
        color: Blue_base,
        backgroundColor: '#fff',
        '.inverted &': {
          backgroundColor: Blue_base,
          color: '#fff',
        },
      },
    },
  },
};
