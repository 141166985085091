import React, { ReactNode, memo, MouseEvent } from 'react';
import { createUseStyles } from 'react-jss';
import classnames from 'classnames';
import { body16, Grey_60, label12, WhiteOpacity80 } from '@czechtv/styles';
import { mediaMinMobile414, mediaMinTablet768 } from '../../../common/breakpoints';
import { staticUrlIcons } from '../../../common/variables';

interface SocialProps {
  className?: string;
  darkMode?: boolean;
  onLinkClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

const INSTAGRAM_SRC = `${staticUrlIcons}instagram.svg`;
const INSTAGRAM_HOVER_SRC = `${staticUrlIcons}instagramHover.svg`;
const TWITTER_SRC = `${staticUrlIcons}x.svg`;
const TWITTER_HOVER_SRC = `${staticUrlIcons}xHover.svg`;
const YOUTUBE_SRC = `${staticUrlIcons}youtube.svg`;
const YOUTUBE_HOVER_SRC = `${staticUrlIcons}youtubeHover.svg`;
const FACEBOOK_SRC = `${staticUrlIcons}facebook.svg`;
const FACEBOOK_HOVER_SRC = `${staticUrlIcons}facebookHover.svg`;
const THREADS_SRC = `${staticUrlIcons}threads.svg`;
const THREADS_HOVER_SRC = `${staticUrlIcons}threadsHover.svg`;
const THREADS_DARK_SRC = `${staticUrlIcons}threads_onDark.svg`;
const INSTAGRAM_DARK_SRC = `${staticUrlIcons}instagram_onDark.svg`;
const TWITTER_DARK_SRC = `${staticUrlIcons}x_onDark.svg`;
const YOUTUBE_DARK_SRC = `${staticUrlIcons}youTube_onDark.svg`;
const FACEBOOK_DARK_SRC = `${staticUrlIcons}facebook_onDark.svg`;

interface IconMobileProps {
  alt: string;
  children: ReactNode;
  href: string;
  iconHoverSrc: string;
  iconSrc: string;
  onLinkClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

const useStyles = createUseStyles({
  social: {
    ...body16,
    color: Grey_60,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  socialTitle: {
    margin: 0,
    display: 'none',
    whiteSpace: 'nowrap',
    [mediaMinTablet768]: {
      display: 'initial',
    },
    '&.darkMode': {
      color: WhiteOpacity80,
    },
  },
  socialList: {
    display: 'flex',
    padding: 0,
    margin: [0, 0, 16, 0],
    listStyle: 'none',
    [mediaMinTablet768]: {
      margin: [0, 0, 0, 15],
    },
  },
  socialListItem: {
    margin: [0, 4],
    textAlign: 'center',
    width: 52,
    [mediaMinMobile414]: {
      width: 64,
    },
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    '&:hover img$iconNoHover': {
      display: 'none',
    },
    '&:hover img$iconHover': {
      display: 'block',
    },
    '& img': {
      width: 36,
      height: 36,
      margin: 'auto',
      [mediaMinTablet768]: {
        width: 32,
        height: 32,
      },
    },

    [mediaMinTablet768]: {
      width: 'initial',
    },
  },
  iconHover: {
    display: 'none',
  },
  iconNoHover: {
    display: 'block',
  },
  socialListItemTitle: {
    ...label12,
    fontWeight: 400,
    textTransform: 'initial',
    color: Grey_60,
    lineHeight: '18px',
    whiteSpace: 'nowrap',
    [mediaMinTablet768]: {
      display: 'none',
    },
  },
  socialListItemIcon: {},
  socialItemLink: {
    textDecoration: 'none',
    display: 'block',
  },
});

const IconMobile = ({
  children,
  href,
  iconSrc,
  iconHoverSrc,
  alt,
  onLinkClick,
}: IconMobileProps) => {
  const classes = useStyles();

  return (
    <li className={classes.socialListItem}>
      <a
        aria-label={alt}
        className={classes.socialItemLink}
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        onClick={onLinkClick}
      >
        <div className={classes.socialListItemIcon}>
          <img alt="" className={classes.iconHover} src={iconHoverSrc} />
          <img alt="" className={classes.iconNoHover} src={iconSrc} />
        </div>
        <div aria-hidden="true" className={classes.socialListItemTitle}>
          {children}
        </div>
      </a>
    </li>
  );
};

export const Social = memo(({ className, onLinkClick, darkMode }: SocialProps) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.social, className)}>
      <div className={classnames(classes.socialTitle, { darkMode })}>
        Česká televize na sociálních sítích:
      </div>
      <ul className={classes.socialList}>
        <IconMobile
          alt="Instagram - Česká televize"
          href="https://www.instagram.com/ceskatelevize"
          iconHoverSrc={INSTAGRAM_HOVER_SRC}
          iconSrc={darkMode ? INSTAGRAM_DARK_SRC : INSTAGRAM_SRC}
          onLinkClick={onLinkClick}
        >
          Instagram
        </IconMobile>
        <IconMobile
          alt="Facebook - Česká televize"
          href="https://www.facebook.com/ceskatelevize"
          iconHoverSrc={FACEBOOK_HOVER_SRC}
          iconSrc={darkMode ? FACEBOOK_DARK_SRC : FACEBOOK_SRC}
          onLinkClick={onLinkClick}
        >
          Facebook
        </IconMobile>
        <IconMobile
          alt="YouTube - Česká televize"
          href="https://www.youtube.com/ceskatelevize"
          iconHoverSrc={YOUTUBE_HOVER_SRC}
          iconSrc={darkMode ? YOUTUBE_DARK_SRC : YOUTUBE_SRC}
          onLinkClick={onLinkClick}
        >
          YouTube
        </IconMobile>
        <IconMobile
          alt="Twitter - Česká televize"
          href="https://twitter.com/czechtv"
          iconHoverSrc={TWITTER_HOVER_SRC}
          iconSrc={darkMode ? TWITTER_DARK_SRC : TWITTER_SRC}
          onLinkClick={onLinkClick}
        >
          <>(X) Twitter</>
        </IconMobile>
        <IconMobile
          alt="Threads - Česká televize"
          href="https://www.threads.net/@ceskatelevize"
          iconHoverSrc={THREADS_HOVER_SRC}
          iconSrc={darkMode ? THREADS_DARK_SRC : THREADS_SRC}
          onLinkClick={onLinkClick}
        >
          Threads
        </IconMobile>
      </ul>
    </div>
  );
});
