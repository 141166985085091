import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { localMenuHotlinkNavStyles } from './LocalMenuHotlinkNav.style';
import {
  LocalMenuHotlinkNavItem,
  LocalMenuHotlinkNavItemLink,
} from './LocalMenuHotlinkNavItem/LocalMenuHotlinkNavItem';

export interface CTGHeaderHotlinkNavProps {
  ariaLabel?: string;
  className?: string;
  isFloatingStyle?: boolean;
  items: LocalMenuHotlinkNavItemLink[];
}
const useLocalMenuHotlinkNavStyles = createUseStyles(localMenuHotlinkNavStyles);

export const CTGHeaderHotlinkNav = ({
  ariaLabel = 'Hotlinks',
  className,
  items,
  isFloatingStyle,
}: CTGHeaderHotlinkNavProps) => {
  const classes = useLocalMenuHotlinkNavStyles();

  const [hotlinks, setHotlinks] = useState<LocalMenuHotlinkNavItemLink[]>(items);

  useEffect(() => {
    setHotlinks(items);

    return () => {
      setHotlinks([]);
    };
  }, [items]);

  return (
    <div aria-label={ariaLabel} className={classNames(classes.hotlinkNav, className)} role="region">
      <ul className={classNames(classes.hotlinkNavList)}>
        {items &&
          hotlinks.map((hotlink, index) => (
            <LocalMenuHotlinkNavItem
              isFloatingStyle={isFloatingStyle}
              key={`${index.toString()}`}
              {...hotlink}
            />
          ))}
      </ul>
    </div>
  );
};
