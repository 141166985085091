import React, { createRef, memo, RefObject, useEffect, useState } from 'react';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { GLOBAL_THEME_CLASSNAME } from '@czechtv/styles';
import {
  GLOBAL_MENU_SCROLL_DISABLE,
  LOCAL_MENU_SCROLL_DISABLE,
  useBodyDisableScroll,
} from '../../uses/useBodyDisableScroll';
import { LocalMenuMobileNavFactory } from '../LocalMenuMobileNavFactory/LocalMenuMobileNavFactory';
import { Spacer } from '../../components/Spacer/Spacer';
import { defaultFloatingStyle, defaultStyle } from '../../common/defaultConfig';
import {
  GlobalHeaderStyleConfig,
  useGlobalHeaderContext,
} from '../../providers/GlobalHeaderProvider';
import { LocalMenuFactory } from '../LocalMenuFactory/LocalMenuFactory';
import { Wrapper, WrapperPositions } from '../../components/Wrapper/Wrapper';
import { GlobalMenuFactory } from '../GlobalMenuFactory/GlobalMenuFactory';
import { GlobalMenuAccountNavFactory } from '../GlobalMenuAccountNavFactory/GlobalMenuAccountNavFactory';
import { GlobalMenuNavFactory } from '../GlobalMenuNavFactory/GlobalMenuNavFactory';
import { useFloating } from '../../uses/useFloating';
import {
  GLOBAL_MENU_DESKTOP_HEIGHT,
  THREE_ROWS_HEIGHT_DESKTOP,
  TWO_ROWS_HEIGHT_DESKTOP,
} from '../../constants/main';
import { wrapperFactoryStyles } from './WrapperFactory.style';

const useWrapperFactoryStyles = createUseStyles(wrapperFactoryStyles);

export const WrapperFactory = memo(() => {
  const { states, config, setStates } = useGlobalHeaderContext();
  const isMenuOpen =
    states.localMobileMenuNavOpened || states.globalMenuNavOpened || states.accountMenuNavOpened;
  const { isAtTop, isScrolledUp, headerNotFullyScrolled, scrolledDownThenUp } = useFloating(
    states.isFixed,
    isMenuOpen,
    states.headerHeight
  );
  const [style, setStyle] = useState<GlobalHeaderStyleConfig>(
    !isAtTop
      ? config.style?.floating ?? defaultFloatingStyle
      : config.style?.default ?? defaultStyle
  );
  const [headerAriaHidden, setHeaderAriaHidden] = useState<boolean>(false);
  const globalMenuButtonRef: RefObject<HTMLButtonElement> = createRef();
  const accountMenuButtonRef: RefObject<HTMLButtonElement> = createRef();
  const localMobileMenuButtonRef: RefObject<HTMLButtonElement> = createRef();
  const hasProductMenu =
    config.productMenu && (config.productMenu.items || config.productMenu.hotlinks);
  const hasSecondaryMenu = config.productMenu?.items?.some(
    (item) => item.active && item.items && item.items.length > 0
  );

  const getDisableScrollWidth = () => {
    if (states.localMobileMenuNavOpened) {
      return LOCAL_MENU_SCROLL_DISABLE;
    }
    if (states.globalMenuNavOpened || states.accountMenuNavOpened) {
      return GLOBAL_MENU_SCROLL_DISABLE;
    }
    return undefined;
  };

  const { scrollbarWidth } = useBodyDisableScroll({
    active: isMenuOpen,
    width: getDisableScrollWidth(),
  });

  const classes = useWrapperFactoryStyles({
    style: states.style,
    scrollbarWidth,
    hasThirdRow: states.headerHeight === THREE_ROWS_HEIGHT_DESKTOP,
  });

  // Přidání/odebrání třídy do body na kterou se dá v css reagovat
  // (otevřené/zavřené lokální mobilní menu)
  useEffect(() => {
    if (states.localMobileMenuNavOpened) {
      document.body.classList.add(classes.localMobileMenuOpened);
    } else {
      document.body.classList.remove(classes.localMobileMenuOpened);
    }
  }, [classes.localMobileMenuOpened, states.localMobileMenuNavOpened]);

  useEffect(() => {
    setHeaderAriaHidden(isMenuOpen);
  }, [isMenuOpen]);

  // Nastavení vizuálního stylu hlavičky
  useEffect(() => {
    // zavrene menu
    // pokud jsem nahore, bude to default
    if ((!isMenuOpen && !isScrolledUp && headerNotFullyScrolled) || !states.isFixed) {
      setStyle(config.style?.default ?? defaultStyle);
      // pokud zacnu scrollovat, zustava default, v momente kdy hlavicka zmizi je to floating
    } else if (isMenuOpen && isAtTop) {
      // pokud jsem nahore bude to default
      setStyle(config.style?.default ?? defaultStyle);
      // jak zacnu scrollovat je to floating
    } else {
      setStyle(config.style?.floating ?? defaultFloatingStyle);
    }
  }, [
    config.style?.default,
    config.style?.floating,
    headerNotFullyScrolled,
    isAtTop,
    isMenuOpen,
    isScrolledUp,
    states.isFixed,
  ]);

  // nastaveni dark modu a stylu hlavičky do contextu
  useEffect(() => {
    setStates({ ...states, ...{ isDark: !!style.dark }, ...{ style } });
  }, [style, setStates]); // eslint-disable-line

  // nastavime vysku hlavicky do kontextu
  useEffect(() => {
    let newHeaderHeight = GLOBAL_MENU_DESKTOP_HEIGHT;
    if (hasProductMenu) {
      newHeaderHeight = TWO_ROWS_HEIGHT_DESKTOP;
    }
    if (hasSecondaryMenu) {
      newHeaderHeight = THREE_ROWS_HEIGHT_DESKTOP;
    }
    if (newHeaderHeight !== states.headerHeight) {
      setStates({ ...states, headerHeight: newHeaderHeight });
    }
  }, [hasProductMenu, hasSecondaryMenu, setStates, states]);
  return (
    <>
      <Wrapper
        ariaHidden={headerAriaHidden}
        className={classNames(
          config.className,
          GLOBAL_THEME_CLASSNAME,
          classes.header,
          states.wrapperPosition === WrapperPositions.absolute &&
            !states.isFixed &&
            classes.wrapperAbsolute,
          states.isDark && classes.headerDark,
          states.isFixed && classes.wrapperFixed,
          states.isFixed &&
            hasSecondaryMenu &&
            !scrolledDownThenUp &&
            !isMenuOpen &&
            classes.wrapperFixedThirdRow,
          states.isFixed && scrolledDownThenUp && !isMenuOpen && classes.wrapperFixedAnimated,
          states.isFixed &&
            scrolledDownThenUp &&
            !isMenuOpen &&
            hasSecondaryMenu &&
            classes.wrapperFixedAnimatedThirdRow,
          isMenuOpen && !isAtTop && classes.headerPositionFixed
        )}
        hasThirdRow={states.headerHeight === THREE_ROWS_HEIGHT_DESKTOP}
        position={states.wrapperPosition}
      >
        <GlobalMenuFactory
          accountMenuButtonRef={accountMenuButtonRef}
          globalMenuButtonRef={globalMenuButtonRef}
        />
        {config.productMenu && (config.productMenu.items || config.productMenu.hotlinks) && (
          <LocalMenuFactory
            hotlinks={config.productMenu.hotlinks ?? []}
            items={config.productMenu.items ?? []}
            localMobileMenuButtonRef={localMobileMenuButtonRef}
            maxWidth={config.productMenu.maxWidth}
          />
        )}
      </Wrapper>
      {/* TODO vyresit tuhle nadheru */}
      <Spacer
        visible={states.isFixed && states.wrapperPosition === WrapperPositions.relative}
        withProductMenuHeight={!!config.productMenu}
      />
      <GlobalMenuNavFactory globalMenuButtonRef={globalMenuButtonRef} />
      <GlobalMenuAccountNavFactory accountMenuButtonRef={accountMenuButtonRef} />
      <LocalMenuMobileNavFactory
        items={config.productMenu?.items ?? []}
        localMobileMenuButtonRef={localMobileMenuButtonRef}
      />
    </>
  );
});
