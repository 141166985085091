import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { buildMediaQuery } from '@czechtv/styles';
import { useScrollbarWidth } from './useScrollbarWidth';

interface DisableScrollPaddingStyleProps {
  bodyPaddingRight: number;
  documentPaddingRight: number;
}

export const LOCAL_MENU_SCROLL_DISABLE = 768;
export const GLOBAL_MENU_SCROLL_DISABLE = 428;

const bodyDisableScrollStyles = {
  overflow: 'hidden !important',
  touchAction: 'none',
  '-webkit-overflow-scrolling': 'none',
  overscrollBehavior: 'none',
};

const documentDisableScrollStyles = {
  overflow: 'hidden !important',
  paddingRight: (props: DisableScrollPaddingStyleProps) => `${props.bodyPaddingRight}px !important`,
  touchAction: 'none',
  '-webkit-overflow-scrolling': 'none',
  overscrollBehavior: 'none',
};

const useStyles = createUseStyles({
  documentDisableScrollLocal: {
    [buildMediaQuery({ maxWidth: LOCAL_MENU_SCROLL_DISABLE - 1 })]: {
      ...documentDisableScrollStyles,
    },
  },
  bodyDisableScrollLocal: {
    [buildMediaQuery({ maxWidth: LOCAL_MENU_SCROLL_DISABLE - 1 })]: {
      ...bodyDisableScrollStyles,
    },
  },
  documentDisableScrollGlobal: {
    [buildMediaQuery({ maxWidth: GLOBAL_MENU_SCROLL_DISABLE - 1 })]: {
      ...documentDisableScrollStyles,
    },
  },
  bodyDisableScrollGlobal: {
    [buildMediaQuery({ maxWidth: GLOBAL_MENU_SCROLL_DISABLE - 1 })]: {
      ...bodyDisableScrollStyles,
    },
  },
});

interface UseBodyDisableScrollProps {
  active: boolean;
  width?: number;
}

export const useBodyDisableScroll = ({ active, width }: UseBodyDisableScrollProps) => {
  const [bodyScrollbarWidth, setBodyScrollbarWidth] = useState(0);
  const [documentScrollbarWidth, setDocumentScrollbarWidth] = useState(0);

  const computedScrollbarWidth = useScrollbarWidth();

  const classes = useStyles({
    bodyPaddingRight: bodyScrollbarWidth,
    documentPaddingRight: documentScrollbarWidth,
  });

  const isVisibleScrollbarY = (el: HTMLElement) => {
    const bodyStyle = window.getComputedStyle(el);

    const y1 = el.scrollTop;

    el.scrollTop += 1;
    const y2 = el.scrollTop;

    el.scrollTop -= 1;
    const y3 = el.scrollTop;

    el.scrollTop = y1;

    return (
      bodyStyle.overflow === 'scroll' ||
      bodyStyle.overflowY === 'scroll' ||
      // na porovnání výšky scroll > client není spoleh při použití marginBottom na konci body
      (el.scrollHeight > el.clientHeight &&
        // proto je tu ještě toto
        (y1 !== y2 || y2 !== y3) &&
        bodyStyle.overflow !== 'hidden' &&
        bodyStyle.overflowY !== 'hidden' &&
        bodyStyle.overflow !== 'clip' &&
        bodyStyle.overflowY !== 'clip')
    );
  };

  const computePaddings = () => {
    if (typeof document !== 'undefined' && isVisibleScrollbarY(document.body)) {
      setBodyScrollbarWidth(computedScrollbarWidth);
    } else {
      setBodyScrollbarWidth(0);
    }

    if (typeof document !== 'undefined' && isVisibleScrollbarY(document.documentElement)) {
      setDocumentScrollbarWidth(computedScrollbarWidth);
    } else {
      setDocumentScrollbarWidth(0);
    }
  };

  const addClasses = (width?: number) => {
    // zapneme disableScroll na body a document je li disableScroll aktivní

    if (width === LOCAL_MENU_SCROLL_DISABLE) {
      classes.documentDisableScrollLocal
        .split(' ') // vytváří dvě třídy, takže musíme přidat postupně
        .forEach((className) => {
          document.documentElement.classList.add(className);
        });
      classes.bodyDisableScrollLocal
        .split(' ') // vytváří dvě třídy, takže musíme přidat postupně
        .forEach((className) => {
          document.body.classList.add(className);
        });
    }
    if (width === GLOBAL_MENU_SCROLL_DISABLE) {
      classes.documentDisableScrollGlobal
        .split(' ') // vytváří dvě třídy, takže musíme přidat postupně
        .forEach((className) => {
          document.documentElement.classList.add(className);
        });
      classes.bodyDisableScrollGlobal
        .split(' ') // vytváří dvě třídy, takže musíme přidat postupně
        .forEach((className) => {
          document.body.classList.add(className);
        });
    }
  };

  const removeClasses = () => {
    // vypneme disableScroll na body a document je li disableScroll neaktivní
    classes.documentDisableScrollLocal
      .split(' ') // vytváří dvě třídy, takže musíme odebrat postupně
      .forEach((className) => {
        document.documentElement.classList.remove(className);
      });
    classes.bodyDisableScrollLocal
      .split(' ') // vytváří dvě třídy, takže musíme odebrat postupně
      .forEach((className) => {
        document.body.classList.remove(className);
      });
    classes.documentDisableScrollGlobal
      .split(' ') // vytváří dvě třídy, takže musíme odebrat postupně
      .forEach((className) => {
        document.documentElement.classList.remove(className);
      });
    classes.bodyDisableScrollGlobal
      .split(' ') // vytváří dvě třídy, takže musíme odebrat postupně
      .forEach((className) => {
        document.body.classList.remove(className);
      });
  };

  useEffect(() => {
    let bodyWidth = 0;
    if (typeof document !== 'undefined') {
      bodyWidth = window.innerWidth;
    }
    if (width && bodyWidth > width) {
      // pokud prepiname mezi local a global menu, musime nejdrive odstranit tridy
      removeClasses();
      return;
    }
    if (active) {
      computePaddings();
      addClasses(width);
    } else {
      removeClasses();
      computePaddings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, computedScrollbarWidth, width]);

  return {
    scrollbarWidth: computedScrollbarWidth,
  };
};
