export const parseBooleanEnvVar = (envVar: string | undefined): boolean => {
  if (typeof envVar === 'undefined') {
    return false;
  }

  return ['true', 'yes', '1'].includes(envVar.trim().toLowerCase());
};

export const parseIntegerEnvVar = (envVar: string | undefined): number | undefined => {
  if (typeof envVar === 'undefined') {
    return undefined;
  }

  return parseInt(envVar, 10);
};

export const findFirstNotDefined = (envVars: {
  [key: string]: string | undefined;
}): string | undefined =>
  Object.entries(envVars).find(([, val]) => typeof val === 'undefined')?.[0];
