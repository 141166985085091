import { DefaultBoxShadow } from '@czechtv/styles';

export const positionedMenuStyles = {
  positionedMenuWrapper: {
    zIndex: 200,
    position: 'absolute',
    pointerEvents: 'all',
    borderRadius: 4,
    width: 'max-content',
    minWidth: 170,
    boxShadow: DefaultBoxShadow,
    overflow: 'hidden',
    transition: 'opacity 200ms cubic-bezier(0, 0, 0.3, 1)',
  },
  positionedMenuWrapperShow: {
    transformOrigin: 'top',
    visibility: 'visible',
    animation: 'context-menu-show 200ms cubic-bezier(0, 0, 0.3, 1)',
  },
  positionedMenuWrapperHidden: {
    opacity: '0%',
    visibility: 'hidden',
    pointerEvents: 'none',
  },
  positionedMenuWrapperHideForTab: {
    display: 'none',
  },
  '@keyframes context-menu-show': {
    from: {
      opacity: 0.3,
      transform: 'scale(1) translateY(-16px)',
    },
    to: {
      opacity: 1,
      transform: 'scale(1) translateY(0px)',
    },
  },
};
