import { Property } from 'csstype';
import {
  Blue_base,
  body16,
  BoxShadowFocus,
  DEFAULT_FONT,
  Grey_50,
  Grey_80,
  Grey_10,
} from '@czechtv/styles';

export interface ContextMenuItemThemeProps {
  itemLinkActiveColor?: Property.Color;
  itemLinkColor?: Property.Color;
  itemLinkDisabledBackgroundColor?: Property.Color;
  itemLinkDisabledColor?: Property.Color;
  itemLinkHoverBackgroundColor?: Property.Color;
  itemLinkHoverColor?: Property.Color;
}

export const contextMenuItemStyles = {
  insetFocusVisible: {}, // Class FK
  contextMenuItemDisabled: {
    '$contextMenuItem& $contextMenuItemLink, $contextMenuItem& $contextMenuItemLink:hover': {
      backgroundColor: (theme?: ContextMenuItemThemeProps) =>
        theme?.itemLinkDisabledBackgroundColor ?? 'transparent',
      color: (theme?: ContextMenuItemThemeProps) => theme?.itemLinkDisabledColor ?? Grey_50,
      cursor: 'auto',
    },
  },
  contextMenuItemActive: {
    '$contextMenuItem& $contextMenuItemLink': {
      fontFamily: DEFAULT_FONT,
      fontWeight: 600,
      color: (theme?: ContextMenuItemThemeProps) => theme?.itemLinkActiveColor ?? '#000000',
      cursor: 'default',
    },
  },
  contextMenuItemLink: {
    display: 'flex',
    alignItems: 'center',
    color: (theme?: ContextMenuItemThemeProps) => theme?.itemLinkColor ?? Blue_base,
    verticalAlign: 'sub',
    ...body16,
    padding: [9, 26, 9],
    '$contextMenuItem:not($contextMenuItemDisabled) &:hover': {
      color: (theme?: ContextMenuItemThemeProps) => theme?.itemLinkHoverColor ?? '#000000',
      backgroundColor: (theme?: ContextMenuItemThemeProps) =>
        theme?.itemLinkHoverBackgroundColor ?? Grey_10,
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
  profileName: {
    '& + ul li:first-child $contextMenuItemLink': {
      padding: [0, 24, 9],
      '&:hover': {
        backgroundColor: '#fff',
        textDecoration: 'underline',
      },
    },
  },
  contextMenuItemLinkIsNot: {
    '$contextMenuItemLink&': {
      outline: 'none',
      '&.focus-visible': {
        boxShadow: BoxShadowFocus,
      },
    },
  },
  contextMenuItem: {
    padding: 0,
    listStyle: 'none',
    color: Grey_80,
    width: '100%',
    maxWidth: '100%',

    '& > span': {
      display: 'flex',
      alignItems: 'center',
      '& > svg': {
        flex: '0 0 auto',
        width: '20px',
        height: '20px',
        margin: '0 8px 0 -28px',
        fill: 'currentColor',
      },
    },
  },
  contextMenuIcon: {
    width: '20px',
    marginRight: '18px',
    display: 'flex',
    alignItems: 'center',
  },
};
