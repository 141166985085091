import React, { memo, MouseEvent } from 'react';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { Grey_60, body14, WhiteOpacity60, WhiteOpacity80 } from '@czechtv/styles';
import { mediaMaxMobile413, mediaMinTablet768 } from '../../../common/breakpoints';
import { link } from '../../../common/styles';

interface SubFooterProps {
  className?: string;
  darkMode?: boolean;
  onLinkClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

const useStyles = createUseStyles({
  subFooter: {
    ...body14,
    fontWeight: 400,
    color: Grey_60,
    letterSpacing: 0,
    textTransform: 'initial',
    display: 'flex',
    justifyContent: 'space-between',
    '& a': {
      ...link,
    },
    '& p': {
      margin: 0,
    },
    '&.darkMode': {
      '& span, a': {
        color: WhiteOpacity80,
      },
      '& p:first-child': {
        color: WhiteOpacity60,
      },
    },
    [mediaMaxMobile413]: {
      '& div:first-child': {
        '& span:first-of-type': {
          display: 'block',
        },
      },
    },
  },
  separator: {
    margin: '0px 7px',
  },
  hidden: {
    [mediaMaxMobile413]: {
      display: 'none',
    },
  },
  rightColumn: {
    display: 'none',
    [mediaMinTablet768]: {
      display: 'flex',
    },
  },
});

export const SubFooter = memo(({ className, darkMode, onLinkClick }: SubFooterProps) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.subFooter, className, { darkMode })}>
      <div>
        <span>© Česká televize</span>
        <span className={classNames(classes.separator, classes.hidden)}>•</span>
        <a href="https://www.ceskatelevize.cz/english/" onClick={onLinkClick}>
          English version
        </a>
        <span className={classes.separator}>•</span>
        <a href="https://www.ceskatelevize.cz/ochrana-soukromi/" onClick={onLinkClick}>
          Ochrana soukromí
        </a>
      </div>
      <div className={classes.rightColumn}>
        <a href="https://www.ceskatelevize.cz/rss/" onClick={onLinkClick}>
          RSS
        </a>
      </div>
    </div>
  );
});
